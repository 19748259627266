
factory.$inject = ['$http', '$q', 'localStorageService', 'appConfig', 'DirectoriesService', 'ParamsService'];(function(){
  'use strict';

  angular
    .module('app.security', [
      'ui.router',
      'ui.validate',
    ])
    .config(['$stateProvider', config]);

  function config($stateProvider) {
    $stateProvider
      .state('logout', {
        url: '/logout',
        onEnter: ['AuthenticationService', function(AuthenticationService) {
          AuthenticationService.logout();
        }]
      })

      .state('ssocascallback', {
        url: '/sso/cas/callback',
        onEnter: ['AuthenticationService', 'ParamsService', '$timeout', '$state', 'localStorageService', function(
          AuthenticationService,
          ParamsService,
          $timeout,
          $state,
          localStorageService
        ){
          if (AuthenticationService.isLoggedIn()) {
            ParamsService.resetSilent('ticket');
            var prevParams = localStorageService.get('urlParams');
            ParamsService.setObjectSilent(prevParams);
            var state = AuthenticationService.getReturnState() || 'intro';
            state = state !== 'sso/cas/callback' ? state.split('/').join('.') : 'intro';
            $timeout(function() { $state.go(state, {}) }, 200);
          }
        }]
      })
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['$rootScope', '$scope', '$state', '$location', 'AuthenticationService', 'ParamsService', '$timeout'];
  angular
    .module('app.security')
    .controller('AuthCtrl', Controller);

  /* @ngInject */
  function Controller(
    $rootScope,
    $scope,
    $state,
    $location,
    AuthenticationService,
    ParamsService,
    $timeout
  ) {
    var ctrl = this;

    if (AuthenticationService.isLoggedIn()) {
      console.log('is logged in', AuthenticationService.isLoggedIn());
      ParamsService.resetSilent('ticket');
      var state = AuthenticationService.getReturnState() || 'intro';
      state = state !== 'sso/cas/callback' ? state.split('/').join('.') : 'intro';
      $timeout(function(){$state.go(state, {})}, 250);
    }

    // No default action is taken because the AuthenticationService
    // handles the redirection in case of failure.

  }
})();

(function() {
  'use strict';

  Controller.$inject = ['$scope', 'AuthenticationService'];
  angular
    .module('app.security')
    .controller('LogoutCtrl', Controller);

  /* @ngInject */
  function Controller($scope, AuthenticationService) {
    var ctrl = this;

    AuthenticationService.logout()
      .then(function(response){
        window.location = '/rioseo/logout';
      });
    return;

  }
})();

(function() {
  'use strict';

  service.$inject = ['$window', '$state', '$http', '$cookies', '$q', 'localStorageService', 'authenticationInterceptor'];
  angular
    .module('app.security')
    .service('AuthenticationService', service);

  /* @ngInject */
  function service (
    $window,
    $state,
    $http,
    $cookies,
    $q,
    localStorageService,
    authenticationInterceptor
  ) {
    var service = {};

    service.validateOpenIdToken = function (token) {
      return $http.get('/rioseo/user/validate-token.json?code=' + token)
    }

    service.validateToken = function() {
      var params = service.parseParams();

      if (params.ticket) {
        service.login(params.ticket);
        delete params.ticket;
      }

      if (params.scope === 'openid' && params.code) {
        $cookies.put('sso_service', window.location.origin + window.location.pathname);
        return service.validateOpenIdToken(params.code)
          .then(function(response) {
            if (response.data.error) {
              return;
            }
            response = authenticationInterceptor.response(response);
            service.login(response.data.token);
            delete params.code;
            delete params.scope;
          });
      }

      return $q(function(resolve, reject) {
        resolve();
      });
    };

    service.parseParams = function() {
      var params = document.location.search.slice(1).split('&').reduce(function(result, currentItem) {
        var parts = currentItem.split('=');
        var key = parts[0];
        var val = parts[1];
        if (!_.isEmpty(key) && !_.isEmpty(val)) {
          result[key] = val;
        }
        return result;
      }, {});
      return params;
    };

    service.getCurrentUser = function(appConfig) {
      return $http
        .get('/rioseo/user/current.json?t='+(new Date().getTime()))
        .then(function(response){
          return authenticationInterceptor.response(response);
        })
    };

    service.logout = function () {
      $cookies.remove('CASTGC');
      document.location.href = '/rioseo/logout';
    };

    service.isLoggedIn = function () {
      return !_.isEmpty($cookies.get('sso_service')) && !_.isEmpty($cookies.get('sso_ticket'));
    };

    service.login = function (token) {
      $cookies.put('sso_ticket', token);
      $cookies.put('sso_service', window.location.origin + window.location.pathname);
    };

    service.getReturnUrl = function() {
      var url = localStorageService.get('returnUrl');
      localStorageService.remove('returnUrl');
      return url;
    }

    service.getReturnState = function() {
      var state = localStorageService.get('returnState');
      localStorageService.remove('returnState');
      return state;
    }

    service.getReturnParams = function() {
      var params = localStorageService.get('returnParams');
      localStorageService.remove('returnParams');
      return params;
    }

    return service;
  }

})();

(function() {
  'use strict';

  Interceptor.$inject = ['$window', '$cookies', '$injector', 'localStorageService'];
  angular
    .module('app.security')
    .service('authenticationInterceptor', Interceptor);

  /* @ngInject */
  function Interceptor (
    $window,
    $cookies,
    $injector,
    localStorageService
  ) {
    var interceptor = {};

    function parseParams () {
      var params = document.location.search.slice(1).split('&').reduce(function(result, currentItem) {
        var parts = currentItem.split('=');
        var key = parts[0];
        var val = parts[1];
        if (!_.isEmpty(key) && !_.isEmpty(val)) {
          result[key] = val;
        }
        return result;
      }, {});
      return params;
    };

    interceptor.response = function (response) {

      if (response.data.error === 'AUTHENTICATION_FAILURE') { // 401
        if (document.location.hostname === 'localreporting.vm.rioseo.com') {
          response.data.redirectUrl = 'https://localreporting.qa.rioseo.com/cas4/login?service=https%3A%2F%2Flocalreporting.vm.rioseo.com%3A444%2Fsso/cas/callback'
        }

        var logoutUrl = response.data.logoutUrl || response.data.redirectUrl.replace('login', 'logout');
        var prevParams = parseParams();

        localStorageService.set('redirectUrl', response.data.redirectUrl);
        localStorageService.set('logoutUrl', logoutUrl);
        localStorageService.set('returnState', document.location.pathname.substr(1));
        localStorageService.set('urlParams', prevParams);

        document.location = response.data.redirectUrl;
        return;
      }
      else if(response.data.error === 'AUTHORIZATION_FAILURE') { // 403
        // console.log('AUTHORI ERR', JSON.stringify(response, null, 4));
        // Issue loading external services: https://github.com/alexcrack/angular-ui-notification/issues/32
        var Notification = $injector.get('Notification');
        Notification.error('You are not allowed to access the resource. Ask for more permissions to the Account Manager.');
        // $window.history.back();
        // $window.location = '/intro';
        return response;
      }

      return response;
    };

    return interceptor;
  }

})();

(function(){
  'use strict';

  routing.$inject = ['$locationProvider', '$urlRouterProvider', '$stateProvider'];
  var isDev = document.location.hostname === 'localhost';

  var appConfig = {
    localStoragePrefix: 'pageauditApp',
    API_URL: '/rioseo',
    isDev: isDev,
  }

  var errorsFound = false;

  window.deferredBootstrapper.bootstrap({
    element: window.document,
    module: 'pageauditApp',
    injectorModules: ['ngCookies', 'app.security', 'LocalStorageModule'],
    resolve: {
      currentUser: ['AuthenticationService', function (AuthenticationService) {
        try {
        return AuthenticationService.validateToken()
          .then(function(){
            return AuthenticationService.getCurrentUser(appConfig)
          });
        } catch (e) {
          return e;
        }
      }],
      defaultConfig: ['$http', function($http){
        return $http.get('/config/defaultConfig.json').then(function(response) {return response.data; });
      }]
    },
    onError: function (error) {
      errorsFound = true;
      if (error.name === 'AUTHENTICATION_FAILURE') {
        // Save prev URL Params to load when logged in
        var pathname = document.location.pathname.substr(1);
        var params   = document.location.search
          .replace(/(^\?)/, '')
          .split('&')
          .map(function(n){
            return n = n.split('='), this[n[0]] = n[1] || true, this
          }
          .bind({}))[0];
        localStorage.setItem('ls.urlParams', JSON.stringify(params));
        localStorage.setItem('ls.returnState', pathname)

        // Generate Login URL similar to:
        // https://dev-rsa.rioseo.com/cas4/login?service=http%3A%2F%2Flocalhost%3A81%2Fsso/cas/callback
        var baseUrl = isDev ? 'https://localreporting.qa.rioseo.com' : document.location.origin;
        var loginRedirect = baseUrl + '/cas4/login?service=' + encodeURIComponent(document.location.origin + '/sso/cas/callback');
        window.location = loginRedirect;
      } else {
        try {
          var errorStringified = JSON.stringify(error.data);
          if (window.sessionStorage) {
            window.sessionStorage.setItem("error_data", errorStringified);
          }
        } catch(e){}
        window.location = '/500.html';
      }
    }
  });
  
  if (!errorsFound && window.sessionStorage) {
    window.sessionStorage.clear();
  }

  /**
   * @ngdoc overview
   * @name pageauditApp
   * @description
   * # pageauditApp
   *
   * Application
   */
  var app = angular.module('pageauditApp', [
    'angular-lodash',
    'ngAnimate',
    'ngAria',
    'ngCookies',
    'ngFileSaver',
    'angular.filter',
    'ngResource',
    'ngMessages',
    'ngSanitize',
    'angularMoment',
    'ngCsv',
    'ngTouch',
    'uiSwitch',
    'ui.select',
    'ui.sortable',
    'ui.bootstrap',
    'hljs',
    'ivh.treeview',
    'dndLists',
    'angular-jqcloud',
    'nvd3',
    'highcharts-ng',
    'angulartics',
    'angulartics.google.analytics',
    'slickCarousel',
    // Custom Modules
    'Shared',
    'LocalizationService',
    'KeywordService',
    'LocalStorageModule',
    'TenantService',
    'ui-notification',
    'Acl',
    'Grid',
    'Local',
    'Intro',
    'Menu',
    'app.admin',
    'app.params',
    'app.aggregations',
    'app.static',
    'app.security',
    'app.manage',
    'app.filters'
  ]);

  app.constant('appConfig', appConfig);

  /* @ngInject */
  app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('authenticationInterceptor');
  }]);

  // Routes @todo should be splitted by module
  /* @ngInject */
  function routing ($locationProvider, $urlRouterProvider,$stateProvider){
    $locationProvider.html5Mode(true);
    $urlRouterProvider.otherwise('/intro');
  }

  app.config(routing);

  /* @ngInject */
  app.config(['ivhTreeviewOptionsProvider', function(ivhTreeviewOptionsProvider) {
    ivhTreeviewOptionsProvider.set({
      defaultSelectedState: false,
      validate: true,
      twistieCollapsedTpl: '<i class="glyphicon glyphicon-plus"></i>',
      twistieExpandedTpl: '<i class="glyphicon glyphicon-minus"></i>',
      twistieLeafTpl: ''
    });
  }])

  if (!isDev) {
    app.config(['$compileProvider', function ($compileProvider) {
      $compileProvider.debugInfoEnabled(false);
    }]);
  }

  app.config(['localStorageServiceProvider', 'currentUser', function (localStorageServiceProvider, currentUser) {
    // localStorageServiceProvider.setPrefix(
      // appConfig.localStoragePrefix + '_' + window.location.hostname + '_' + currentUser.user.userId
    // );

    // @todo this should be moved to Aggregations module config
    Highcharts.getOptions().colors = Highcharts.map(Highcharts.getOptions().colors, function (color) {
      return {
        radialGradient: {
          cx: 0.5,
          cy: 0.3,
          r: 0.7
        },
        stops: [
          [0, color],
          [1, Highcharts.Color(color).brighten(-0.3).get('rgb')]
        ]
      };
    });
  }]);

  app.run(['$rootScope', '$state', '$stateParams', '$cookies', 'localStorageService', 'currentUser', '$location', 'AclService', 'ParamsService', '$sce', '$templateRequest', function(
    $rootScope,
    $state,
    $stateParams,
    $cookies,
    localStorageService,
    currentUser,
    $location,
    AclService,
    ParamsService,
    $sce,
    $templateRequest
  ) {
    if (!currentUser) {
      return false;
    }

    // It's very handy to add references to $state and $stateParams to the $rootScope
    // so that you can access them from any scope within your applications.For example,
    // <li ng-class="{ active: $state.includes('contacts.list') }"> will set the <li>
    // to active whenever 'contacts.list' or one of its decendents is active.
    // Ref: https://github.com/angular-ui/ui-router/blob/master/sample/app/app.js#L14
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    // Remove previous attached listeners to ParamsService
    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      // var resetParams = {
      //   page: 0,
      //   currentPage: 1,
      //   sortBy: undefined,
      //   sortAsc: undefined,
      //   includedInTopOffering: undefined,
      // };

      // if (['', '/sso/cas/callback'].indexOf(fromState.name) === -1) {
      //   resetParams.searchText = null;
      // };

      // ParamsService.setObjectSilent(resetParams);
      ParamsService.resetAll();
    });

    $rootScope.params = {};

    $rootScope.tenantName  = currentUser.tenant.name;
    $rootScope.indexDate   = currentUser.formattedDataDate;
    $rootScope.loggedUser  = currentUser.user; // @todo Still used by some templates and controllers
    $rootScope.currentUser = currentUser.user;
    $rootScope.tenant      = currentUser.tenant;
    $rootScope.isIBM       = $rootScope.tenantName.indexOf('ibm') >= 0;
    $rootScope.user_rw     = AclService.can(['USER_RW_RSA', 'ADMIN_RSA']);
    try {
        $rootScope.isInIframe =  window.self !== window.top;
    } catch (e) {
        $rootScope.isInIframe = true;
    }
  }]);
})();

(function() {
  'use strict';

  angular.module('Shared', [
    'shared.filters',
    'mwl.confirm'
  ]);
})();

'use strict';

angular
  .module('shared.filters', [
    'filters.abs',
    'filters.ucfirst',
    'filters.urlencode',
    'filters.percent',
    'filters.formatScore',
    'filters.formatDuration',
    'filters.formatBid',
    'filters.bigNumber',
    'filters.localNumber',
    'filters.currency',
    'filters.shortenUrl',
    'filters.sanitizeUrl',
    'filters.split',
    'filters.dateFromString',
    'filters.formatSocialPercent',
    'filters.externalLink',
    'filters.uuid',
    'filters.underscoreless',
    'filters.isGreaterThan',
    'filters.isLesserThan',
    'filters.itContains',
    'filters.itDoesNotContains'
  ]);

angular.module('filters.isGreaterThan', []).filter('isGreaterThan', function () {
  return function (item, number) {
    switch(typeof(item)) {
      case "number":
        return item > number;
        break;
      case "string":
        return item.length > number;
        break;
      case "object":
        return item.length ? item.length > number : Object.keys(item).length > number;
        break;
    }
    return false;
  };
});

angular.module('filters.isLesserThan', []).filter('isLesserThan', function () {
  return function (item, number) {
    switch(typeof(item)) {
      case "number":
        return item < number;
        break;
      case "string":
        return item.length < number;
        break;
      case "object":
        return item.length ? item.length < number : Object.keys(item).length < number;
        break;
    }
    return false;
  };
});

angular.module('filters.itContains', []).filter('itContains', function () {
  return function (object, searchForThis, caseSensitive) {
    var searchIn = object;
    searchIn = searchIn ? searchIn : '';
    if(!caseSensitive && searchIn.toLocaleLowerCase) {
      searchIn = searchIn.toLocaleLowerCase();
      searchForThis = searchForThis.toLocaleLowerCase();
    }
    return searchIn.indexOf ? searchIn.indexOf(searchForThis) != -1 : searchIn[searchForThis];
  }
});

angular.module('filters.itDoesNotContains', []).filter('itDoesNotContains', ['itContainsFilter', function (itContainsFilter) {
  return function (object, searchForThis, caseSensitive) {
    return !itContainsFilter(object, searchForThis, caseSensitive);
  }
}]);

angular.module('filters.dateFromString', []).filter('dateFromString', function() {
  return function(string) {
    return new Date(string.slice(0,4), string.slice(4,6)-1, string.slice(6,8)).getTime();
  }
});

angular.module('filters.split', []).filter('split', function() {
  return function(string) {
    return string ? string.split(',') : '';
  }
});

angular.module('filters.ucfirst', []).filter('ucfirst', function() {
  return function(string) {
    return typeof(string) === 'string' ? string.substring(0, 1).toUpperCase() + string.substring(1).toLowerCase() : null;
  }
});

angular.module('filters.urlencode', []).filter('urlencode', function() {
  return function(input) {
    return window.encodeURIComponent(input);
  }
});

angular.module('filters.percent', []).filter('percent', function() {
  return function(string, precision) {
    if (precision === undefined) precision = 0;
    var value = (Number(string) * 100).toFixed(precision);
    if (isNaN(value)) {
      return '';
    }
    return value + '%';
  }
});

angular.module('filters.abs', []).filter('abs', function() {
  return function(string) {
    var value = Math.abs(Number(string));
    if (isNaN(value)) {
      return string;
    }
    return value;
  }
});

angular.module('filters.uuid', []).filter('uuid', function() {
  return function(string) {
    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
    }

    return guid();
  }
});

angular.module('filters.formatScore', []).filter('formatScore', function() {
  return function(input) {
    if (isNaN(input)) {
      return '';
    }
    return Math.round(input * 100);
  }
});

angular.module('filters.formatDuration', []).filter('formatDuration', function() {
  return function(input) {
    if (isNaN(input)) {
      return '';
    }
    var value = Math.round(input / 100) / 10;
    return String(value) + ' s';
  }
});

angular.module('filters.formatBid', []).filter('formatBid', ['$filter', function($filter) {
  return function(input, precision) {
    return $filter('currency')(input/1000000, precision);
  }
}])

angular.module('filters.bigNumber', []).filter('bigNumber', function() {
  return function(number, precision) {
    if (precision === undefined) precision = 0;
    if (number!==undefined) {
      var abs = Math.abs(number);
      if (abs >= Math.pow(10, 15)) { // Quadrillion
        number = (number / Math.pow(10, 15)).toFixed(precision)+"Q";
      } else if (abs < Math.pow(10, 15) && abs >= Math.pow(10, 12)) { // Trillion
        number = (number / Math.pow(10, 12)).toFixed(precision)+"T";
      } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) { // Billion
        number = (number / Math.pow(10, 9)).toFixed(precision)+"B";
      } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) { // Million
        number = (number / Math.pow(10, 6)).toFixed(precision)+"M";
      } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) { // Thousand
        number = (number / Math.pow(10, 3)).toFixed(precision)+"K";
      } else {
        number = Math.round(number);
      }
    }
    return number;
  }
});

angular.module('filters.localNumber', []).filter('localNumber', function() {
  return function(number) {
    return number.toLocaleString();
  }
});

angular.module('filters.currency', []).filter('currency', function() {
  return function(number, precision) {
    if (precision === undefined) precision = 1;
    if (number !== null && number !== undefined) {
      var abs = Math.abs(number);
      if (abs >= Math.pow(10, 15)) { // Quadrillion
        number = (number / Math.pow(10, 15)).toFixed(precision)+"Q";
      } else if (abs < Math.pow(10, 15) && abs >= Math.pow(10, 12)) { // Trillion
        number = (number / Math.pow(10, 12)).toFixed(precision)+"T";
      } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) { // Billion
        number = (number / Math.pow(10, 9)).toFixed(precision)+"B";
      } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) { // Million
        number = (number / Math.pow(10, 6)).toFixed(precision)+"M";
      } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)) { // Thousand
        number = (number / Math.pow(10, 3)).toFixed(precision)+"K";
      } else {
        number = precision == 0 ? Math.round(number) : number.toFixed(precision);
      }
    }
    return number ? '$ ' + number : '';
  }
});

angular.module('filters.shortenUrl', []).filter('shortenUrl', function() {
  return function(url) {
    return url;
  }
});

angular.module('filters.sanitizeUrl', []).filter('sanitizeUrl', function() {
  return function(url) {
    if (!url) return 'about:blank';
    if (url.indexOf('://') < 0) url = '//' + url;
    return url;
  }
});

angular.module('filters.formatSocialPercent', []).filter('formatSocialPercent', function() {
  return function(input) {
    if (input === null) {
      return '-';
    }
    return input + '%';
  }
});

angular.module('filters.externalLink', []).filter('externalLink', function() {
  return function(url) {
    return new Date(string.slice(0,4), string.slice(4,6)-1, string.slice(6,8)).getTime();
  }
});

angular.module('filters.underscoreless', []).filter('underscoreless', function () {
  return function (input) {
    return input.replace(/_/g, ' ');
  };
});

'use strict';

angular
  .module('Shared')
  .directive('selectOnClick', ['$window', function ($window) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('click', function () {
          if (!$window.getSelection().toString()) {
            // Required for mobile Safari
            this.setSelectionRange(0, this.value.length)
          }
        });
      }
    };
  }]);

(function() {
  'use strict';

  directive.$inject = ['$parse'];
  angular
    .module('Shared')
    .directive('fileModel', directive);

  /* @ngInject */
  function directive($parse) {
    var directive = {
      restrict: 'A',
      link: link
    };

    return directive;

    function link(scope, element, attrs) {
      var model = $parse(attrs.fileModel);
      var modelSetter = model.assign;
      element.bind('change', function(){
        scope.$apply(function(){
          modelSetter(scope, element[0].files[0]);
        });
      });
    }
  }
})();

(function() {
  'use strict';
  angular
    .module('Shared')
    .directive('datepicker', function() {
      return {
          restrict: 'A',
          require: 'ngModel',
          link: function(scope, element, attrs, ctrl) {
              $(element).datepicker({
                  dateFormat: 'yymmdd',
                  onSelect: function(date) {
                      ctrl.$setViewValue(date);
                      ctrl.$render();
                      scope.$apply();
                  }
              });
          }
      };
  });

})();

'use strict';

angular
  .module('pageauditApp')
  .controller('MainCtrl', ['$http', '$location', '$q', '$rootScope', '$scope', '$timeout', '$uibModal', '$window', 'AuthenticationService', 'GridWidthSrvc', 'Localization', 'localStorageService', 'ParamsService', 'currentUser', function (
    $http,
    $location,
    $q,
    $rootScope,
    $scope,
    $timeout,
    $uibModal,
    $window,
    AuthenticationService,
    GridWidthSrvc,
    Localization,
    localStorageService,
    ParamsService,
    currentUser
  ) {
    var ctrl = this;

    $rootScope.appName          = $rootScope.tenant.appTitle ? $rootScope.tenant.appTitle : 'Rio Search Analytics';
    $rootScope.setLocation      = setLocations; // used in localization modal
    $rootScope.showAggregations = true;
    $rootScope.isMenuDisabled   = $location.search().noMenu || false;
    $rootScope.updatedDataDate  = currentUser.formattedDataDate;
    $rootScope.location         = $location;

    // Remember params sent by URL
    var prevParams = localStorageService.get('urlParams') || {};
    var newParams  = _.merge({}, ParamsService.getAll(), prevParams, $location.search());
    $rootScope.params = newParams;
    ParamsService.setObjectSilent(newParams);
    initLocalizationCriteria();

    $rootScope.language = {};
    $rootScope.country = {};

    function initLocalizationCriteria () {
      Localization.init()
        .then(function(responses){
          if (responses) {
            $rootScope.countries = responses[0].data;
            $rootScope.languages = responses[1].data;
            setLocations();
          }
        });
    }

    function setLocations (language, country) {
      var language = language || Localization.getLanguage();
      var country = country || Localization.getLocation();

      $rootScope.language.selected = $rootScope.languages.filter(function(d) {return d.code == language }).shift();
      $rootScope.country.selected  = $rootScope.countries.filter(function(d) { return d.code == country }).shift();
      var previousLocale = Localization.getLocale();
      $rootScope.locales = Localization.setLocale(language, country);

      // @todo this is triggering events in all the application
      ParamsService.setObjectSilent({ locales: $rootScope.locales });
    }

    $scope.go = function(params) {
      $location.search($rootScope.params);
      ParamsService.setObject($rootScope.params);
    }

    // Clear watchers on scroll
    angular.element($window).bind('scroll', function(){
      $scope.$applyAsync();
    });

    // Adjust grid main column width when resize event is triggered
    angular.element($window).bind('resize', function() {
      $scope.$evalAsync(function() {
        GridWidthSrvc.mainColumnWidth();
      });
    });
  }])

'use strict';

angular
  .module('LocalizationService', [])
  .factory('Localization', factory);

/* @ngInject */
function factory (
  $http,
  $q,
  localStorageService,
  appConfig,
  DirectoriesService,
  ParamsService
) {
  var srvc = {};

  srvc.countries = [];
  srvc.languages = [];

  srvc.defaultDevice = 'DESKTOP'
  srvc.devices       = [
    { code: 'DESKTOP', title: 'Desktop', icon: 'zmdi-desktop-mac'},
    { code: 'MOBILE', title: 'Mobile', icon: 'zmdi-smartphone-iphone'},
  ];

  srvc.init = function init () {
    return $q.all([
      srvc.getCountries(),
      srvc.getLanguages()
    ]);
  };

  srvc.getLanguages = function getLanguages () {
    return $http.get(appConfig.API_URL + '/global/config/languages.json', {cache:true})
      .then(function(response) {
        if (response) {
          response.data = response.data.sort(function(a,b){return d3.ascending(a.name,b.name)});
          return response;
        }
      });
  }

  srvc.getCountries = function getCountries () {
    return $http.get(appConfig.API_URL + '/global/config/countries.json', {cache:true})
      .then(function(response){
        if (response) {
          response.data = response.data.sort(function(a,b){return d3.ascending(a.name,b.name)});
          return response;
        }
      });
  }

  srvc.getCriteria = function(data) {
    var _this = this,
      promise = $http.get(appConfig.API_URL + '/global/criteria.json', data);

    promise.then(function(response) {
      _this.countries = response.data.locations.sort(function(a,b){return d3.ascending(a.name,b.name)});
      _this.languages = response.data.languages.sort(function(a,b){return d3.ascending(a.name,b.name)});
    });
    return promise;
  }

  srvc.getFrequentLocales = function () {
    var locales = [
      // North America
      'US|en', 'CA|en', 'CA|fr', 'MX|es',
      // South America
      'BR|pt', 'AR|es', 'CO|es', 'CR|es',
      // Europe
      'GB|en', 'FR|fr', 'IT|it', 'ES|es', 'DE|de',
      // Asia
      'IN|hi', 'JP|ja', 'KR|ko', 'CN|zh_CN', 'RU|ru', 'SA|ar', 'AE|ar',
      // Oceania
      'AU|en',
      // Africa
      'ZA|en',
    ];
    return locales;
  }

  srvc.getLocale = function () {
    var _this = this,
      locales = [],
      language = _this.getLanguage(),
      location = _this.getLocation();
    return location + '|' + language;
  }

  srvc.getLocation = function () {
    return localStorageService.get('country') || 'US';
  }

  srvc.getLanguage = function () {
    return localStorageService.get('language') || 'en';
  }

  srvc.getSearchEngine = function () {
    return ParamsService.get('searchEngine') || DirectoriesService.default;
  }

  srvc.getDevice = function () {
    return ParamsService.get('device') || srvc.defaultDevice;
  }

  srvc.getLocale = function () {
    var language = srvc.getLanguage();
    var location = srvc.getLocation();
    return location + '|' + language;
  }

  srvc.setLocale = function (language, country) {
    var language = language || srvc.getLanguage();
    var country  = country || srvc.getLocation();
    var locale   = country + '|' + language;

    localStorageService.set('language', language);
    localStorageService.set('country', country);
    localStorageService.set('locales', locale);

    return locale;
  }

  srvc.localeToCountryCode = function(locale) {
    return locale.split('|').shift();
  }

  srvc.localeToLanguageCode = function(locale) {
    return locale.split('|').pop();
  }

  srvc.localeToCountry = function(locale) {
    var _this = this,
     code = _this.localeToCountryCode(locale);
    return _this.countries.filter(function(d) {
      return d.code === code && d.type === 'country';
    }).shift();
  }

  srvc.localeToLanguage = function(locale) {
    var _this = this,
     code = _this.localeToLanguageCode(locale);
    return _this.languages.filter(function(d) {
      return d.code === code && d.type === 'language';
    }).shift();
  }

  return srvc;
}

(function() {
  'use strict';

  factory.$inject = ['appConfig', 'currentUser', '$http', '$rootScope', '$location'];
  angular
    .module('Shared')
    .factory('DirectoriesService', factory);

  /* @ngInject */
  function factory(
    appConfig,
    currentUser,
    $http,
    $rootScope,
    $location
  ) {
    var srvc   = {};
    var tenant = $rootScope.tenantName;

    srvc.default     = currentUser.tenant.defaultSearchEngine || 'GOOGLE';
    srvc.all         = srvc.all || [];
    // @todo remove $location from here (somehow).
    srvc.selected    = srvc.selected || $location.search().searchEngine || srvc.default;
    srvc.add         = add;
    srvc.remove      = remove;
    srvc.getAll      = getAll;
    srvc.getSelected = getSelected;

    return srvc;
    ////////////////

    function getAll () {
      return $http.get(appConfig.API_URL + '/directories.json');
    }

    function getSelected () {
      if (!_.isEmpty(srvc.selected) && _.isObject(srvc.selected)) {
        return srvc.selected;
      }

      var selected = _.find(srvc.all, { name: srvc.selected });

      return selected;
    }

    function add (directory) {
      srvc.all.push(directory);
      srvc.all = _.uniqBy(srvc.all, 'name');
    }

    function remove (directory) {
      _.remove(srvc.all, {name: directory.name});
    }
  }
})();

(function() {
  'use strict';

  factory.$inject = ['localStorageService', 'currentUser'];
  angular
    .module('Shared')
    .factory('DataDateService', factory);

  /* @ngInject */
  function factory(
    localStorageService,
    currentUser
  ) {
    var srvc = {}

    srvc.dataDate       = srvc.dataDate || (currentUser ? currentUser.dataDate : undefined);
    srvc.dataDateTo     = srvc.dataDateTo || getDefaultDataDateTo();
    srvc.availableDates = getAvailableDates;

    function getDefaultDataDateTo () {
      if (currentUser) {
        var lastMonth = moment(currentUser.dataDate, 'YYYYMMDD').subtract(1, 'months').format('YYYYMMDD');
        if (currentUser.availableDataDates && _.includes(currentUser.availableDataDates, lastMonth)) {
          return lastMonth;
        } else {
          return currentUser.dataDate;
        }
      }
    }

    function getAvailableDates () {
      var availableDates = currentUser.availableDataDates.map(function(d){
        return {text: moment(d, 'YYYYMMDD'), value: d}
      });

      // commenting this out because it means we show dates that aren't actually available.
      // var defaultDateTo = getDefaultDataDateTo();

      // if (!_.find(availableDates, {value: defaultDateTo})) {
      //   availableDates.unshift({text: moment(defaultDateTo, 'YYYYMMDD'), value: defaultDateTo});
      // }

      return availableDates;
    }

    return srvc;
  }
})();

'use strict';

angular.module('TenantService', [])
  .service('Tenant', ['$http', 'appConfig', function($http, appConfig) {

    var service = {};

    service.getConfig = function (tenantName) {
      console.log('getConfig', tenantName)
      return $http.get(appConfig.API_URL + '/' + tenantName + '.json');
    }

    return service;
}]);

(function(){

  'use strict';

  Controller.$inject = ['$scope', '$rootScope', '$uibModal', '$timeout'];
  angular
    .module('Shared')
    .directive('confirmDialog', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      bindToController: {
        cancel: '&',
        items: '<',
        noItems: '@',
        ngClick: '&',
        ok: '&',
        title: '@',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      replace: true,
      // restrict: 'E',
      scope: {},
      template: function( element, attrs ) {
        var tag = element[0].nodeName;
        return '<' + tag + ' ng-click="ctrl.open()">' + element.html() + '</' + tag + '>';
      },
      link: function ( scope, element, attrs ) {
        scope.ctrl.body = attrs.confirmDialog;
      }
    };
    return directive;
  }  

  /* @ngInject */
  function Controller($scope, $rootScope, $uibModal, $timeout) {
    var ctrl = this;

    ctrl.title = ctrl.title || 'Confirmation needed';

    ctrl.toggle = function(item) {
      item.checked = !item.checked;
      this.removeItem(item);
    }

    ctrl.open = function() {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'modules/shared/views/confirm-dialog.html',
        scope: $scope,
        controllerAs: 'ctrl',
        resolve: {

        },
        size: 'md'
      });

      modalInstance.result.then(function () {
        ctrl.ok();
      }, function(param){
        ctrl.cancel();
      });
    };

    ctrl.removeItem = function(item) {
      this.items.splice(this.items.indexOf(item),1);
    }


  }
})();
(function() {
  'use strict';

  angular
    .module('Shared')
    .directive('avatar', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      restrict: 'E',
      replace: true,
      scope: true,
      bindToController: {
        user: '<',
        size: '@'
      },
      controller: 'avatarController',
      controllerAs: 'ctrl',
      templateUrl: 'modules/shared/avatar/avatar.html'
    };

    return directive;
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['appConfig'];
  angular
    .module('Shared')
    .controller('avatarController', Controller);

  function Controller(appConfig) {
    var ctrl = this;

    var username = ctrl.user ? ctrl.user.firstName + ' ' +ctrl.user.lastName : 'NA';

    var initials = username.match(/\b(\w)/g);

    ctrl.initials = (initials[0] + initials[initials.length - 1]).toUpperCase();

    // ctrl.initials = username.replace(/[0-9]/g, '').match(/\b(\w)/g).join('').toUpperCase();
  }
})();

(function() {
  'use strict';

  angular
    .module('Shared')
    .directive('print', directive);

  function directive() {
    var directive = {
      restrict: 'E',
      scope: true,
      bindToController: {
        target: '@',
        filename: '@'
      },
      controller: Controller,
      controllerAs: 'ctrl',
      template: '<button type="button" class="btn btn-sm btn-default" ng-click="ctrl.print()">' +
                  '<i class="zmdi zmdi-print"></i> Print' +
                '</button>',
    };

    return directive;
  }

  function Controller() {
    var ctrl = this;

    ctrl.filename = (ctrl.filename || Date.now()) + '.pdf';

    // http://stackoverflow.com/questions/34042440/using-html2canvas-to-render-a-highcharts-chart-to-pdf-doesnt-work-on-ie-and-fir
    // http://stackoverflow.com/questions/29166163/jspdf-addhtml-exporting-low-quality-image-to-pdf
    ctrl.print = function () {
      ctrl.printing = true;

      var svgElements = $(ctrl.target).find('svg');

      //replace all svgs with a temp canvas
      svgElements.each(function() {
        var canvas, xml;

        // canvg doesn't cope very well with em font sizes so find the calculated size in pixels and replace it in the element.
        $.each($(this).find('[style*=em]'), function(index, el) {
          $(this).css('font-size', getStyle(el, 'font-size'));
        });

        canvas = document.createElement("canvas");
        canvas.className = "screenShotTempCanvas";

        //convert SVG into a XML string
        xml = (new XMLSerializer()).serializeToString(this);

        // Removing the name space as IE throws an error
        xml = xml.replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '');

        //draw the SVG onto a canvas
        canvg(canvas, xml);
        $(canvas).insertAfter(this);
        //hide the SVG element
        $(this).attr('class', 'tempHide');
        $(this).hide();
      });

      html2canvas($(ctrl.target), {
        onrendered: function(canvas) {
          var imgData = canvas.toDataURL('image/png');
          var doc = new jsPDF('p', 'in', 'a4', true);
          var width = doc.internal.pageSize.width;
          var height = doc.internal.pageSize.height;
          doc.addImage(imgData, 'PNG', 0, 0, width, 0, null, 'NONE');
          doc.save(ctrl.filename);
          ctrl.printing = false;
        }
      });

      $(ctrl.target).find('.screenShotTempCanvas').remove();
      $(ctrl.target).find('.tempHide').show().removeClass('tempHide');

      // var doc = new jsPDF(); //'landscape'
      // var content = $(ctrl.target);
      // doc.addHTML(content, {background: 'white'}, function() {
      //   doc.save(ctrl.filename);
      //   ctrl.printing = false;
      // });
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('Shared')
    .directive('spinner', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        isLoading: '<'
      },
      controller: Controller,
      controllerAs: 'ctrl',
      link: link,
      templateUrl: 'modules/loading/spinner.html',
    };

    return directive;

    function link($scope, $elem, $attrs, ctrl) {
      $scope.$watch('ctrl.isLoading', function (newValue) {
        if (newValue) {
          var parent = angular.element($elem[0].parentElement);
          var table  = parent.find('.grid--table');
          var height = null; //parent.height() || 'auto';
          if (table.length) {
            height = table.height() < 540 ? 540 : table.height();
            $elem.find('.spinner--container').css({'height': height, 'line-height': height+'px'});
          } else {
            $elem.find('.spinner--container').css({'height': height});
          }
        }
      });
    }
  }

  /* @ngInject */
  function Controller() {}
})();

(function() {
  'use strict';

  helpTooltip.$inject = ['$compile', '$document'];
  angular
    .module('Shared')
    .directive('helpTooltip', helpTooltip);

  /* @ngInject */
  function helpTooltip (
    $compile,
    $document
  ) {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        text: '<'
      },
      controller: Controller,
      controllerAs: '$ctrl',
      template: [
        '<span class="help--tooltip">',
          '<span ng-if="$ctrl.showTooltip">',
            '<a uib-tooltip="{{$ctrl.text}}" tooltip-placement="auto bottom">',
              '<i class="zmdi zmdi-help"></i>',
              // '<i class="zmdi zmdi-help-outline"></i>',
            '</div>',
          '</span>',
        '</span>',
      ].join(''),
      link: link
    };

    function link ($scope, $elem, $attrs, ctrl) {
      $scope.$watch('$ctrl.text', function () {
        ctrl.showTooltip = !ctrl.isTextEmpty()
      })
    }

    return directive;
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.isTextEmpty = isTextEmpty
    ctrl.showTooltip = !isTextEmpty()

    function isTextEmpty () {
      return _.isEmpty(ctrl.text)
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('app.params', []);
})();

(function() {
  'use strict';

  factory.$inject = ['$rootScope', 'localStorageService'];
  angular
    .module('app.params')
    .factory('ParamsService', factory);

  function factory($rootScope, localStorageService) {
    var srvc = {}

    var defaultParams = { page: 0, currentPage: 1 };

    srvc.bind             = bind;
    srvc.unbind           = unbind;
    srvc.ignoredParams    = ['size','sortBy','sortAsc','page','currentPage'];
    srvc.isSet            = isSet;
    srvc.isSetObject      = isSetObject;
    srvc.isSetParams      = isSetParams;
    srvc.get              = get;
    srvc.getAll           = getAll;
    srvc.params           = getAll() || defaultParams;
    srvc.paramsChanged    = paramsChanged;
    srvc.set              = set;
    srvc.setSilent        = setSilent;
    srvc.setObject        = setObject;
    srvc.setObjectSilent  = setObjectSilent;
    srvc.resetAll         = resetAll;
    srvc.reset            = reset;
    srvc.resetSilent      = resetSilent;
    srvc.toggleParam      = toggleParam;
    srvc.notify           = notify;
    srvc.subscribe        = subscribe;

    return srvc;

    ////////////////

    function bind(callback) {
      $rootScope.$on('$paramsServiceUpdate', callback);
    }

    function unbind(params) {
      $rootScope.$$listeners['$paramsServiceUpdate'] = [];
    }

    function notify(params) {
      // @todo this condition depends on ignoredParams
      // if (srvc.paramsChanged(params)) {
      $rootScope.$emit('$paramsServiceUpdate', params);
      // }
    }

    function subscribe(scope, callback) {
      // The event object passed into the listener has the following attributes:
      // - Event {Object} Read more on: https://docs.angularjs.org/api/ng/type/$rootScope.Scope#$emit
      // - Args {Object} Message sent by event emitter
      var handler = $rootScope.$on('$paramsServiceUpdate', callback);
      scope.$on('$destroy', handler);
    }

    function isSet(paramName) {
      return !_.isEmpty($rootScope.params[paramName]);
    }

    function isSetParams(keys) {
      var match = keys.reduce(function(a, b){
        return isSet(b) && a;
      }, true) && keys.length;

      return match;
    }

    function isSetObject(paramsObj) {
      var keys      = _.keys(paramsObj);
      var toCompare = _.pick($rootScope.params, keys);
      var isEqual   = _.isEqual(paramsObj, toCompare);

      // var isEqual = keys.reduce(function(a, b){
      //   return get(b) === paramsObj[b] && a;
      // }, true) && keys.length;

      return isEqual;
    }

    function get(paramName, fromCache) {
      if (fromCache) {
        var params = getFromCache() || {};
        return params[paramName];
      }

      return getAll()[paramName];
    };

    function getAll() {
      if (! $rootScope.params) {
        $rootScope.params = {};
      }

      return $rootScope.params;
    }

    function setObject(params, silent) {
      for(var param in params) {
        $rootScope.params[param] = params[param];
      }

      saveToCache($rootScope.params);
      if (!silent) {
        notify(params);
      }
    };

    function setObjectSilent(params) {
      setObject(params, true);
    };

    function set(paramName, paramValue) {
      setSilent(paramName, paramValue);
      notify(paramName);
    };

    function setSilent(paramName, paramValue) {
      $rootScope.params[paramName] = paramValue;
      saveToCache($rootScope.params);
    };

    function resetAll() {
      var params = {}

      // Not reset global filters
      if (!_.isEmpty($rootScope.params.projects)) params.projects = $rootScope.params.projects
      if (!_.isEmpty($rootScope.params.pageProjects)) params.pageProjects = $rootScope.params.pageProjects
      if (!_.isEmpty($rootScope.params.searchEngine)) params.searchEngine = $rootScope.params.searchEngine
      if (!_.isEmpty($rootScope.params.device)) params.device = $rootScope.params.device
      if (!_.isEmpty($rootScope.params.locale)) params.locale = $rootScope.params.locale

      $rootScope.params = params
      notify(params)
      saveToCache($rootScope.params);
    }

    function reset(paramName) {
      delete $rootScope.params[paramName];

      notify(paramName);
      saveToCache($rootScope.params);
    }

    function resetSilent(paramName) {
      delete $rootScope.params[paramName];
      saveToCache($rootScope.params);
    }

    function toggleParam(paramName) {
      $rootScope.params[paramName] = !$rootScope.params[paramName];

      notify(paramName);
      saveToCache($rootScope.params);
    };

    function getFromCache () {
      return localStorageService.get('params');
    }

    function saveToCache (params) {
      localStorageService.set('params', $rootScope.params);
    }

    function paramsChanged(params, ignored) {
      var ignoredParams = _.isEmpty(ignored) ? srvc.ignoredParams : [].concat(ignored, srvc.ignoredParams)
      var keys = _.isObject(params) ? _.keys(params) : [ params ]
      var diff = _.difference(keys, ignoredParams)

      // If difference is 0 it means all keys match with ignoredParams, so skip updates
      return diff.length !== 0;
    }
  }
})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService'];
  angular
    .module('app.params')
    .directive('editableFilters', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      restrict: 'E',
      replace: true,
      scope: true,
      bindToController: {
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/params/editable-filters.html'
    };

    return directive;
  }

  /* @ngInject */
  function Controller(
    ParamsService
  ) {
    var ctrl = this;

    ctrl.$params = ParamsService;
  }
})();

'use strict';

/**
 * @ngdoc function
 * @name pageauditApp.controller:KeywordRankCtrl
 * @description
 * # KeywordRankCtrl
 * Controller of the pageauditApp
 */
angular
  .module('pageauditApp')
  .controller('KeywordRankCtrl', ['$scope', '$rootScope', '$uibModalInstance', '$uibModal', '$http', 'appConfig', 'AclService', 'Keywords', 'Localization', 'params', 'currentUser', function (
    $scope,
    $rootScope,
    $uibModalInstance,
    $uibModal,
    $http,
    appConfig,
    AclService,
    Keywords,
    Localization,
    params,
    currentUser
  ) {
    var ctrl = this;

    $scope.segment        = params.segment;
    $scope.keyword        = params.keyword;
    $scope.source         = params.source;
    $scope.rankType       = params.rankType || 'Organic';
    $scope.location       = params.details ? params.details.location : undefined;
    $scope.details        = params.details;
    $scope.domain         = params.domain;
    $scope.modal          = $uibModalInstance;
    $scope.locale         = Localization.getLocale();
    $scope.device         = Localization.getDevice();
    $scope.searchEngine   = Localization.getSearchEngine();
    $scope.loggedUser     = $rootScope.loggedUser;
    $scope.user_rw        = AclService.can(['USER_RW_RSA', 'ADMIN_RSA']);
    $scope.dateSelected   = params.selectedDate || '';
    $scope.availableDates = params.datesArray || undefined;
    $scope.serpUID        = params.serpUID;
    switch ($scope.source) {
      case 'RANK':
        $scope.title = 'Ranks for keyword:';
        break;
      case 'SITE_RANK':
        $scope.title = 'Google Site Pages for keyword:';
        break;
      case 'LOCAL':
        $scope.title = $scope.details.title ? $scope.details.title.replace($scope.keyword.name,'') : 'Local Ranks for keyword:';
        break;
      case 'ADHOC':
        $scope.title = $scope.details.title ? $scope.details.title.replace($scope.keyword.name,'') : 'Ad-Hoc Ranks for keyword:';
        break;
    }

      $scope.pageTypeDescriptions = {};
      if ($rootScope.segmentation) {
        $rootScope.segmentation.forEach(function(d){
          $scope.pageTypeDescriptions[d.name] = d.description;
        });
      }

      // This should be a Modal Service
      $scope.addDomainFromOthers = function(domain) {
        var modalInstance = $uibModal.open({
          templateUrl: 'modules/admin/views/admin.tenants.settings.domains.edit.html',
          controller: 'EditDomainModal',
          resolve: {
            tenant: function() {
              return $rootScope.tenantName;
            },
            domain: function() {
              domain.stopWords = [];
              return domain;
            },
            mode: function() {
              return 'add';
            }
          }
        });
      };

      $scope.ok = function () {
        $uibModalInstance.close();
      };
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.filterByDirectory = function (directory) {
        $scope.searchEngine = directory.name;
        updateRanks();
      }

      $scope.filterByLocale = function(locale) {
        $scope.locale = locale;
        updateRanks();
      }

      function updateRanks (date) {
        $scope.loading = true;
        $scope.pages   = {};
        $scope.params  = {
          date: date || $scope.dateSelected,
          domain: $scope.domain,
          keyword: $scope.keyword.name,
          locale: $scope.locale,
          pageType: $scope.segment,
          locationFid: $scope.details ? $scope.details.fid : undefined,
          locationLid: $scope.details ? $scope.details.lid : undefined,
          source: $scope.source,
          dataDate: date || $scope.dateSelected,
          serpUID: $scope.serpUID,
          directory: $scope.details && $scope.details.directory ? $scope.details.directory : $scope.searchEngine ? $scope.searchEngine : undefined,
          searchEngine: $scope.searchEngine,
        };

        Keywords.getRanks($scope.params)
          .success(function(response) {
            response.keywordRanks
              .forEach(function(d){
                $scope.pages[d.rankingType] = d.pages;
              })
            if(!params.datesArray) {//Mock up: delete if and leave what's inside
              $scope.availableDates = _.map(_.toPairs(response.availableDates), function(d){ return { date: d[0], label: d[1].date, rankAvailable: d[1].rankAvailable }; });
            }
            if (!$scope.dateSelected) {
              if(date) {
                $scope.dateSelected = date;
              } else {
                var foundDate = _.find($scope.availableDates, { label: response.currentDate });
                if(foundDate) {
                  $scope.dateSelected = foundDate.date;
                }
              }
            }
            $scope.serp         = response.serp;
            $scope.keywordRanks = response.keywordRanks;
            $scope.loading      = false;
          })
          .error(function(error){
            $scope.keywordRanks = [];
            $scope.serp         = null;
            $scope.loading      = false;
          });
      }

    updateRanks()

    $scope.updateRanks = updateRanks;

    $scope.getCSVFileName = function(details) {
      return details.title + '.csv';
    }

    $scope.getCSVHeaders = function() {
      return [
        'Type',
        'Rank',
        'Title',
        'URL',
        'Audit Score',
        'Content Score',
        'Technical Score',
        'Link Score',
        'Social Score',
        'Response',
        'Duration (seconds)',
        'Category',
      ];
    };

    $scope.getCSVPages = function(pages) {
      var csvOutput = [],
          locale = Localization.getLocale();
      for (var type in pages) {
        if (pages[type] && pages[type].length) {
          pages[type].forEach(function(p) {
            var rank = p.positions
              ? _.find(p.positions, {locale: locale }).organicPos
              : p.position;
            csvOutput.push([
              type,
              rank,
              p.title,
              p.url,
              (p.averageScore ? Math.round(p.averageScore*100) : ''),
              (p.overallContentScore ? Math.round(p.overallContentScore*100) : ''),
              (p.overallTechnicalScore ? Math.round(p.overallTechnicalScore*100) : ''),
              (p.overallLinkScore ? Math.round(p.overallLinkScore*100) : ''),
              (p.overallSocialScore ? Math.round(p.overallSocialScore*100) : ''),
              p.responseInitialStatusCode,
              (p.responseDuration ? p.responseDuration / 1000 : ''),
              $scope.pageTypeDescriptions[p.pageType] || 'Other',
            ]);
          });
        }
      }
      return csvOutput;
    };

    $scope.getPagesByURL = function(url) {
      var filters = {
        size: 6,
        from: 0,
        searchText: url,
        types: ['CLIENT'],
        projects  : [],
        categories : [],
        searchTextPhraseOnly: false,
        categoriesFilterAnd: false,
        contentType: '',
        companyName: '',
        timeRange: null,
        statusRange: null,
        sort: [{
          field: null,
          asc: false,
        }]
      };

      return $http.post(appConfig.API_URL + '/pageaudit/pages.json', filters)
        .then(function(response){
          return response.data.rows.map(function(item){
            return item.url;
          });
        });
    };

    ctrl.openCitation = function(page, serp) {
      var modalInstance = $uibModal.open({
        size: 'lg',
        templateUrl: 'modules/local/views/citation-modal.html',
        resolve: {
          field: function() { return 'websiteURL'},
          location: function() { return serp.locationSnapshot },
          citation: function() { return page },
          directory: function() { return serp.directory },
          url: function() { return page.url },
          source: function() { return 'RANK' },
        },
        controller: 'CitationsCompareCtrl as ctrl'
      });
    };

    // opens a new instance of this dialog in order to show results from adhoc test
    ctrl.runSerpTest = function() {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'modules/keyword/keyword-ranks-dialog.html',
        controller: 'KeywordRankCtrl as ctrl',
        resolve: {
          params: function() {
            return {
              details: {
                location: $scope.location,
                fid: $scope.location.fid,
                lid: $scope.location.lid,
                directory: $scope.details.directory,
                title: currentUser.directoryLabels[$scope.details.directory].forRanking + ' Test',
              },
              keyword: $scope.keyword || '',
              project: null,
              rankType: $scope.rankType,
              segment: null,
              source: 'ADHOC',
              serpUID: $scope.serpUID
            };
          },
        },
        size: 'lg'
      });
    };
  }]);

'use strict';

/**
 * @ngdoc function
 * @name KeywordService.factory:Keywords
 * @description
 * # KeywordService
 * Controller of the KeywordService
 */
angular
  .module('KeywordService', [])
  .factory('Keywords', ['$http', '$q', 'Localization', 'localStorageService', 'appConfig', '$rootScope', 'ParamsService', 'DataDateService', function(
    $http,
    $q,
    Localization,
    localStorageService,
    appConfig,
    $rootScope,
    ParamsService,
    DataDateService
  ) {

    return {

      getRanks: function(params) {
        var data = {
          country: params.country,
          device: Localization.getDevice(),
          directory: params.directory,
          domain: params.domain,
          language: params.language,
          locale: params.locale || Localization.getLocale(),
          locationFid: params.locationFid,
          locationLid: params.locationLid,
          name: params.keyword,
          pageType: params.pageType || null,
          searchEngine: params.searchEngine || Localization.getSearchEngine(),
          source: params.source,
          projects: ParamsService.get("projects") ? ParamsService.get("projects") : [],
          dataDate: DataDateService.dataDate,
          dataDateTo: DataDateService.dataDateTo,
          serpUID: params.serpUID
        };
        if(params.dataDate)
          data.dataDate = params.dataDate;
        return $http.post(appConfig.API_URL + '/pageaudit/keyword-ranks.json', data);
      },

      getKeywordRanksSerpDetail: function(params) {
        var data = {
          country: params.country,
          device: Localization.getDevice(),
          directory: params.directory,
          language: params.language,
          locale: params.locale || Localization.getLocale(),
          locationFid: params.locationFid,
          name: params.keyword,
          pageType: params.pageType || null,
          projects: ParamsService.get("projects") ? ParamsService.get("projects") : [],
          searchEngine: Localization.getSearchEngine(),
          source: params.source,
          serpUID: params.serpUID,
          url: params.url,
          dataDate: DataDateService.dataDate,
          dataDateTo: DataDateService.dataDateTo
        };
        return $http.post(appConfig.API_URL + '/pageaudit/keyword-ranks-serp-detail.json', data);
      }

    }
}]);

(function(){
  'use strict';

  Controller.$inject = ['$scope', '$rootScope', '$location', '$uibModal', '$timeout', '$filter', 'Keywords', 'ParamsService', 'Citation'];
  SerpSourceModalController.$inject = ['source', 'params', 'isLocal', '$sce'];
  angular
    .module('pageauditApp')
    .directive('serpSource', directive)
    .directive('serpIframe', SerpIframeDirective)
    .controller('SerpSourceModalController', SerpSourceModalController);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      transclude: true,
      scope: true,
      bindToController: {
        ngClick: '@',
        label: '@',
        url: '<',
        params: '<',
        isLocal: '<'
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/keyword/views/serp.source.button.html'
    };


    return directive;

  }
  /* @ngInject */
  function Controller(
    $scope,
    $rootScope,
    $location,
    $uibModal,
    $timeout,
    $filter,
    Keywords,
    ParamsService,
    Citation
  ) {
    var ctrl = this;

    ctrl.modal = function() {
      var params = ctrl.params;
      params.url = ctrl.url;
      ctrl.loading = true;

      if (ctrl.isLocal) {

      }

      var promise = ctrl.isLocal ? Citation.getCitationSerpHTML : Keywords.getKeywordRanksSerpDetail;

      // Keywords.getKeywordRanksSerpDetail(params).then(function(response) {
      //   ctrl.loading = false;
      //   ctrl.html = response.data.html;
      // })
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: '/modules/keyword/views/serp.source.modal.html',
        controller: 'SerpSourceModalController as ctrl',
        scope: $scope,
        resolve: {
          source: promise(params),
          params: params,
          isLocal: ctrl.isLocal
        },
        size: 'lg'
      });
    };
  }

  // @ngInject
  function SerpSourceModalController (
    source,
    params,
    isLocal,
    $sce
  ) {
    var ctrl = this;
    ctrl.title = 'Source HTML';
    ctrl.source = source.data;
    ctrl.source.url = $sce.trustAsResourceUrl(ctrl.source.url);

    if (isLocal) {
      ctrl.defaultUrl = params.citationURL;
    } else {
      ctrl.defaultUrl = params.url;
    }
  }

  function SerpIframeDirective () {
    function link ($scope, $elem) {
      var iframe = document.createElement('iframe');
      var element0 = $elem[0];
      element0.appendChild(iframe);
      var body = iframe.contentDocument.body;

      $scope.$watch('raw', function () {
        body.innerHTML = $scope.raw;
      });
    }

    return {
      restrict: 'E',
      scope: {
        raw: '='
      },
      link: link
    }
  }
})();

(function() {
  'use strict';

  angular.module('Grid', []);

})();

(function() {
  'use strict';

  DeltaDataController.$inject = ['$uibModal'];
  angular
    .module('Grid')
    .filter('makePositive', function() {
      return function(num) {
        return isNaN(num) ? num : Math.abs(num);
      }})
    .directive('deltaData', directive);

  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: {},
      // require: '^gridValue',
      bindToController: {
        value: '<',
        delta: '<'
      },
      controller: DeltaDataController,
      controllerAs: 'ctrl',
      templateUrl: 'modules/grid/views/delta-data.html'
    };
    return directive;
  };

  /* @ngInject */
  function DeltaDataController($uibModal) {
    var ctrl = this;
  }
})();

(function() {
  'use strict';

  var module = angular.module('Grid');

  module.factory('GridWidthSrvc', ['$rootScope', '$location', function(
    $rootScope,
    $location
  ){
    var srvc = {};
    var COLWIDTH = 80;

    srvc.mainColumnWidth = function(columnCount) {
      var vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      columnCount = columnCount !== undefined ? columnCount : $rootScope.columnCount || 0;
      var element = angular.element(document.querySelectorAll('.grid--table')[0])
        , margins = 0
        , fixedCols = 0
        , aggregations = 0
        , allColsSum = columnCount * COLWIDTH
        , minWidth = 150;

      if (['/kwd', '/keyword', '/brands', '/trend', '/segment', '/competition'].indexOf($location.path()) !== -1) {
        fixedCols = 20 + 24 + 30;
      }

      if ($location.path() === '/page') {
        fixedCols = 21 + 25;
      }

      if ($location.path() === '/reports') {
        fixedCols = 20;
      }

      var total = element.width() - margins - fixedCols - allColsSum;

      $rootScope.columnCount     = columnCount;
      $rootScope.mainColumnWidth = total < minWidth ? minWidth : total;
    }

    return srvc;
  }]);

})();

(function(){
  'use strict';

  Controller.$inject = ['$scope', '$rootScope', 'ParamsService', 'GridWidthSrvc', '$uibModal', '$timeout', '$state'];
  angular
    .module('Grid')
    .directive('sortingArrows', directive);

  /* @ngInject */
  function directive(){
    var directive = {
      scope: true,
      bindToController: {
        field: '<',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      replace: true,
      restrict: 'E',
      templateUrl: 'modules/grid/views/sorting-arrows.html'
    };
    return directive;
  }

  /* @ngInject */
  function Controller($scope, $rootScope, ParamsService, GridWidthSrvc, $uibModal, $timeout, $state) {
    var ctrl = this;

    ctrl.sort = function(order) {
      if (ctrl.field.forEach && ctrl.field.length == 1) {
        ctrl.field = ctrl.field.shift();
      }

      // @todo cochihack
      // @todo this is because in backend they are using different fields to shown than sort
      var isKwd = $state.current.name === 'kwd'
      var sortingFieldsMapping = {
        'queryVolume.queryVolume' : isKwd ? 'queryVolume.queryVolume' : 'queryVolumes.queryVolume',
        'queryVolume.suggestedBid' : isKwd ? 'queryVolume.suggestedBid': 'queryVolumes.suggestedBid',
        'queryVolume.competition' : isKwd ? 'queryVolume.competition' : 'queryVolumes.competition',
        'competitionString' : 'queryVolumes.competition',
      }

      var field = _.keys(sortingFieldsMapping).indexOf(ctrl.field) > -1 ? sortingFieldsMapping[ctrl.field] : ctrl.field

      ParamsService.setObject({
        sortBy: field,
        sortAsc: order || false
      })
    }

    ParamsService.subscribe($scope, function() {
      ctrl.sortBy  = ParamsService.get('sortBy');
      ctrl.sortAsc = ParamsService.get('sortAsc');
    })
  }
})();

(function() {
  'use strict';

  Controller.$inject = ['localStorageService', 'ParamsService'];
  angular
    .module('Grid')
    .directive('gridPagination', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      restrict: 'E',
      templateUrl: 'modules/grid/views/pagination.html',
      controller: Controller,
      controllerAs: 'ctrl',
    };

    return directive;
  }

  /* @ngInject */
  function Controller(
    localStorageService,
    ParamsService
  ) {
    var ctrl = this;

    ctrl.params = ParamsService.getAll();
    ctrl.params.size = +ctrl.params.size || +localStorageService.get('pagination-size') || 10;
    ctrl.params.currentPage = +ctrl.params.currentPage || 1;

    ctrl.updatePage = function (page) {
      var page = page;
      ParamsService.setObject({
        page: (page - 1),
        currentPage: page
      });
    }

    ctrl.updatePageSize = function () {
      ctrl.params.size = ctrl.params.size || 10;
      ParamsService.setObject({
        page: 0,
        currentPage: 1,
        size: ctrl.params.size
      });
      localStorageService.set('pagination-size', ctrl.params.size);
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('app.filters', []);
})();

(function() {
  'use strict';

  Controller.$inject = ['currentUser', 'DataDateService', 'ParamsService', 'AclService'];
  angular
    .module('app.filters')
    .directive('filterDate', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      restrict: 'E',
      scope: true,
      bindToController: {
      },
      controller: Controller,
      controllerAs: '$ctrl',
      templateUrl: 'modules/filters/date/filter-date.html'
    };

    return directive;
  }

  /* @ngInject */
  function Controller(
    currentUser,
    DataDateService,
    ParamsService,
    AclService
  ) {
    var ctrl = this;

    ctrl.dataDate       = DataDateService.dataDate;
    ctrl.dataDateTo     = DataDateService.dataDateTo;

    var currentDataDate = moment(currentUser.dataDate, 'YYYYMMDD');

    var availableDates;

    // if the user is not admin (or QA), they should only see months that are the current month, or before.
    // this is to prevent users from seeing months that havent' been fully loaded and QAed yet. see LR-2954.
    if (AclService.can(['ADMIN_RLS', 'USER_RLS_ALL_LOCATIONS'])) {
      availableDates = DataDateService.availableDates();
    } else {
      availableDates = _.filter(DataDateService.availableDates(), function(d) { return d.text.isSameOrBefore(currentDataDate); });
    }

    ctrl.baseDates      = availableDates;
    ctrl.availableDates = availableDates;

    ctrl.onDataDateChange = function(dataDate) {
      DataDateService.dataDate = dataDate;
      ParamsService.notify();
    }

    ctrl.onDataDateToChange = function(dataDate) {
      DataDateService.dataDateTo = dataDate;
      ParamsService.notify();
    }
  }
})();


'use strict';

angular
  .module('Menu', [
    'Acl',
    'Shared',
    'ui.router'
  ])
  .run(['$rootScope', '$location', '$http', '$state', 'AclService', '$cookies', function ($rootScope, $location, $http, $state, AclService, $cookies) {

    function filterItemsByCapabilities (item) {
      return !(item.capabilities && !AclService.can(item.capabilities));
    }

    function loadMenu(event, newUrl, oldUrl) {
      if ($rootScope.mainMenu) {
        $rootScope.mainMenu.map(function(item) {
          item.active = false;
          if (item.path === $location.path()) {
            item.active = true;
            $rootScope.subMenuItems = item.items.filter(function(i){
              return i.disabled !== true;
            }).filter(filterItemsByCapabilities) || [];
            return;
          } else if (item.items) {
            item.items.map(function(subitem){
              if (subitem.path === $location.path()) {
                item.active = true;
                subitem.active = true;
                $rootScope.subMenuItems = item.items.filter(function(i){
                  return i.disabled !== true;
                }).filter(filterItemsByCapabilities) || [];
                return;
              }
            })
          }
        });
      }
    }

    function customTenantMenuNaming (menu) {
      if (['ibm', 'ibmtest'].indexOf($rootScope.tenantName) !== -1) {
        menu.map(function(item){
          if (item.state === 'keywords') {
            item.label = 'KIS Research';
          }
          return item;
        })
      }

      return menu;
    }

    $http.get('/config/mainMenu.json').then(function(response) {
      var menuItems = response.data.filter(function(item) {
        if (item.disabled) {
          return false;
        } else if (item.capabilities && !AclService.can(item.capabilities)) {
          return false
        } else {
          return true;
        }
      });

      // Customize Menu labels depending current Tenant
      $rootScope.mainMenu = customTenantMenuNaming(menuItems);

      loadMenu();
    });

    $rootScope.$on('$locationChangeStart', function(){
      $rootScope.subMenuItems = null;
      return loadMenu();
    });
  }]);

(function() {
  'use strict';

  angular
    .module('Menu')
    .factory('MenuService', factory);

  factory.$inject = ['$rootScope'];

  /* @ngInject */
  function factory($rootScope) {
    var srvc = {}

    srvc.getSubitems = function () {
      return $rootScope.subMenuItems
    }

    return srvc;
  }
})();

(function() {
  'use strict';

  angular
    .module('Menu')
    .directive('topnavMenu', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      templateUrl: 'modules/menu/topnav-menu.html'
    };

    return directive;
  }

})();

(function() {
  'use strict';

  config.$inject = ['$stateProvider'];
  run.$inject = ['$rootScope'];
  angular
    .module('app.manage', [
      'ui.router',
      'ui.validate',
      'ui-notification',
      'Shared'
    ])
    .config(config)
    .run(run);

  function config ($stateProvider) {
    $stateProvider
      .state('manage', {
        abstract: true,
        url: '/manage',
        template: '<div id="manage"><ui-view/></div>'
      })
      .state('manage.tenants', {
        url: '/tenants',
        templateUrl: 'modules/manage/tenants/tenants.list.html',
        controller: 'AdminTenantsController as ctrl',
      })
      .state('manage.qaDashboard', {
        url: '/qa-dashboard',
        templateUrl: 'modules/manage/qa/qa.dashboard.html',
        controller: 'AdminQADashboardController as ctrl',
      })
  }

  function run ($rootScope) {
    // @todo This should be moved to a Factory
    $rootScope.gridUtils = {
      toggleChecked: function(list) {
        if (!list) return;
        var status = !list.allchecked;
        list.forEach(function(item) {
          item.checked = status;
        });
        list.allchecked = status;
      },
      getChecked: function(list) {
        if (!list) return [];
        return list.filter(function(item){ return item.checked });
      },
      updateChecked: function(list) {
        if (!list) return 0;
        var checked = this.getChecked(list);
      }
    };
  }

})();

'use strict';

angular
  .module('app.manage')
  .factory('RootAdminService', ['$http', 'appConfig', function($http, appConfig) {

    return {
      // TENANTS
      getTenants: function() {
        return $http.get(appConfig.API_URL + '/admin/tenant/list.json', {
          params: {
            from: 0,
            size: 1000
          }
        });
      },

      createTenant: function(data) {
        return $http.put(appConfig.API_URL + '/admin/create-index.json', data);
      },

      deleteTenant: function(tenantName){
        return $http.delete(appConfig.API_URL + '/admin/'+tenantName+'.json');
      },

      getCreationProgress: function() {
        return $http.get(appConfig.API_URL + '/admin/create-index-progress.json');
      }

    }
}]);

'use strict';

angular
  .module('app.manage')
  .controller('AdminTenantsController', ['$uibModal', '$scope', '$state', 'RootAdminService', 'Notification', '$interval', function (
    $uibModal,
    $scope,
    $state,
    RootAdminService,
    Notification,
    $interval
  ) {
    $scope.tenants = [];
    $scope.loading = true;

    var intP = $interval(function() {
      getCreationData();
    }, 5000);

    getCreationData();

    function getCreationData() {
      return RootAdminService.getCreationProgress()
        .then(function(creationResponse) {
          $scope.creations = creationResponse.data.response;
          
          var anyRunning = false;
          for (var c in $scope.creations) {
            anyRunning = $scope.creations[c].status == 'RUNNING';
          }
          if (!anyRunning) {
            console.log("cancelling creation polling");
            $interval.cancel(intP);
          }
        });
    }

    RootAdminService.getTenants()
      .success(function (response) {
          $scope.tenants = response.rows;
          $scope.loading = false;
      })
      .error(function (error) {
        console.log(error)
      });

    $scope.add = function() {
      $interval.cancel(intP);
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/manage/tenants/tenants.add.html',
        controller: 'AddTenantModal',
        keyboard: false,
        backdrop: 'static'
      });

      modalInstance.result.then(function () {
          // called when you close the modal
          intP = $interval(function() {
            getCreationData();
          }, 5000);
      }, function () {
          // called when you dismiss modal by clicking anywhere outside modal
          intP = $interval(function() {
            getCreationData();
          }, 5000);
      });
    };

    $scope.delete = function(checkedTenants) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/manage/tenants/tenants.delete.html',
        controller: 'DeleteTenantModal',
        resolve: {
          tenant: function() {
            return checkedTenants;
          }
        }
      });
    };
}]);

angular
  .module('app.manage')
  .controller('DeleteTenantModal', ['$scope', '$state', '$uibModalInstance', 'RootAdminService', 'tenant', 'Notification', function (
    $scope,
    $state,
    $uibModalInstance,
    RootAdminService,
    tenant,
    Notification
  ) {
    $scope.tenant = tenant;
    $scope.delete = function(isValid, tenantName) {
      if (isValid) {
        RootAdminService.deleteTenant(tenantName).then(function (response) {
          $uibModalInstance.close();
          $state.reload();
          Notification.success('Tenant successfully deleted.');
        });
      }
    }
}]);

angular
  .module('app.manage')
  .controller('AddTenantModal', ['$scope', '$state', '$uibModalInstance', 'RootAdminService', 'Notification', '$interval', function (
    $scope,
    $state,
    $uibModalInstance,
    RootAdminService,
    Notification,
    $interval
  ) {

    var intP;

    function getCreationData() {
      return RootAdminService.getCreationProgress()
        .then(function(creationResponse) {
          $scope.creations = creationResponse.data.response;
        });
    }

    $scope.tenant = { configureDefaultDirectories: true, importLocations:true }; // default tenant object
    $scope.keywords = [];
    for(var i = 0; i < 5; i++) {
      $scope.keywords.push({value: ""});
    }

    $scope.addKeyword = function () {
      if (!$scope.keywords.filter(function (d) {
          return d.value == "";
        }).length) {
        $scope.keywords.push({value: ""});
      }
    };

    $scope.removeKeyword = function (i) {
      $scope.keywords = $scope.keywords.filter(function (d, j) {
        return i !== j;
      });
    };

    $scope.close = function() {
      $uibModalInstance.close();
      $state.reload();
      
      // Notification.success('Tenant creation started');
      $scope.tenant = { configureDefaultDirectories: true, importLocations:true }; // default tenant object
    };

    $scope.addTenant = function(isValid, tenant) {
      if (isValid) {
        $scope.loading = true;

        tenant.keywords = _.filter(_.map($scope.keywords, 'value'), function(k) {
            return k != null && k.trim().length > 0;
        });

        function handleRes(res) {
          var c = res[tenant.tenant];
          $scope.creationStatus = c.status;
          $scope.errorMessage = c.errorMessage;
          $scope.currentOperation = c.currentOperation;

          if ($scope.creationStatus !== 'RUNNING') {
            $scope.loading = false;
            $interval.cancel(intP);
          }
        }

        RootAdminService.createTenant(tenant).success(function (response) {
          handleRes(response.response);
          intP = $interval(function() {
            getCreationData().then(function() {
              handleRes($scope.creations);
            });
          }, 5000);
        }).error(function (response) {
          $scope.loading = false;
          $interval.cancel(intP);
          Notification.error(response.messages[0]);
        });
      }
    }
}]);


'use strict';

angular
  .module('app.manage')
  .factory('DataQualityService', ['$http', 'appConfig', function($http, appConfig) {
    return {
        getReports: function(dataDate) {
          return $http.get(appConfig.API_URL + '/admin/data-quality/report/' + dataDate + '.json');
        },
        getCitationDetail: function(tenant, did, dataDate) {
            return $http.get(appConfig.API_URL + '/admin/data-quality/citation-detail/' + tenant + '-' + did + '-' + dataDate + '.json');
        }
    };
}]);
'use strict';

angular
  .module('app.manage')
  .controller('AdminQADashboardController', ['$uibModal', '$scope', '$state', 'RootAdminService', 'DataQualityService', 'LocationService', 'Notification', '$interval', '$anchorScroll', function (
    $uibModal,
    $scope,
    $state,
    RootAdminService,
    DataQualityService,
    LocationService,
    Notification,
    $interval,
    $anchorScroll
  ) {

    var ctrl = this;

    $scope.loading = true;
    $scope.qaDates = [];
    $scope.selectedDate = undefined;
    $scope.reports = [];
    $scope.selectedClient = undefined;
    $scope.selectedMonth = undefined;

    $scope.showNAPTable = false;
    $scope.tableRanking = false;

    $scope.$watch('selectedDate', function(newVal, oldVal, scope) {
        loadData(newVal);
    });

    $scope.getReportDataForDir = function(directory, dirData) {
        return _.find(dirData, function(d) { return d.directory == directory});
    }

    $scope.getClass = function(dirReport) {
        if (!dirReport) {
            return 'dq-na';
        }

        var diff = dirReport.targetAccuracy - dirReport.accuracy;

        if (diff == 0 || diff < 0) {
            return 'dq-good';
        } else if (diff <= 0.05) {
            return 'dq-warn';
        }

        return 'dq-bad';
    }

    $scope.getOverallClass = function(report) {
        if (!report) {
            return 'dq-na';
        }

        var diff = report.targetOverallAccuracy - report.overallAccuracy;

        if (diff == 0 || diff < 0) {
            return 'dq-good';
        } else if (diff <= 0.05) {
            return 'dq-warn';
        }

        return 'dq-bad';
    }

    $scope.getTrendClass = function(dirReport) {
        if (dirReport) {
            var diff = dirReport.targetAccuracy - dirReport.accuracy;
            if (diff == 0) {
                return 'zmdi zmdi-trending-flat';
            } else if (diff < 0) {
                return 'zmdi zmdi-trending-up';
            } else {
                return 'zmdi zmdi-trending-down';
            }
        }

        return '';
    }

    $scope.getOverallTrendClass = function(report) {
        if (report) {
            var diff = report.targetOverallAccuracy - report.overallAccuracy;
            if (diff == 0) {
                return 'zmdi zmdi-trending-flat';
            } else if (diff < 0) {
                return 'zmdi zmdi-trending-up';
            } else {
                return 'zmdi zmdi-trending-down';
            }
        }

        return '';
    }

    $scope.selectClient = function(report) {
        ctrl.listing = [];
        $scope.showNAPTable = false;
        $scope.selectedClient = report.tenant;
        $scope.selectedMonth = report.dataDate;

        DataQualityService.getCitationDetail(report.tenant, report.did, report.dataDate)
        .then(function(response) {
            if (response.data.ok === false) {
                Notification.error("Error loading citation detail");
            } else {
                ctrl.listing = response.data.response.directories.map(function(i){
                    i.totalItems = i.found.count + i.notFound.count;
                    return i;
                });
                ctrl.total   = response.data.total;
                
                $scope.showNAPTable = true;
                $anchorScroll("napTable");
            }
        });
    }

    $scope.showHelp = function() {
        $scope.modalInstance = $uibModal.open({
            templateUrl: 'modules/manage/qa/qa.help.html',
          });
    }

    var init = function() {
        loadDates();
    };
    
    var loadDates = function() {
        // end date is next month, start date is 2 years ago from this month
        var currentMonth = moment.utc().startOf('day').startOf('month');
        var endDate = moment(currentMonth).add(1, 'months');
        var startDate = moment(currentMonth).subtract(2, 'years');
        $scope.selectedDate = endDate.format('YYYYMMDD');
        
        for (var m = moment(endDate); m.diff(startDate, 'days') > 0; m.subtract(1, 'months')) {
            $scope.qaDates.push(m.format('YYYYMMDD'));
        }
    }

    var loadData = function(dataDate) {
        ctrl.listing = [];
        $scope.showNAPTable = false;
        $scope.selectedClient = undefined;
        $scope.selectedMonth = undefined;
        console.log("loading for " + dataDate);

        DataQualityService.getReports(dataDate).then(function(res) {
            if (res.data && res.data.ok === true) {
                $scope.reports = res.data.response;
                $scope.loading = false;
            } else {
                Notification.error("Error loading data quality reports!");
                $scope.loading = false;
            }
        });
    };

    init();





    $scope.directories = [
        "GOOGLE_API",
        "GOOGLE_SCRAPED",
        "BING_API",
        "BING_SCRAPED",
        "YAHOO_LOCAL",
        "FOURSQUARE",
        "YELLOWPAGES",
        "SUPERPAGES",
        "CITYSEARCH",
        "LOCALDOTCOM",
        "DEXKNOWS",
        "INSIDERPAGES",
        "CYLEX",
        "411",
        "EZLOCAL",
        "192",
        "SCOOT",
        "YELLOWBOOK"
    ];

    $scope.directoryLabels = {
        "GOOGLE_API": {
            "short": "G (API)",
            "long": "Google API"
        },
        "GOOGLE_SCRAPED": {
            "short": "G (Scraped)",
            "long": "Google Scraped"
        },
        "BING_API": {
            "short": "B (API)",
            "long": "Bing API"
        },
        "BING_SCRAPED": {
            "short": "B (Scraped)",
            "long": "Bing Scraped"
        },
        "YAHOO_LOCAL": {
            "short": "Y",
            "long": "Yahoo"
        },
        "FOURSQUARE": {
            "short": "FSQ",
            "long": "Foursquare"
        },
        "YELLOWPAGES": {
            "short": "YP",
            "long": "YellowPages"
        },
        "SUPERPAGES": {
            "short": "SP",
            "long": "SuperPages"
        },
        "CITYSEARCH": {
            "short": "CS",
            "long": "CitySearch"
        },
        "LOCALDOTCOM": {
            "short": "LDC",
            "long": "Local.com"
        },
        "DEXKNOWS": {
            "short": "DK",
            "long": "DexKnows"
        },
        "INSIDERPAGES": {
            "short": "IP",
            "long": "InsiderPages"
        },
        "CYLEX": {
            "short": "CY",
            "long": "Cylex"
        },
        "411": {
            "short": "411",
            "long": "411.ca"
        },
        "EZLOCAL": {
            "short": "EZL",
            "long": "EZLocal"
        },
        "192": {
            "short": "192",
            "long": "192.uk"
        },
        "SCOOT": {
            "short": "SCT",
            "long": "Scoot.uk"
        },
        "YELLOWBOOK": {
            "short": "YB",
            "long": "Yellowbook"
        },
    }
}]);

'use strict';

angular
  .module('app.admin', [
    'ui.router',
    'ui.validate',
    'ui-notification',
    'colorpicker.module',
    'Acl',
    'Grid',
    'KeywordService',
    'Menu',
    'Shared'
  ])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider
      .state('admin', {
        abstract: true,
        url: '/admin',
        template: '<ui-view/>',
        controller: 'AdminController',
        resolve: {
          tenantName: ['localStorageService', function(localStorageService) {
            return localStorageService.get('tenantGlobalKey');
          }]
        }
      })
      .state('admin.index', {
        url: '',
        controller: ['$state', '$rootScope', '$timeout', '$location', 'MenuService', function($state, $rootScope, $timeout, $location, MenuService) {
          $timeout(function(){
            var subitems = MenuService.getSubitems();
            if (subitems && subitems[0]) {
              $state.go(subitems[0].state);
            } else {
              $location.path('/intro');
            }
          }, 0);
        }],
      })
      .state('admin.brands', {
        url: '/brands',
        templateUrl: 'modules/admin/views/admin.tenants.settings.brands.html',
        controller: 'AdminSettingsBrandsController',
        resolve: {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can(['ADMIN', 'ADMIN_DM', 'DOMAIN_MANAGER'])) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.domains', {
        url: '/domains',
        templateUrl: 'modules/admin/views/admin.tenants.settings.domains.html',
        controller: 'AdminSettingsDomainsController',
        resolve: {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can(['ADMIN', 'ADMIN_DM', 'DOMAIN_MANAGER'])) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.strategies', {
        url: '/strategies',
        templateUrl: 'modules/admin/views/admin.tenants.settings.strategies.html',
        controller: 'AdminSettingsStrategiesController',
        resolve: {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can(['ADMIN', 'ADMIN_DM', 'DOMAIN_MANAGER'])) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.locations', {
        url: '/locations',
        templateUrl: 'modules/admin/views/admin.tenants.settings.locations.html',
        controller: 'AdminSettingsLocationsController',
        resolve : {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can('ADMIN')) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.users', {
        url: '/users',
        templateUrl: 'modules/admin/views/admin.users.html',
        controller: 'AdminUsersController',
        resolve : {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can('ADMIN')) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.settings', {
        url: '/settings',
        templateUrl: 'modules/admin/views/admin.tenants.settings.general.html',
        controller: 'AdminSettingsGeneralController',
        resolve : {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can('ADMIN')) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.directories', {
        url: '/directories',
        templateUrl: 'modules/admin/views/admin.tenants.settings.directories.html',
        controller: 'AdminSettingsDirectoriesController',
        resolve: {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can(['ADMIN', 'ADMIN_DM', 'DOMAIN_MANAGER'])) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.localkeyword', {
        url: '/local-keywords',
        templateUrl: 'modules/admin/views/admin.tenants.settings.localkeywords.html',
        controller: 'AdminSettingsLocalKeywordsController',
        resolve: {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can(['ADMIN', 'ADMIN_DM', 'DOMAIN_MANAGER'])) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.googleoauth', {
        url: '/google-oauth',
        templateUrl: 'modules/admin/views/admin.google-oauth.html',
        controller: 'GoogleOAuthController',
        resolve: {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can(['ADMIN'])) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('admin.googleoauthcallback', {
        url: '/google-oauth-callback',
        templateUrl: 'modules/admin/views/admin.google-oauth-callback.html',
        controller: 'GoogleOAuthController',
        resolve: {
          acl: ['$q', 'AclService', function ($q, AclService) {
            if (AclService.can(['ADMIN'])) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
  }])
  .run(['$rootScope', 'localStorageService', '$state', '$stateParams', 'ParamsService', function($rootScope, localStorageService, $state, $stateParams, ParamsService) {
    $rootScope.tenantName = localStorageService.get('tenantGlobalKey') || null;
    var size = localStorageService.get('pagination-size');
    ParamsService.setSilent('size', size);
  }]);


'use strict';

angular
  .module('app.admin')
  .controller('AdminController', ['$uibModal', '$scope', '$rootScope', function ($uibModal, $scope, $rootScope) {

    $scope.tenantName = $rootScope.tenantName;

    $scope.isUserLoggedIn = function() {
      return false;
    }

    $scope.toggleChecked = function(list) {
      if (!list) return;
      var status = !list.allchecked;
      list.forEach(function(item) {
        item.enabled = status;
      });
      list.allchecked = status;
    }

    $scope.getChecked = function(list) {
      if (!list) return [];
      return list.filter(function(item){ return item.enabled || item.checked });
    };

    $scope.updateChecked = function(list) {
      if (!list) return 0;
      var checked = $scope.getChecked(list);
      list.allchecked = list.length === checked.length;
      return true;
    };

  }]);

'use strict';

angular
  .module('app.admin')
  .controller('AdminUsersController', ['$uibModal', '$scope', '$rootScope', '$state', '$stateParams', 'AdminService', 'tenantName', 'localStorageService', 'ParamsService', function (
    $uibModal,
    $scope,
    $rootScope,
    $state,
    $stateParams,
    AdminService,
    tenantName,
    localStorageService,
    ParamsService
  ) {

    $scope.users = [];
    $rootScope.params = ParamsService.params;

    loadData();

    function loadData(scope, params) {
      var paramKeys = params ? Object.keys(params) : {};
      if (paramKeys.length && paramKeys[0] == 'locales') {
        return;
      }
      $scope.loading = true;

      AdminService.getRoles(tenantName)
        .then(function(response) {
          $scope.roles = response.data;
        });

      AdminService.getUsers(tenantName, ParamsService.params)
        .then(function (response) {
          $scope.users = response.data.rows;
          $scope.totalCount = response.data.totalCount;
          $scope.loading = false;
        });
    }

    ParamsService.subscribe($scope, loadData);

    $scope.selectRole = function(role) {
      ParamsService.set('role', $scope.selectedRole);
    }

    $scope.search = function($event) {
      if ($event.charCode === 13) {
        $scope.params.from = 0;
        loadData();
      }
    }

    $scope.importDialog = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.users.import.html',
        controller: 'ImportUsersModal',
        size: 'lg',
        resolve: {
          tenantName: function() {
            return tenantName;
          }
        }
      });
    };

    $scope.add = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.users.edit.html',
        controller: 'EditUserModal',
        resolve: {
          user: function() {
            return {
              roles: []
            };
          },
          tenant: function() {
            return tenantName;
          }
        }
      });
    };

    $scope.edit = function(user) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.users.edit.html',
        controller: 'EditUserModal',
        resolve: {
          user: function() {
            return _.clone(user);
          },
          tenant: function() {
            return tenantName;
          }
        }
      });
    };

    $scope.delete = function(checkedUsers) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.users.delete.html',
        controller: 'DeleteUserModal',
        resolve: {
          users: function() {
            return checkedUsers;
          },
          tenant: function() {
            return tenantName;
          }
        }
      });
    };

    $scope.getChecked = function(items) {
      return _.filter(items, { checked: true});
    };
  }]);

angular
  .module('app.admin')
  .controller('ImportUsersModal', ['$scope', '$state', '$uibModalInstance', '$timeout', 'AdminService', 'tenantName', 'FileSaver', 'Notification', function ($scope, $state, $uibModalInstance, $timeout, AdminService, tenantName, FileSaver, Notification) {
    $scope.downloadTemplate = function() {
      AdminService.importUsersTemplate($scope.tenantName)
        .then(function(response){
          var data = new Blob([response.data], { type: 'text/csv;charset=utf-8' }),
            timestamp = new Date().toISOString().substring(0, 10),
            config = {
              data: data,
              filename: 'download-users-template.csv'
            };
          FileSaver.saveAs(config);
        })
    };

    $scope.uploadFile = function(file){
      AdminService.uploadUsersData(tenantName, file)
        .success(function(response){
          $state.reload();
          Notification.success('Users data successfully imported to the database.');
        })
        .error(function(error){
          Notification.error('A problem ocurr while importing the file.');
        });
    };
}]);

angular
  .module('app.admin')
  .controller('DeleteUserModal', ['$scope', '$state', '$uibModalInstance', '$timeout', 'AdminService', 'Notification', 'tenant', 'users', function ($scope, $state, $uibModalInstance, $timeout, AdminService, Notification, tenant, users) {
    $scope.users = users;
    $scope.delete = function(users) {
      users.forEach(function(user) {
        AdminService.deleteUser(tenant, user).then(function (response) {
        });
      });

      $timeout(function() {
        $uibModalInstance.close();
        $state.reload();
        Notification.success('Users successfully deleted.');
      }, 1000);
    }
}]);

angular
  .module('app.admin')
  .controller('AddUserModal', ['$q', '$scope', '$state', '$uibModalInstance', 'AdminService', 'Notification', 'tenant', function ($q, $scope, $state, $uibModalInstance, AdminService, Notification, tenant) {

    $scope.addUser = true;

    $scope.checkUserId = function(userId) {
      return AdminService.getUser($scope.tenantName, userId).then(function (response) {
        if (response.status === 200 && response.data.userId !== userId) {
          return response.data;
        } else {
          return $q.reject();
        }
      });
    }

    AdminService.getRoles(tenant)
      .then(function(response) {
        $scope.roles = response.data.sort();
      });

}]);

angular
  .module('app.admin')
  .controller('EditUserModal', ['$scope', '$state', '$uibModalInstance', '$rootScope', 'AdminService', 'Notification', 'user', 'tenant', '$http', 'appConfig', function (
    $scope,
    $state,
    $uibModalInstance,
    $rootScope,
    AdminService,
    Notification,
    user,
    tenant,
    $http,
    appConfig
  ) {
    $scope.user = user;

    $scope.add = !user.userId;

    var orig = angular.copy($scope.user);
    $scope.reset = function (scope) {
      angular.copy(orig, scope);
    }

    AdminService.getRoles(tenant)
      .then(function(response) {
        $scope.roles = response.data.sort();
      });

    if (user.userId) {
      // AdminService.getProjectsPermissionsByUser(tenant, user.userId)
      //   .then(function(response){
      //     response.data.forEach(function(i){
      //       return $scope.selectedProjects[i.name] = i.name;
      //     })
      //   });
    }

    $scope.selectRole = function() {
      var exists = $scope.user.roles.filter(function(d){return d === $scope.selectedRole}).length;
      if (!exists && $scope.selectedRole) {
        $scope.user.roles.push($scope.selectedRole);
      }
      $scope.selectedRole = '';
    };

    $scope.removeRole = function(role) {
      $scope.user.roles = $scope.user.roles.filter(function(d) {
        return d !== role;
      });
    };

    $scope.locationDisabled = function(ivhNode, ivhIsSelected, ivhTree) {
      console.log(ivhNode, ivhIsSelected, ivhTree);
    };

    $scope.selectedProjects = {};
    $scope.selectProject = function($item) {
      $scope.selectedProjects[$item] = $item;
      $scope.project = '';
    };

    $scope.removeProject = function($item) {
      delete $scope.selectedProjects[$item];
    };

    // $scope.projects = $rootScope.getProjects();

    function selectedTreeItems(nodes) {
      var selected = [];

      for (var ix = 0, len = nodes.length; ix < len; ix++) {
        var node = nodes[ix];
        if (!!node.selected) {
          selected.push(node.path);
        }
        if (node.children !== undefined && node.children.length > 0) {
          selected = selected.concat(selectedTreeItems(node.children));
        }
      }
      return selected;
    }

    $scope.addUser = function(isValid, user) {
      if (isValid) {
        if (tenant) {
          user.tenant = tenant;
        }
        AdminService.addUser(tenant, user).then(function (response) {
          $uibModalInstance.close();
          $state.reload();
          Notification.success('User successfully added.');
        }).catch(function(error){
          if (error.status === 409) {
            Notification.warning('User already exists.');
          } else {
            Notification.error('Some problem happened while adding the new user.');
          }
        });
      }
    }

    $scope.updateUser = function(isValid, user) {
      if (isValid) {
        if (tenant) {
          user.tenant = tenant;
        }
        delete user.tenantGloblaKey;
        delete user.dashboardConfigHome;
        delete user.role;

        AdminService.updateUser(tenant, user).success(function (response) {
          Notification.success('User successfully updated.');
          $state.reload();
          $uibModalInstance.close();
          $http.get(appConfig.API_URL + '/user/current.json')
            .then(function(response) {
              console.log(response.data.roles)
            });
        });
      }
    }
}]);

'use strict';

angular
  .module('app.admin')
  .controller('AdminSettingsGeneralController', ['$scope', '$rootScope', 'AdminService', 'Notification', function ($scope, $rootScope, AdminService, Notification) {

    AdminService.getTypes($rootScope.tenantName)
      .success(function (response) {
        if (response && response.types) {
          $scope.availableTypes = response.types;
          $scope.tenantTypes = {};
          for (var i = 0; i < $scope.availableTypes.length; i++) {
            $scope.tenantTypes[$scope.availableTypes[i]] = false;
          }
        }
      }).then(function(){
        AdminService.getTenant($rootScope.tenantName)
        .success(function (tenant) {
          for (var i = 0; i < tenant.types.length; i++) {
            $scope.tenantTypes[tenant.types[i]] = true;
          }
          tenant.logoPath = tenant.logo;
          tenant.whiteLabelLogoPath = tenant.whiteLabelLogo;
          if (!tenant.citationAuditEnabled) {
            tenant.citationAuditEnabled = false;
          }
          if (!tenant.reportingPeriod) {
            tenant.reportingPeriod = "MONTH";
          }
          $scope.tenant = tenant;
        });
      });

    $scope.uiConfig = $rootScope.tenantConfig;

    if ($scope.tenant.searchableProfileFields) {
      $scope.searchableProfileFields = $scope.tenant.searchableProfileFields.map(function (d) {
        return {
          value: d,
        }
      });
    } else {
      $scope.searchableProfileFields = [];
    }

    $scope.addSearchableProfileField = function () {
      if (!$scope.searchableProfileFields.filter(function (d) {
          return d.value == "";
        }).length) {
        $scope.searchableProfileFields.push({value: ""});
      }
    };

    $scope.removeSearchableProfileField = function (i) {
      $scope.searchableProfileFields = $scope.searchableProfileFields.filter(function (d, j) {
        return i !== j;
      });
    };

    if ($scope.tenant.facebookPageNames) {
      $scope.facebookPageNames = $scope.tenant.facebookPageNames.map(function (d) {
        return {
          value: d,
        }
      });
    } else {
      $scope.facebookPageNames = [];
    }
    $scope.addFacebookPageName = function () {
      if (!$scope.facebookPageNames.filter(function (d) {
          return d.value == "";
        }).length) {
        $scope.facebookPageNames.push({value: ""});
      }
    };

    $scope.removeFacebookPageName = function (i) {
      $scope.facebookPageNames = $scope.facebookPageNames.filter(function (d, j) {
        return i !== j;
      });
    };

    $scope.toggleTenantType = function (type) {
      var types = [];
      for (var i = 0; i < $scope.availableTypes.length; i++) {
        $scope.tenantTypes[$scope.availableTypes[i]] && types.push($scope.availableTypes[i]);
      }
      $scope.tenant.types = types;
    }
    
    $scope.update = function (isValid, data) {

      var data = {
        tenant: $rootScope.tenantName,
        types: data.types,
        logoPath: data.logoPath,
        reportingPeriod: data.reportingPeriod,
        whiteLabelLogoPath: data.whiteLabelLogoPath,
        appTitle: data.appTitle,
        logsEnabled: data.logsEnabled,
        citationAuditEnabled: data.citationAuditEnabled,
        citationAuditPeriodInMonths: data.citationAuditPeriodInMonths,
        facebookBusinessId: data.facebookBusinessId
      };

      if (isValid) {
        data.searchableProfileFields = _.map($scope.searchableProfileFields, 'value');
        data.facebookPageNames = _.map($scope.facebookPageNames, 'value');

        AdminService.updateTenant($rootScope.tenantName, data).success(function (response) {
          // Update tenant object to avoid reload
          angular.merge($rootScope.tenant, {
            logo: data.logoPath,
            whiteLabelLogo: data.whiteLabelLogoPath,
            appTitle: data.appTitle,
          });
          Notification.success('Settings successfully updated.');
        });
      }
    };

    $scope.updateConfig = function () {
      var config = $scope.uiConfig;
      AdminService.updateTenantConfig($rootScope.tenantName, config)
        .then(function (response) {
          Notification.success('Settings successfully updated.');
        });
    }
  }]);

angular
  .module('app.admin')
  .controller('AdminSettingsBrandsController', ['$uibModal', '$rootScope', '$scope', '$state', '$stateParams', 'AdminService', 'FileSaver', 'localStorageService', 'Notification', 'ParamsService', function ($uibModal,
                                                         $rootScope,
                                                         $scope,
                                                         $state,
                                                         $stateParams,
                                                         AdminService,
                                                         FileSaver,
                                                         localStorageService,
                                                         Notification,
                                                         ParamsService) {
    $rootScope.params = ParamsService.params;

    $scope.downloadCSV = function () {
      AdminService.getBrandsCSV($scope.tenantName, ParamsService.params)
        .then(function (response) {
          var data = new Blob([response.data], {type: 'text/csv;charset=utf-8'}),
            timestamp = new Date().toISOString().substring(0, 10),
            config = {
              data: data,
              filename: 'brands_' + timestamp + '.csv'
            };
          FileSaver.saveAs(config);
        })
    };

    $scope.initList = function () {
      $scope.loadData();
    };

    $scope.searchDomains = function (partialDomainName) {
      var params = {
        searchText: partialDomainName,
        page: 0,
        size: 10,
      }
      AdminService.getDomains($scope.tenantName, params)
        .success(function (response) {
          $scope.domains = [];
          for (var i = 0; i < response.rows.length; i++) {
            $scope.domains.push(response.rows[i].domain);
          }
        })
        .error(function (error) {
          console.log(error)
        });
    };

    $scope.loadData = function () {
      $scope.loading = true;
      AdminService.getBrands($rootScope.tenantName, ParamsService.params)
        .success(function (response) {
          $scope.items = response.rows;
          $scope.totalCount = response.totalCount;
          $scope.loading = false;
        })
        .error(function (error) {
          console.log(error)
        });
    }

    ParamsService.subscribe($scope, $scope.loadData);

    $scope.add = function () {
      $scope.action = 'add';
      $scope.item = {};

      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.brands.form.html',
        controller: 'AdminSettingsBrandsController',
        scope: $scope,
      });
    };


    $scope.edit = function (item) {
      $scope.action = 'edit';
      $scope.item = angular.copy(item);
      $scope.addAnother = false;

      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.brands.form.html',
        controller: 'AdminSettingsBrandsController',
        scope: $scope,
      });
    };

    $scope.save = function (item, addAnother) {
      $scope.saving = true;
      var item = {
        name: item.name,
        domain: item.domain,
        segment: item.segment,
        uid: item.uid,
      };

      AdminService.saveBrand($rootScope.tenantName, item)
        .then(function (response) {
          $scope.saving = false;
          if (addAnother) {
            $scope.item = {};
          } else {
            $scope.modalInstance.close();
            $state.reload();
          }
          Notification.success('Brand saved successfully.');
        }, function (response) {
          if (response.status === 409) {
            Notification.error('Brand already exist. Please check for duplicates.');
            $scope.saving = false;
          }
          console.log('errror', response)
        });
    }

    $scope.delete = function (items) {
      var deleteBrands = [];
      items.forEach(function (brand) {
        deleteBrands.push(brand.uid);
      });

      AdminService.deleteBrand($rootScope.tenantName, deleteBrands).success(function (response) {
        $scope.modalInstance.close();
        $state.reload();
        Notification.success('Brand deleted successfully.');
      });
    }

    $scope.deleteModal = function (checkedItems) {
      $scope.deleteItems = checkedItems;
      $scope.type = 'Brands';
      $scope.modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.delete-dialog.html',
        controller: 'AdminSettingsBrandsController',
        scope: $scope,
      });
    };

    $scope.saveMultiple = function (selectedItems) {
      AdminService.saveMultipleBrands($rootScope.tenantName, selectedItems, ParamsService.params).success(function (response) {
        $scope.modalInstance.close();
        $state.reload();
        Notification.success('Brands Updated successfully.');
      });
    }
  }]);

angular
  .module('app.admin')
  .controller('AdminSettingsDomainsController', ['$uibModal', '$rootScope', '$scope', '$state', '$stateParams', 'AdminService', 'localStorageService', 'ParamsService', function ($uibModal,
                                                          $rootScope,
                                                          $scope,
                                                          $state,
                                                          $stateParams,
                                                          AdminService,
                                                          localStorageService,
                                                          ParamsService) {
    $rootScope.params = ParamsService.params;
    $scope.domains = [];

    loadData();
    function loadData() {
      $scope.loading = true;

      AdminService.getDomains($scope.tenantName, ParamsService.params)
        .success(function (response) {
          $scope.domains = response.rows;
          $scope.totalCount = response.totalCount;
          $scope.loading = false;
          var i = 0;
          var len = response.rows.length;
          var domain;
          if (!$rootScope.domain)
            $rootScope.domain = [];
          while (i < len) {
            domain = response.rows[i++];
            $rootScope.domain[domain.title] = domain;
          }
        })
        .error(function (error) {
          console.log(error)
        });
    }

    ParamsService.subscribe($scope, loadData);

    $scope.loadData = loadData;

    $scope.add = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.domains.edit.html',
        controller: 'EditDomainModal',
        resolve: {
          domain: function () {
            var d = new Object();
            d.stopWords = [];
            return d;
          },
          tenant: function () {
            return $scope.tenantName;
          },
          mode: function () {
            return "add";
          }
        }
      });
    };

    $scope.edit = function (domain) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.domains.edit.html',
        controller: 'EditDomainModal',
        resolve: {
          domain: function () {
            return domain;
          },
          tenant: function () {
            return $scope.tenantName;
          },
          mode: function () {
            return "edit";
          }
        }
      });
    };

    $scope.delete = function (checkedDomains) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.domains.delete.html',
        controller: 'DeleteDomainsModal',
        resolve: {
          domains: function () {
            return checkedDomains;
          },
          tenant: function () {
            return $scope.tenantName
          }
        }
      });
    };
  }]);

//Strategies Controller
angular
  .module('app.admin').filter('orderSubStrategy', function () {
  return function (items, field) {
    var res = [];
    _(items).keys().sort().each(function (key) {
      res.push(items[key]);
    });
    return res;
  }
})
  .controller('AdminSettingsStrategiesController', ['$rootScope', '$scope', '$compile', 'AdminService', 'ParamsService', 'Notification', 'LocationService', function ($rootScope,
                                                             $scope,
                                                             $compile,
                                                             AdminService,
                                                             ParamsService,
                                                             Notification,
                                                             LocationService) {
    $scope.params = ParamsService.getAll();
    $scope.currentTab = {"root": {"index": 0, childs: []}};
    $scope.activeTab = {};
    $scope.currentDomain = {};
    $scope.currentDirectory = {};
    $scope.currenTabData = {};
    $scope.loading = false;
    LocationService.getDirectories().success(function (data) {
      $scope.directories = data;
      $scope.directories.unshift({
        name: "ALL",
        domain: "transparent-favicon.info",
        title: "All Directories"
      })
      $scope.currentDirectory = data[0];
      $scope.getStrategyTab();
    });
    AdminService.getDomains($scope.tenantName, $scope.params).success(function (response) {
      $scope.domains = response.rows;
      $scope.currentDomain = $scope.domains[0];
      $scope.getStrategyTab();
    }).error(function (error) {
      console.log(error);
    });
    AdminService.getStrategiesDefinition($rootScope.tenantName).success(function (data) {
      var index = 0;
      var len = data.length;
      var id;
      $scope.strategies = data;
      $scope.activeSubTab = [];
      while (index < len) {
        id = data[index].id;
        $scope.currentTab[id] = {"index": 0, childs: []};
        $scope.currentTab.root.childs[index++] = id;
      }
      $scope.activeTab = $scope.currentTab.root.childs[0];
      $scope.getStrategyTab();
    }).error(function (error) {
      console.log(error);
      $scope.getError = error;
      $scope.loading = false;
    });
    $scope._showDatePickers = function () {
      $('.selectpicker').selectpicker({
        showTick: true,
        width: '70%',
        showSubtext: true,
        size: 6
      });
      $('.selectpicker').on('loaded.bs.select', function (e) {
        var options = JSON.parse(e.target.getAttribute('ui-sub-types')).options;
        var selectedOptions = [];
        var optionsDOMElments = e.target.parentNode.childNodes[1].childNodes[0].childNodes;
        var index = 0;
        var currentDOMElement;
        options.forEach(function (option) {
          currentDOMElement = optionsDOMElments[index++];
          if (option.selected) {
            selectedOptions.push(option.id);
          }
          currentDOMElement.setAttribute('tooltip-placement', 'right');
          currentDOMElement.setAttribute('uib-tooltip', option.description);
        });
        $(e.target).selectpicker('val', selectedOptions);
        $compile(optionsDOMElments)($scope);
      });
    }
    $scope._parseError = function (error) {
      console.log(error);
      if (error.search(/\sHTTP\s\d{3}\s/) != -1) {
        $scope.getError = '<h1 class="strategy-unexpected-error">Unexpected server error</h1>';
      } else {
        $scope.getError = error;
      }
      $scope.loading = false;
      $scope.loading = false;
    };
    $scope._getStrategyTab = function () {
      var currentActiveTab = $scope.strategy ? $scope.strategy.typeStrategies[$scope.currentIndex] : false;
      if ($rootScope.tenantName && $scope.currentDomain.domain && $scope.activeTab && $scope.currentDirectory.name && currentActiveTab) {
        if (currentActiveTab.id && (!currentActiveTab.types || !currentActiveTab.types.length)) {//common strategy
          AdminService.getStrategyTab($rootScope.tenantName, $scope.currentDomain, currentActiveTab.id, $scope.currentDirectory.name).success(function (data) {
            $scope.currenTabData = data;
            /*$scope.gridHeaders = [$scope.strategy.typeStrategies[$scope.currentIndex].title];
             $scope.currenTabData.selectedStrategies.forEach(function(selectedStrategy) {
             debugger;
             selectedStrategy.strategyTitles;
             });*/
            $scope.loading = false;
          }).error(function (error) {
            $scope._parseError(error);
          });
        } else {//sub strategy
          AdminService.getStrategyTab($rootScope.tenantName, $scope.currentDomain, currentActiveTab.id, $scope.currentDirectory.name).success(function (data) {
            var subStrategies = {};
            var currentActiveTab = $scope.strategy ? $scope.strategy.typeStrategies[$scope.currentIndex] : false;
            var order = 0;
            currentActiveTab.types.forEach(function (type) {
              subStrategies[type.id] = type;
              subStrategies[type.id].order = order++;
            });
            for (var availableStrategy in data.availableStrategies) {
              if (subStrategies[availableStrategy])
                subStrategies[availableStrategy].options = data.availableStrategies[availableStrategy];
            }
            $scope.currenTabData = subStrategies;
            $scope.loading = false;
            setTimeout($scope._showDatePickers, 25);
            $scope.grid = data.selectedStrategies;
          }).error(function (error) {
            $scope._parseError(error);
          });
        }
      }
    };
    $scope.getStrategyTab = function () {
      $scope.loading = true;
      $scope.error = false;
      $scope.getError = false;
      setTimeout($scope._getStrategyTab, 25);
    };
    $scope.onTabClick = function (index) {
      $scope.currentTab.root.index = index;
      $scope.activeTab = $scope.currentTab.root.childs[index];
      $scope.getStrategyTab();
    };
    $scope.onSubTabClick = function (index) {
      $scope.currentTab[$scope.activeTab].index = index;
      $scope.getStrategyTab();
    };
    $scope.onDomainSelected = function (domain) {
      $scope.currentDomain = domain;
      $scope.getStrategyTab();
    };
    $scope.onDirectorySelected = function (directory) {
      $scope.currentDirectory = directory;
      $scope.getStrategyTab();
    };
    $scope.onModifySelected = function () {
      var strategies = [];
      var body;
      var type = $scope.strategy.typeStrategies[$scope.currentIndex].id;
      var radios = document.getElementsByName(type);
      var combos = document.getElementsByTagName('select');
      var combo;
      var index = 0;
      var subIndex = 0;
      var option;
      var subStrategyId;
      $scope.modifyError = false;
      if (radios.length) {
        radios.forEach(function (radio) {
          if (radio.checked) {
            strategies.push({"id": radio.value, "subtype": ""});
          }
        });
      }
      if (combos.length) {
        while (index < combos.length) {
          combo = combos.item(index++);
          subIndex = 0;
          subStrategyId = JSON.parse(combo.getAttribute('ui-sub-types')).id;
          while (subIndex < combo.selectedOptions.length) {
            option = combo.selectedOptions.item(subIndex++);
            strategies.push({"id": option.value, "subtype": subStrategyId});
          }
        }
        ;
      }
      body = {
        "type": type,
        "directory": $scope.currentDirectory.name,
        "withSubtypes": $scope.currenTabData.availableStrategies ? false : true,
        "strategies": strategies
      }
      AdminService.setStrategy(body, $rootScope.tenantName, $scope.currentDomain).then(function (data1, data2, data3) {
        $scope.getStrategyTab();
      }, function (data) {
        $scope.modifyError = data.data;
      });
    }
    $scope.onResetSelected = function() {
      var type = $scope.strategy.typeStrategies[$scope.currentIndex].id;
      AdminService.resetStrategy(type, $rootScope.tenantName, $scope.currentDomain)
        .then(function(response) {
          Notification.success('Strategy was reset')
          $scope.getStrategyTab();
        });
    }
  }]);

angular
  .module('app.admin')
  .controller('DeleteDomainsModal', ['$scope', '$state', '$uibModalInstance', 'AdminService', 'Notification', 'tenant', 'domains', function ($scope, $state, $uibModalInstance, AdminService, Notification, tenant, domains) {
    $scope.domains = domains;

    $scope.delete = function (domains) {
      var deleteDomains = [];
      domains.forEach(function (domain) {
        deleteDomains.push(domain.uid);
      });

      AdminService.deleteDomain(tenant, deleteDomains).success(function (response) {
        $uibModalInstance.close();
        $state.reload();
        Notification.success('Domains successfully deleted.');
      });
    }
  }]);

angular
  .module('app.admin')
  .controller('EditDomainModal', ['$scope', '$rootScope', '$uibModalInstance', 'AdminService', 'Notification', 'tenant', 'domain', 'mode', '$state', function ($scope, $rootScope, $uibModalInstance, AdminService, Notification, tenant, domain, mode, $state) {
    var ctrl = this;

    $scope.domain = domain;
    $scope.createBrandedKeyword = false;
    $scope.mode = mode;
    $scope.addAnother = false;
    if ($scope.mode == 'add')
      $scope.domain.strategies = AdminService.getDefaultStrategies();

    var orig = angular.copy($scope.domain);
    $scope.reset = function (scope) {
      angular.copy(orig, scope);
    };

    AdminService.getDomainRadii(tenant, $scope.domain.did).then(function(response) {
      if (response.data.ok && response.data.response && Array.isArray(response.data.response)) {
        $scope.radius1 = response.data.response[0];
        if (response.data.response.length == 2) {
          $scope.radius2 = response.data.response[1];
        } else {
          $scope.radius2 = null;
        }
      } else {
        Notification.error("Unable to get domain radii!");
      }
    });

    $scope.customSpecialtyReportingOptions = ['Citations', 'Ranks'];

    $scope.addCustomSpecialtyReporting = function () {
      if (!$scope.domain.customSpecialtyReporting.filter(function (d) {
          return d.spid == "";
        }).length) {
        $scope.domain.customSpecialtyReporting.push({spid: "", options:[]});
      }
    };

    $scope.removeCustomSpecialtyReporting = function (i) {
      $scope.domain.customSpecialtyReporting = $scope.domain.customSpecialtyReporting.filter(function (d, j) {
        return i !== j;
      });
    };

    $scope.toggleCustomSpecialtyReportingOpt = function(specialty, opt) {
      var idx = specialty.options.indexOf(opt);

      // Is currently selected
      if (idx > -1) {
        specialty.options.splice(idx, 1);
      }

      // Is newly selected
      else {
        specialty.options.push(opt);
      }
    };

    $scope.updateDomain = function (isValid, domain, override) {
      if (isValid) {
        var strategies = AdminService.getStrategies(domain);

        if (domain.locationSegmentation) {
          if (domain.locationSegmentation.attribute == "") {
            domain.locationSegmentation.attribute = null;
          }
        }

        var domain = {
          uid: domain.uid,
          domain: domain.domain,
          did: domain.did,
          title: domain.title,
          type: "CLIENT",
          primary: domain.primary,
          tokens: domain.tokens,
          negativeTokens: domain.negativeTokens,
          googleAnalyticsAccount: domain.googleAnalyticsAccount,
          specialties: domain.specialties,
          status: domain.status,
          hasState: domain.hasState,
          strategies: strategies,
          customSpecialtyReporting: domain.customSpecialtyReporting,
        };

        domain.stopWords = _.map($scope.stopWords, 'value');
        domain.strategies = strategies;

        if ($scope.mode == 'edit') {
          AdminService.updateDomain(tenant, domain, domain.primary, $scope.createBrandedKeyword, [$scope.radius1, ($scope.radius2 ? $scope.radius2 : ($scope.radius1 + 1))])
            .then(function (response) {
                if ($scope.addAnother) {
                  $scope.domain = {};
                } else {
                  $uibModalInstance.close();
                  $state.reload();
                }
                Notification.success('Domain successfully updated.');
              },
              function (response) {
                var msg = response.data.messages && response.data.messages.length ? response.data.messages.shift() : 'Error saving the domain.'
                Notification.error(msg);
              });
        }
        else {
          AdminService.addDomain(tenant, domain, domain.primary, $scope.createBrandedKeyword)
            .then(function (response) {
                if ($scope.addAnother) {
                  $scope.domain = {};
                } else {
                  $uibModalInstance.close();
                  $state.reload();
                }
                Notification.success('Domain successfully created.');
              },
              function (response) {
                var msg = response.data.messages && response.data.messages.length ? response.data.messages.shift() : 'Error saving the domain.'
                Notification.error(msg);
              });
        }

      }
    };

    $scope.statuses = ["PRESALES", "BASELINE", "DISABLED"];
    $scope.hasStateOptions = ["No", "Yes"];

    $scope.stopWords = domain.stopWords.map(function (d) {
      return {
        value: d,
      }
    });
    
    if ($scope.stopWords.length < 5) {
      for (var i = $scope.stopWords.length; i < 5; i++) {
        $scope.stopWords.push({value: ""});
      }
    }

    $scope.addStopWord = function () {
      if (!$scope.stopWords.filter(function (d) {
          return d.value == "";
        }).length) {
        $scope.stopWords.push({value: ""});
      }
    };

    $scope.removeStopWord = function (i) {
      $scope.stopWords = $scope.stopWords.filter(function (d, j) {
        return i !== j;
      });
    };
  }]);

angular
  .module('app.admin')
  .controller('AdminSettingsDirectoriesController', ['$uibModal', '$rootScope', '$scope', '$state', '$stateParams', 'AdminService', 'LocationService', 'localStorageService', 'Notification', function ($uibModal,
                                                              $rootScope,
                                                              $scope,
                                                              $state,
                                                              $stateParams,
                                                              AdminService,
                                                              LocationService,
                                                              localStorageService,
                                                              Notification) {
    $rootScope.params = {
      size: localStorageService.get('pagination-size') || 10,
      from: 0,
      currentPage: 0
    };
    $scope.domains = [];
    $scope.rls_status = {
      title: 'No Filter',
      value: false
    }
    $scope.filter = {
      dirName: '',
      domainName: '',
      rsa: 'none',
      local: 'none',
      dirStatus: 'both'
    }

    loadData();
    function loadData() {
      $scope.loading = true;

      LocationService.getDirectoriesRLS_status().success(function (data) {
        $scope.dirCheckboxes = data;
        if ($scope.items) {
          $scope.mergeDirectoryDataWithCheckboxData();
        }
      });

      LocationService.getDirectories($scope.tenantName, $rootScope.params)
        .then(function (response) {
          $scope.items = response.data.rows || response.data;
          $scope.totalCount = response.data.totalCount || response.data.length;
          if ($scope.dirCheckboxes) {
            $scope.mergeDirectoryDataWithCheckboxData();
          }
          $('.selectpicker').on('loaded.bs.select', function (e) {
            var classes = e.target.getAttribute("class");
            var customClasses = classes.substring(13, classes.indexOf(" ng-pristine")).split(" ");
            var combo = e.target.parentNode;
            var index = 0;
            var customClass;
            while (customClass = customClasses[index++]) {
              combo.classList.add(customClass);
            }
          });
          $('.selectpicker').selectpicker({
            showTick: true,
            showSubtext: true
          });
          $scope.loading = false;
        });
    }

    $scope.go = function () {
      $rootScope.params.from = $rootScope.params.size * ($rootScope.params.currentPage - 1);
      loadData();
    }

    $scope.updateData = function () {
      loadData();
    }

    $scope.updateDirectory = function (directory) {
      AdminService.updateDirectory($scope.tenantName, directory).then(function (response) {
        $scope.saving = false;
        $scope.changed = false;
        $scope.saved = true;
        Notification.success('Directory successfully updated.');
      }, function (error, resp) {
        $scope.saving = false;
        Notification.error('There was a problem updating directory [' + directory.name + '].');
      });
    };

    $scope.mergeDirectoryDataWithCheckboxData = function () {
      var checkBoxes = {};
      var checkBoxData;
      var dirs = $scope.items;
      $scope.dirCheckboxes.forEach(function (checkBox) {
        checkBoxes[checkBox.name] = checkBox;
      });
      dirs.forEach(function (directory) {
        checkBoxData = checkBoxes[directory.name];
        if (checkBoxData) {
          directory.enabledRank = checkBoxData.enabledRank;
          directory.enabledCitation = checkBoxData.enabledCitation;
        }
      });
      $scope.items = dirs;
    }

    $scope.onDirectorySelected = function (directory) {
      $scope.currentDirectory = directory;
    };

    $scope.onRlsStatusSelected = function (name, rvalue) {
      $scope.rls_status = {
        title: name,
        value: rvalue
      }
    };
    $scope.onRankChecked = function (item, $event) {
      item.enabledRank = $event.currentTarget.checked;
      LocationService.updateDirectoriesRLS_status(item).then(function successCallback(response) {
        Notification.success('Directory successfully updated.');
      }, function errorCallback(response) {
        Notification.error('Error ' + response.status + ": " + response.statusText);
        console.error(response);
      });
    }
    $scope.onCitationChecked = function (item, $event) {
      item.enabledCitation = $event.currentTarget.checked;
      LocationService.updateDirectoriesRLS_status(item).then(function successCallback(response) {
        Notification.success('Directory successfully updated.');
      }, function errorCallback(response) {
        Notification.error('Error ' + response.status + ": " + response.statusText);
        console.error(response);
      });
    }
  }]);

angular
  .module('app.admin')
  .controller('AdminSettingsLocalKeywordsController', ['$uibModal', '$rootScope', '$scope', '$state', '$stateParams', 'AdminService', 'Notification', 'ParamsService', function ($uibModal,
                                                                $rootScope,
                                                                $scope,
                                                                $state,
                                                                $stateParams,
                                                                AdminService,
                                                                Notification,
                                                                ParamsService) {
    $rootScope.params = ParamsService.params;
    $scope.localKeywords = [];

    loadData();
    function loadData() {
      $scope.loading = true;

      AdminService.getLocalKeywords($rootScope.tenantName).then(function (response) {
        $scope.localKeywords = response.data;
        $scope.loading = false;
      }, function (response) {
        Notification.error("Could not load keywords");
        $scope.loading = false;
      });
    }

    ParamsService.subscribe($scope, loadData);

    $scope.loadData = loadData;

    $scope.disable = function (Keyword, disabled) {
      $scope.loading = true;
      Keyword.disabled = disabled;
      Keyword.statusChanged = getDateFormatted(new Date());

      if (Keyword.name != '') {
        var keyword = {
          name: Keyword.name,
          location: true,
          specialties: Keyword.specialties,
          disabled: Keyword.disabled,
          statusChanged: Keyword.statusChanged,
          dateCreated: Keyword.dateCreated,
          branded: Keyword.branded,
        };

        AdminService.editLocalKeyword($rootScope.tenantName, keyword)
        .then(function (response) {
            $state.reload();
            Notification.success('Keyword successfully edited.');
          },
          function (response) {
            var msg = response.data.messages && response.data.messages.length ? response.data.messages.shift() : 'Error saving keyword.';
            Notification.error(msg);
          });
      }
    }

    $scope.delete = function (Keyword) {
      $scope.loading = true;
      AdminService.deleteLocalKeyword($rootScope.tenantName, Keyword).then(function (response) {
        Notification.success("Keyword deleted");
        $state.reload();
      }, function (response) {
        Notification.error("Could not delete keyword");
        $scope.loading = false;
      });
    };

    $scope.add = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.localkeywords.edit.html',
        controller: 'EditLocalKeywordModal',
        resolve: {
          keyword: function () {
            var d = new Object();
            return d;
          },
          tenant: function () {
            return $scope.tenantName;
          },
          mode: function () {
            return "add";
          }
        }
      });
    };

    $scope.edit = function(keyword) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.localkeywords.edit.html',
        controller: 'EditLocalKeywordModal',
        resolve: {
          keyword: function () {
            return keyword;
          },
          tenant: function () {
            return $scope.tenantName;
          },
          mode: function () {
            if (keyword.name == '') {
              return "add";
            }
            return "edit";
          }
        }
      });
    };

  }]);

angular
  .module('app.admin')
  .controller('EditLocalKeywordModal', ['$scope', '$rootScope', '$uibModalInstance', 'AdminService', 'Notification', 'DataDateService', 'Tenant', 'tenant', 'keyword', 'mode', '$state', function ($scope,
                                                 $rootScope,
                                                 $uibModalInstance,
                                                 AdminService,
                                                 Notification,
                                                 DataDateService,
                                                 Tenant,
                                                 tenant,
                                                 keyword,
                                                 mode,
                                                 $state) {
    var ctrl = this;

    $scope.keyword = keyword;
    $scope.mode = mode;

    var orig = angular.copy($scope.keyword);
    $scope.reset = function (scope) {
      angular.copy(orig, scope);
    };

    $scope.update = function (isValid, keyword) {
      if (isValid) {
        var keyword = {
          name: keyword.name,
          location: true,
          specialties: keyword.specialties,
          branded: keyword.branded,
        };


        if ($scope.mode == 'edit') {
          AdminService.editLocalKeyword($rootScope.tenantName, keyword)
          .then(function (response) {
              $uibModalInstance.close();
              $state.reload();
              Notification.success('Keyword successfully edited.');
            },
            function (response) {
              var msg = response.data.messages && response.data.messages.length ? response.data.messages.shift() : 'Error saving keyword.';
              Notification.error(msg);
            });
        } else {
          AdminService.addLocalKeyword($rootScope.tenantName, keyword)
          .then(function (response) {
              $uibModalInstance.close();
              $state.reload();
              Notification.success('Keyword successfully added.');
            },
            function (response) {
              var msg = response.data.messages && response.data.messages.length ? response.data.messages.shift() : 'Error saving keyword.';
              Notification.error(msg);
            });
        }
      }
    };
  }]);

angular
  .module('app.admin')
  .controller('AdminSettingsLocationsController', ['$uibModal', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'AdminService', 'Notification', 'FileSaver', 'localStorageService', 'ParamsService', 'appConfig', function ($uibModal,
                                                            $rootScope,
                                                            $scope,
                                                            $state,
                                                            $stateParams,
                                                            $timeout,
                                                            AdminService,
                                                            Notification,
                                                            FileSaver,
                                                            localStorageService,
                                                            ParamsService,
                                                            appConfig) {
    $rootScope.params = ParamsService.params;
    $scope.locations = [];
    $scope.loading = true;

    AdminService.getDomains($scope.tenantName, $scope.params).success(function (response) {
      $scope.domains = response.rows;
      $scope.currentDomain = $scope.domains[0];
      if (!$scope.currentDomain) {
        $scope.currentDomain = {};
      }
      $scope.loading = false;
    }).error(function (error) {
      console.log(error);
      $scope.loading = false;
    });

    $scope.search = function ($event) {
      if ($event.charCode === 13) {
        ParamsService.set('page', 0);
        loadData();
      }
    }

    loadData();
    function loadData() {
      $scope.loading = true;
      if (!$scope.currentDomain) {
        setTimeout(loadData, 5);
      } else {
        AdminService.getLocations($scope.tenantName, "", $scope.currentDomain.domain).success(function (response) {
          $scope.locations = response.rows || response;
          $rootScope.params.size = $scope.locations.length || 10;
          $scope.totalCount = response.totalCount || response.length;
          $rootScope.totalCount = $scope.totalCount;
          $scope.loading = false;
        }).error(function (error) {
          console.log(error)
        });
      }
    }

    ParamsService.subscribe($scope, loadData);

    $scope.edit = function (location) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.locations.edit.html',
        controller: 'EditLocationModal',
        resolve: {
          location: function () {
            return location;
          },
          tenant: function () {
            return $scope.tenantName;
          }
        }
      });
    };

    $scope.importMySQL = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.locations.import.html',
        controller: 'ImportLocationModal',
        resolve: {
          tenant: function () {
            return $scope.tenantName;
          }
        }
      });
    };

    $scope.importSettings = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/admin/views/admin.tenants.settings.locations.settings.html',
        controller: 'ImportSettingsModal',
        size: 'lg',
        resolve: {
          tenant: function () {
            return $scope.tenantName;
          }
        }
      });
    };
  }]);

angular
  .module('app.admin')
  .controller('ImportSettingsModal', ['$scope', '$state', '$uibModalInstance', '$timeout', 'AdminService', 'Notification', 'tenant', '$http', function ($scope, $state, $uibModalInstance, $timeout, AdminService, Notification, tenant, $http) {
    $scope.profileFieldNames = {};

    AdminService.getLocationFieldSourceMappings(tenant)
      .then(function (response) {
        $scope.mappings = response.data.mappings;
        $scope.sources = response.data.sources;

        for (var i in $scope.mappings) {
          var mapping = $scope.mappings[i];
          var o = _.find(mapping.mapping.options, function(o) { return o.key === 'PROFILE_FIELD_NAME'; });
          if (o) {
            $scope.profileFieldNames[mapping.field] = o.value;
          }
        }
      });

    $scope.saveMappings = function() {
      var mappings = _.values($scope.mappings);

      for (var i in mappings) {
        var mapping = mappings[i];
        if (typeof $scope.profileFieldNames[mapping.field] !== 'undefined') {
          mapping.mapping.options = [{key: 'PROFILE_FIELD_NAME', value: $scope.profileFieldNames[mapping.field]}];
        }
      }

      AdminService.saveLocationFieldSourceMappings(tenant, mappings)
        .success(function (response) {
          Notification.success(response.messages.join("<br>"));
          $uibModalInstance.close();
        })
        .error(function (response) {
          Notification.error(response.messages.join("<br>"));
        });
    };

    // $scope.importLocations = function () {
    //
    //   Notification.success('Import process has started.');
    //   $uibModalInstance.close();
    //
    //   AdminService.importLocations(tenant, $scope.domainId)
    //     .then(function (response) {
    //       Notification.success('Locations have been imported successfully.');
    //     }, function (response, error) {
    //       Notification.error('There was an error importing Locations.');
    //     });
    // };
  }]);

angular
  .module('app.admin')
  .controller('ImportLocationModal', ['$scope', '$state', '$uibModalInstance', '$timeout', 'AdminService', 'Notification', 'tenant', 'currentUser', '$http', function ($scope, $state, $uibModalInstance, $timeout, AdminService, Notification, tenant, currentUser, $http) {
    $scope.domainId = currentUser.tenant.rlsDomainId;

    $scope.importLocations = function () {

      Notification.success('Import process has started.');
      $uibModalInstance.close();

      AdminService.importLocations(tenant, $scope.domainId)
        .then(function (response) {
          $timeout(function () {
            $uibModalInstance.close();
            $state.reload();
            Notification.success('Locations have been imported successfully.');
          }, 1000);
        }, function (response, error) {
          Notification.error('There was an error importing Locations.');
        });
    };
  }]);

angular
  .module('app.admin')
  .controller('DeleteLocationsModal', ['$scope', '$state', '$uibModalInstance', '$timeout', 'AdminService', 'Notification', 'tenant', 'locations', function ($scope, $state, $uibModalInstance, $timeout, AdminService, Notification, tenant, locations) {
    $scope.locations = locations;
    var locationFids = [];
    $scope.delete = function (locations) {
      locations.forEach(function (location) {
        locationFids.push(location.fid);
      });
      AdminService.deleteLocation(tenant, locationFids).success(function (response) {
        $timeout(function () {
          $uibModalInstance.close();
          $state.reload();
          Notification.success('Location successfully deleted.');
        }, 1000);
      });
    }
  }]);

angular
  .module('app.admin')
  .controller('AddLocationModal', ['$scope', '$uibModalInstance', '$state', '$timeout', 'AdminService', 'Notification', 'tenant', function ($scope, $uibModalInstance, $state, $timeout, AdminService, Notification, tenant) {
    $scope.addLocation = function (isValid, location) {
      if (isValid) {
        AdminService.createLocation(tenant, location).success(function (response) {
          $timeout(function () {
            $uibModalInstance.close();
            $state.reload();
            Notification.success('Location successfully added.');
          }, 1000);
        });
      }
    }
  }]);

angular
  .module('app.admin')
  .controller('EditLocationModal', ['$scope', '$uibModalInstance', 'AdminService', 'Notification', 'tenant', 'location', function ($scope, $uibModalInstance, AdminService, Notification, tenant, location) {
    $scope.location = location;
    $scope.URLs = location.locationURLs.map(function (d) {
      return {
        value: d,
      }
    });
  }]);

angular
  .module('app.admin')
  .controller('GoogleOAuthController', ['$scope', '$location', 'AdminService', function ($scope, $location, AdminService) {

    $scope.initAuthentication = function () {
      AdminService.initGoogleAuthentication().success(function (result) {
        window.location.href = result.redirectUrl;
      });
    };

    $scope.finishAuthentication = function () {
      var oauthRequest = {code: '', error: ''};
      var oauthCode = $location.search().code;
      if (oauthCode) {
        oauthRequest.code = oauthCode;
      }
      var error = $location.search().error;
      if (oauthCode) {
        oauthRequest.error = error;
      }

      AdminService.finishGoogleAuthentication(oauthRequest).success(function (result) {
        $scope.callbackMessage = result.message;
      });
    };
  }]);

  function getDateFormatted(date) {
    return moment(date).format('YYYYMMDD');
  }
(function() {
  'use strict';

  strategyTypeController.$inject = ['$scope'];
  angular
    .module('app.admin')
    .directive('strategyType', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        strategies: '<',
        tabs: '=',
        tabId: '<'
      },
      controller: strategyTypeController,
      controllerAs: 'ctrl',
      link: function($scope) {
        $scope.$watch("currentTab", function(newValue, oldValue){
          $scope.tabs = newValue;
        });
        $scope.$watch("activeTab", function(newValue, oldValue){
          $scope.tabId = newValue;
        });
        $scope.$watch("strategy", function(newValue, oldValue){
          var tab = $scope.tabs[$scope.tabId];
          var index = 0;
          var len = newValue ? newValue.typeStrategies.length : 0;
          var id;
          function getChildIds(strategy, subChild) {
            var childs = strategy.typeStrategies ? strategy.typeStrategies : strategy.types;
            var index = 0;
            var len = childs.length;
            var id;
            var res = subChild ? subChild : [];
            while(index < len) {
              if(childs[index].id) {
                id = childs[index].id;
                res.push(id);
              }else {
                res = getChildIds(childs[index], res);
                index++;
                continue;
              }
              index++;
              $scope.ctrl.tabs[id] = {"index":0, childs:[]};
            }
            return res;
          };
          $scope.typeStrategy = newValue;
          if(oldValue && oldValue.secondTime) {
            if(oldValue != newValue) {
              oldValue.secondTime = false;
              oldValue.otherStrategy = false;
            }
            else
              $scope.currentActiveTab = {};
          }else if(newValue) {
            newValue.secondTime = true;
          }
          if(newValue && newValue.otherStrategy && $scope.currentActiveTab) {
            $scope.currentActiveTab = $scope.currentActiveTab.types ? $scope.currentActiveTab.types[tab.index] : {};
            if(newValue.otherStrategy == 1) {
              $scope.subTitle = oldValue.title;
              newValue.otherStrategy = $scope.currentActiveTab.types && $scope.currentActiveTab.types.length ? 1 : 2;
            }
          }
          else if(tab) {//CONDITION 1: getStrategiesDefinition response return data
            if(!tab.childs.length) {//CONDITION 2: it's the first time condition 1 has been met;
              tab.childs = getChildIds(newValue);
              $scope.tabs[$scope.tabId] = tab;
            }
            $scope.currentIndex = tab.index;
            $scope.childs = tab.childs;
            $scope.currentActiveTab = newValue.typeStrategies[tab.index];
            if($scope.currentActiveTab.types && $scope.currentActiveTab.types.length && newValue) {
              newValue.otherStrategy = 1;
            }
          }
        });
      },
      templateUrl: function($element, $attrs) {
        return 'modules/admin/views/strategy.type.html';
      }
    };

    return directive;
  }

  /* @ngInject */
  function strategyTypeController(
    $scope
  ) {
    var ctrl = this;
    $scope.onSubTabClick = function(index) {
      ctrl.tabs[ctrl.tabId].index = index;
      $scope.currentIndex = index;
      $scope.currentActiveTab = ctrl.strategies.typeStrategies[index];
    }
  }
})();

'use strict';

angular
  .module('app.admin')
  .factory('AdminService', ['$http', '$q', '$rootScope', 'localStorageService', 'LocationService', 'ParamsService', 'appConfig', 'DirectoriesService', function(
    $http,
    $q,
    $rootScope,
    localStorageService,
    LocationService,
    ParamsService,
    appConfig,
    DirectoriesService
  ) {

    $http.defaults.headers.delete = { 'Content-Type': 'application/json;charset=utf-8' };

    function extractParams(params) {
      var size = params.size || ParamsService.get('size') || 10;
      var filters = {
        size: +size,
        from: params.from * +size || ParamsService.get('page') * +size || 0,
        searchText: params.searchText || ParamsService.get('searchText') || '',
      };

      return filters
    }
    var defaultStrategies = {
      'citationSearchStrategy':'defaultStrategy',
      'locationBuilderStrategy':'none',
      'businessNameMatcherStrategy':'defaultStrategy',
      'addressMatcherStrategy':'none',
      'retryStrategy':'none',
      'locationMySQLMigratorStrategy': 'none'
    }

    return {

      getTypes: function(tenantName) {
        return $http.get(appConfig.API_URL + '/user/types.json');
      },

      getTenant: function(tenantName) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'.json');
      },

      updateTenant: function(tenantName, data) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/update-index.json', data);
      },

      updateTenantConfig: function(tenantName, config) {
        var data = { uiConfig: config };
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/ui-config.json', data);
      },

      // USERS
      getUsers: function(tenantName, params) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/user/list.json', {
          params: extractParams(params)
        });
      },

      getAllUsers: function(tenantName, params) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/user/list.json', {
          params: params || { from: 0, size: 9999 }
        }, { cache: true});
      },

      getUser: function(tenantName, userId) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/user/'+userId+'.json');
      },

      addUser: function(tenantName, user) {
        return $http.put(appConfig.API_URL + '/tenant-admin/'+ tenantName +'/user/create.json', user);
      },

      updateUser: function(tenantName, user) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+ tenantName +'/user/edit.json', user);
      },

      deleteUser: function(tenantName, user) {
        return $http.delete(appConfig.API_URL + '/tenant-admin/'+ tenantName +'/user/'+user.userId+'.json');
      },

      deleteUsers: function(users) {
        console.log('Bulk action not implemented.');
      },

      importUsersTemplate: function(tenantName) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/download-users-template.csv', {
        });
      },

      // Data file
      uploadUsersData: function(tenantName, file) {
        var fd = new FormData();
        fd.append('file', new Blob([file], { type: 'text/csv' }));
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/upload-users.csv?tenant='+tenantName, fd, {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }) ;
      },

      // ROLES

      getRoles: function(tenantName) {
        return $http.get(appConfig.API_URL + '/user/roles.json', { cache: true });
      },

      // BRANDS
      getBrands: function(tenant, params) {
        return $http.post(appConfig.API_URL + '/tenant-admin/' + tenant + '/keyword-brands/get-keyword-brands.json', {
          searchText: params.searchText,
          exactMatch: false,
          from: params.page * (params.size || 10),
          size: params.size || 10,
          domain: params.domain,
        });
      },

      getBrandsCSV: function(tenant, params) {
        return $http.post(appConfig.API_URL + '/tenant-admin/' + tenant + '/keyword-brands/get-keyword-brands.csv', {
          searchText: '',
          exactMatch: false,
          from: 0,
          size: 9999,
        });
      },

      saveBrand: function(tenant, params) {
        return $http.post(appConfig.API_URL + '/tenant-admin/' + tenant + '/keyword-brands/update.json', {
          name: params.name,
          domain: params.domain,
          segment: params.segment,
          uid: params.uid,
        });
      },

      saveMultipleBrands: function(tenant, brands, params) {
        var data = {
          keywordBrands: brands,
          domain: params.domain
        };
        return $http.post(appConfig.API_URL + '/tenant-admin/' + tenant + '/keyword-brands/update-multiple.json', data);
      },

      deleteBrand: function(tenant, data) {
        return $http.post(appConfig.API_URL + '/tenant-admin/' + tenant + '/keyword-brands/delete.json', data);
      },

      // REPORTS
      searchReports: function(tenantName, params) {
        return $http.post(appConfig.API_URL + '/export/search.json', extractParams(params));
      },

      downloadReport: function (tenantName, id) {
        return $http.post(appConfig.API_URL + '/export/' + id + '/download.csv', {})
      },

      updateReport: function (tenantName, report) {
        return $http.post(appConfig.API_URL + '/export/update.json', report)
      } ,

      deleteReports: function(tenantName, reports) {
        return $http.post(appConfig.API_URL + '/export/delete.json', reports);
      },

      //Reports
      // DOMAINS
      getDomains: function(tenantName, params) {
        if (isNaN(params.size)) {
          delete params.size;
        }

        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/domain/list.json', {
          params: extractParams(params)
        });
      },

      getDomain: function(tenantName, domain) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/domain/'+domain+'.json');
      },

      getDomainRadii: function(tenantName, did) {
        return $http.get(appConfig.API_URL + '/tenant-admin/' +tenantName + '/domain/' + did + '/radii.json')
      },

      addDomain: function(tenantName, domain, override, createBrandedKeyword) {
        return $http.put(appConfig.API_URL + '/tenant-admin/'+tenantName+'/domain/add.json', {
          domain: domain,
          override: override || false,
          createBrandedKeyword: createBrandedKeyword
        });
      },

      createDomain: function(tenantName, domain, override) {
        return $http.put(appConfig.API_URL + '/tenant-admin/'+tenantName+'/domain.json', {
          domain: domain,
          override: override || false
        });
      },

      updateDomain: function(tenantName, domain, override, createBrandedKeyword, radii) {
        // if the domain has specialties, break it into a list of ints
        if (typeof(domain.specialties) === 'string') {
          domain.specialties = _.map(domain.specialties.split(","), function(e) { return parseInt(e); });
        } else {
          domain.specialties = [];
        }

        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/domain/update.json', {
          domain: domain,
          override: override || false,
          createBrandedKeyword: createBrandedKeyword,
          radii: radii,
        });
      },

      deleteDomain: function(tenantName, domains) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/domain/delete.json', domains);
      },

      // STRATEGIES
      getStrategiesDefinition: function(tenantName) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/strategy/definitions.json');
      },

      getStrategyTab: function(tenantName, domain, type_id, directory) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/strategy/'+domain.did+"/"+type_id+"/"+directory+"/strategies.json");
      },

      setStrategy: function(body, tenantName, domain) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/strategy/'+domain.did+'/update.json',body,{ transformResponse: function(d, h) {return d} });
      },

      resetStrategy: function(strategyType, tenantName, domain) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/strategy/'+domain.did+'/' + strategyType + '/reset.json');
      },

      // LOCATIONS
      getLocations: function(tenantName, searchTerm, domain) {
        var parameters = extractParams({});
        parameters.searchTerm = searchTerm;
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/location/search.json', parameters);
      },

      uploadLocations: function(tenantName, file) {
        var fd = new FormData();
        fd.append('file', file);
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/upload-locations.csv?tenant='+tenantName, fd, {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }) ;
      },

      importLocations: function(tenantName, domainId) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/locations/importMySql.json', {
          domainId: domainId,
        });
      },

      importLocationsTemplate: function(tenantName) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/download-locations-template.csv', {
        });
      },

      createLocation: function(tenantName, location) {
        // It also works as update
        return $http.put(appConfig.API_URL + '/tenant-admin/'+tenantName+'/locations.json', {
          tenant: tenantName,
          location: location
        });
      },

      deleteLocation: function(tenantName, locations) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/locations/delete.json', locations);
      },

      // CATEGORIES
      getCategories: function(tenantName, params) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/categories.json', {
          params: params || { from: 0, size: 50 }
        });
      },

      createCategories: function(tenantName, category) {
        return $http.put(appConfig.API_URL + '/tenant-admin/'+tenantName+'/add-categories.json', {
          categories: category
        });
      },

      updateCategories: function(tenantName, category) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/categories/update.json', {
          categories: category
        });
      },

      deleteCategories: function(tenantName, categories) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/categories/delete.json', {
          categories: categories
        });
      },

      // Data file
      uploadKeywordsData: function(tenantName, file) {
        var fd = new FormData();
        fd.append('file', file);
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/upload-keywords.csv?tenant='+tenantName, fd, {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }) ;
      },

      importKeywordsTemplate: function(tenantName) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/download-local-keywords-template.csv', {
        });
      },

      // Permissions
      getProjectsPermissionsByProject: function(tenantName, project) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/'+project+'/user/list.json');
      },

      getProjectsPermissionsByUser: function(tenantName, user) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/user/'+user+'/projects.json');
      },

      grantProjectsUserPermissions: function(tenantName, permissions) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/project-user.json', permissions);
      },
      getDefaultStrategies: function() {
        return defaultStrategies;
      },
      getStrategies: function(domain) {
        var strategies = domain.strategies ? domain.strategies : {};
        if(domain.strategies) {
          for(var strategy in defaultStrategies) {
            if(domain.strategies[strategy])
              strategies[strategy] = domain.strategies[strategy];
            if(domain.strategies[strategy] == 'none') {
              delete domain.strategies[strategy];
            }
          }
        }
        if(strategies.locationMySQLMigratorStrategy && strategies.locationMySQLMigratorStrategy != 'none') {
          strategies.locationMySQLMigratorStrategy = [
            {"withNamePrefix":domain.strategies.withNamePrefix},
            {"withDefaultTelephone":domain.strategies.withDefaultTelephone},
            {"withNameSuffix":domain.strategies.withNameSuffix},
            {"withName":domain.strategies.withName}
          ];
        }
        return strategies;
      },
      setStrategies: function(domain) {
        var currentDomainStrategies = $rootScope.domain[domain.title].strategies;
        for(var strategy in defaultStrategies) {
          if(currentDomainStrategies[strategy]) {//if the backEnd has a value for this then set that value
            domain.strategies[strategy] = currentDomainStrategies[strategy];
          }else {//else set the default value
            domain.strategies[strategy] = defaultStrategies[strategy];
          }
        }
        var locationStrategy = domain.strategies.locationMySQLMigratorStrategy;
        if(locationStrategy && locationStrategy != "none") {
          locationStrategy.forEach(function(objectWithStrategies){
            for(strategy in objectWithStrategies) {
              domain.strategies[strategy] = objectWithStrategies[strategy];
            }
          });
          domain.strategies.locationMySQLMigratorStrategy = 'show';
        }
      },
      initGoogleAuthentication: function () {
        return $http.get(appConfig.API_URL + '/google-oauth/init-authorization.json');
      },

      finishGoogleAuthentication: function (oauthRequest) {
        return $http.post(appConfig.API_URL + '/google-oauth/finish-authorization.json', oauthRequest);
      },

      getLocalKeywords: function(tenantName) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/localKeywords.json');
      },

      addLocalKeyword: function(tenantName, keyword) {
        var body = {
          'name': keyword.name,
          'location': keyword.location,
          'branded': keyword.branded,
        }

        // if the keyword has specialties, break it into a list of ints
        if (typeof(keyword.specialties) === 'string') {
          body.specialties = _.map(keyword.specialties.split(","), function(e) { return parseInt(e); });
        }

        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/keyword.json',body);
      },

      editLocalKeyword: function(tenantName, keyword) {
        var body = {
          'name': keyword.name,
          'location': keyword.location,
          'dateCreated': keyword.dateCreated,
          'disabled': keyword.disabled,
          'statusChanged': keyword.statusChanged,
          'branded': keyword.branded
        }

        // if the keyword has specialties, break it into a list of ints
        if (typeof(keyword.specialties) === 'string') {
          body.specialties = _.map(keyword.specialties.split(","), function(e) { return parseInt(e); });
        }

        return $http.put(appConfig.API_URL + '/tenant-admin/'+tenantName+'/keyword.json',body);
      },

      deleteLocalKeyword: function(tenantName, keyword) {
        var body = {
          'name': keyword.name
        }
        return $http({
          url: appConfig.API_URL + '/tenant-admin/'+tenantName+'/keyword.json',
          method: 'DELETE',
          data: body,
          headers: {
            "Content-Type": "application/json;charset=utf-8"
          }
        });
      },

      getLocationFieldSourceMappings: function(tenantName) {
        return $http.get(appConfig.API_URL + '/tenant-admin/'+tenantName+'/location-field-source/mappings.json');
      },

      saveLocationFieldSourceMappings: function(tenantName, mappings) {
        return $http.post(appConfig.API_URL + '/tenant-admin/'+tenantName+'/location-field-source/mappings.json', mappings);
      }
    }
}]);

'use strict';

angular
  .module('Acl', [])
  .run(['$rootScope', 'AclService', 'localStorageService', 'currentUser', function (
    $rootScope,
    AclService,
    localStorageService,
    currentUser
  ) {
    if (!currentUser) {
      return false;
    }
    var tenant = currentUser.tenant.name;
    localStorageService.set('tenantGlobalKey', tenant);

    if (_.intersection(['ADMIN', 'SUPER_ADMIN'], currentUser.user.roles).length > 0) {
      $rootScope.isAdmin = true;
    }

    $rootScope.can = AclService.can;
  }]);

'use strict';

angular
  .module('Acl')
  .factory('AclService', ['currentUser', function(
    currentUser
  ) {
    var service = {};

    service.can = function (capabilities) {
      if (!currentUser) {
        return false;
      }

      if (_.isArray(capabilities)) {
        return _.intersection(currentUser.user.roles, capabilities).length > 0;
      }

      return currentUser.user.roles.indexOf(capabilities) !== -1
    }

    return service;
  }]);

(function() {
  'use strict';

  config.$inject = ['$stateProvider'];
  angular
    .module('Local', [
      'ui.router',
      'shared.filters'
    ])
    .config(config);

  function config ($stateProvider) {
    $stateProvider

      .state('reports', {
        url: '/reports',
        abstract: true,
        template: '<ui-view/>',
        controller: 'ReportingCtrl',
      })

      .state('reports.nap', {
        url: '/nap',
        templateUrl: 'modules/local/nap/location-nap.html',
        controller: 'LocationNapCtrl as ctrl',
      })

      .state('reports.social', {
        url: '/social',
        templateUrl: 'modules/local/social/location-social.html',
        controller: 'LocationSocialCtrl as ctrl',
      })
      .state('reports.export', {
        url: '/export',
        templateUrl: 'modules/local/export/exports.html',
        controller: 'ExportCtrl as ctrl',
      })
  }
})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$filter', 'LocationService', '$scope', '$rootScope', '$location'];
  angular
    .module('Local')
    .directive('directoriesSelector', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      restrict: 'E',
      scope: true,
      bindToController: {
        model: '=',
        selected: '=',
        onselect: '&',
        onrefresh: '&',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/local/directories/directories-selector.html'
    };

    return directive;
  }

  /* @ngInject */
  function Controller(
    ParamsService,
    $filter,
    LocationService,
    $scope,
    $rootScope,
    $location
  ) {
    var ctrl = this;

    ctrl.loading = true;
    ctrl.data    = {};

    LocationService.getTenantSearchEngines()
      .then(function(response) {
        ctrl.items = response.data;
        $scope.$evalAsync(function(){
          if (ctrl.selected) {
            ctrl.model = ctrl.items.filter(function(d){return ctrl.selected.indexOf(d.name) >= 0; });
          }
        })
        ctrl.loading = false;
      });


    ctrl.select = function($item, $model) {
      ctrl.onselect();
    };

    ctrl.refresh = function($select) {
      LocationService.$select = $select;
    };

    ctrl.remove = function($item, $model) {
      ctrl.onselect();
    };
  }
})();

'use strict';

angular
  .module('Local')
  .controller('CitationsCtrl', ['$scope', '$location', '$window', '$http', '$rootScope', 'LocationService', function (
    $scope,
    $location,
    $window,
    $http,
    $rootScope,
    LocationService
  ) {

    $scope.$on('$routeUpdate', function(scope, next, current) {
      $scope.params = $location.search();
    });

    $scope.params = $location.search();
    $scope.params.from = $scope.params.from || 0;
    $scope.params.size = $scope.params.size || 10;
    $scope.params.searchTerm = $scope.searchTerm || '';

    LocationService.getLocations($scope.params)
      .success(function(response){
        console.log(response)
        $scope.locations = response.rows;
        $scope.loading = false;
      })
      .error(function(response, error){
        console.log(response, error)
      });

    $scope.setParam = function(key, value) {
      console.log('setParam', key, value)
      $scope.params = $location.search();
      $scope.params[key] = value;
      $location.search($scope.params);
    }

  }]);

'use strict';

angular
  .module('Local')
  .controller('CitationsOverviewCtrl', ['$scope', '$rootScope', '$timeout', 'Citation', 'LocationService', function (
    $scope,
    $rootScope,
    $timeout,
    Citation,
    LocationService
  ) {

    $scope.loading = {
      citations: true,
      reviews: true
    };

    $rootScope.$on('local-aggs-overview-update', function($scope) {
      // updateData();
    });

    updateData();

    function updateData() {

      Citation.getCitationsOverview($scope.params).then(function(result){
        $scope.loading.citations = false;
        $scope.aggsCitationsOverview = result.data;
        $scope.localListingsChartData = [{
          key: 'Not Found',
          color: '#FF002C',
          values: [
            {
              date: 'Previous',
              color: '#FF002C',
              value: angular.isNumber($scope.aggsCitationsOverview.changeNotFound) ? ($scope.aggsCitationsOverview.totalNotFound * (1 - $scope.aggsCitationsOverview.changeNotFound)) : 0
            },
            {
              date: 'Current',
              color: '#FF002C',
              value: $scope.aggsCitationsOverview.totalNotFound
            }
          ]
        },
        {
          key: 'Found',
          color: '#93D345',
          values: [
            {
              date: 'Previous',
              color: '#93D345',
              value: angular.isNumber($scope.aggsCitationsOverview.changeFound) ? $scope.aggsCitationsOverview.totalFound * (1 - $scope.aggsCitationsOverview.changeFound) : $scope.aggsCitationsOverview.totalFound,
            },
            {
              date: 'Current',
              color: '#93D345',
              value: $scope.aggsCitationsOverview.totalFound
            }
          ]
        }];
      });



      LocationService.getReviewsOverview($scope.params).then(function(result){
        $scope.loading.reviews = false;
        $scope.aggsReviewsOverview = result.data;
        var keys = _.keys($scope.aggsReviewsOverview.currentLocalRanksByPage).sort();
        var values = keys.map(function(k){
          return {
            key: parseInt(k) + '+ pages',
            values: [
              { label: 'Organic: Previous', value: $scope.aggsReviewsOverview.previousOrganicRanksByPage[k] },
              { label: 'Local: Previous', value: $scope.aggsReviewsOverview.previousLocalRanksByPage[k] },
              { label: 'Organic: Current', value: $scope.aggsReviewsOverview.currentOrganicRanksByPage[k] },
              { label: 'Local: Current', value: $scope.aggsReviewsOverview.currentLocalRanksByPage[k] },
            ]
          }
        });
        $scope.reviewsChartData = values;
      });
    };




    $scope.localListingsChartOptions = {
      chart: {
        type: 'multiBarChart',
        height: 150,
        width: 330,
        stacked: true,
        showControls: false,
        transitionDuration: 500,
       //  .tooltipContent(function(key, y, e, graph) {
       // var data =graph.series.values[y-1];
       // return  '<p> Text1: ' +  data.Data3 + '</p>'
       //       + '<p> Text2: ' +  data.Data4 + '</p>'
       //       + '<p> Text3: ' +  data.Data5 + '</p>'
       // }),
        x: function(d){
          if (d.date !== null) {
            return d.date.replace(/&nbsp;/g,' ');
          }
          return null;
        },
        y: function(d) {
          return d.value;
        },
        showValues: true,
        showYAxis: false,
        yAxis: {
          scale: d3.scale.log(),
          tickFormat: function(d){
            return d3.format(',.0f')(Math.abs(d));
          }
        },
        valueFormat: function(d){
          return d3.format(',.0f')(Math.abs(d));
        },
        margin: {
          left:0,
          right: 50,
        },
        callback: function() {
          // return ;
          d3.selectAll('#local-listing .nv-discretebar .nv-groups .nv-group rect').attr('width', 81);
        }
      }
    };

    $scope.reviewsChartOptions = {
      chart: {
        type: 'multiBarChart',
        height: 300,
        width: 330,
        stacked: true,
        showControls: false,
        transitionDuration: 0,
        groupSpacing: 0.3,
        reduceXTicks: false,
        rotateLabels: 45,
        color: [
          '#95D14F',
          '#D4EA46',
          '#f1c40f',
          '#e67e22',
          '#e74c3c',
        ],
        x: function(d){
          return d.label;
        },
        y: function(d) {
          if (!angular.isNumber(d.value)) {
            return 0;
          }
          return d.value;
        },
        xAxis: {
          tickFormat: function(d){
            return d;
          }
        },
        showYAxis: false,
        yAxis: {
          scale: d3.scale.log(),
          tickFormat: function(d){
            return d3.format('%')(d);
          }
        },
        margin: {
          left: 0,
          right: 50,
          bottom: 120
        }
      }
    };
  }]);

'use strict';

angular
  .module('Local')
  .controller('ReportingCtrl', ['$scope', '$location', '$window', '$http', '$rootScope', '$uibModal', '$timeout', 'ParamsService', 'LocationService', 'Citation', 'FileSaver', 'Notification', 'localStorageService', function (
    $scope,
    $location,
    $window,
    $http,
    $rootScope,
    $uibModal,
    $timeout,
    ParamsService,
    LocationService,
    Citation,
    FileSaver,
    Notification,
    localStorageService
  ) {
    $scope.$params = ParamsService;

    $scope.parent = {
      dateFrom: new Date(),
      dateTo: new Date(),
    };

    $rootScope.directory = { selected: [] };

    function strToDate(dateStr) {
      var d = new Date(dateStr);
      d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
      return d;
    }

    function getPreviousDate(offset) {
      var d = new Date();
      d.setDate(d.getDate() + offset);
      return d;
    };

    $scope.searchLocations = function($event) {
      if ($event.keyCode === 13) {
        $timeout(function(){
          // $scope.loadLocations();
          $rootScope.$emit('local-aggs-overview-update');
        },0)
      }
    };

    $rootScope.isSortActive = function(field) {
      return field === $rootScope.params.sortBy;
    }

    $rootScope.sortBy = function(field, order) {
      ParamsService.setObject({
        sortAsc: order,
        sortBy: field,
      });
    };

    $rootScope.loadLocations = $scope.loadLocations = function() {
      $rootScope.params = $location.search();
      $rootScope.params.page = +$rootScope.params.page || 0;
      $rootScope.params.size = +$rootScope.params.size || 10;
      $rootScope.params.searchText = $rootScope.params.searchText || '';
      if ($rootScope.params.directories) {
        $rootScope.params.directories = $rootScope.params.directories.forEach ? $rootScope.params.directories : [$rootScope.params.directories];
        // $rootScope.params.directories = LocationService.getParams().directories;
      }

      $scope.loading = true;

      // $rootScope.$emit('local-aggs-overview-update');

      LocationService.getLocations($rootScope.params)
        .then(function(response){
          $scope.totalCount = response.data.totalCount;
          $scope.locations = response.data.rows;
          $scope.loading = false;

        }, function(response, error){
          console.log(response, error)
        });
    };

    $scope.loadLocations();

    $scope.dateChange = function($this, $value, $event) {
      $rootScope.params.dateFrom = formatDate(new Date($scope.parent.dateFrom));
      $rootScope.params.dateTo = formatDate(new Date($scope.parent.dateTo));
      localStorageService.set('dateFrom', $rootScope.params.dateFrom);
      localStorageService.set('dateTo', $rootScope.params.dateTo);
      $scope.go();
    };

    function formatDate(date) {
      if (!date) date = new Date();
      return date.toISOString().slice(0, 10);
    };

    /**
     * Executes all the events related with update of page results
     */
    $scope.go = function() {
      $location.search($rootScope.params);
      $scope.loadLocations();
    }

    ParamsService.subscribe($scope, function(scope, paramName){
      $location.search($rootScope.params);
      $scope.loadLocations();
      $rootScope.$emit('local-aggs-overview-update');
      $scope.$emit('$routeUpdate');
    });

    $scope.setParam = function(key, value) {
      $rootScope.params = $location.search();
      $rootScope.params[key] = value;
      $location.search($rootScope.params);
    }

    LocationService.getDirectories()
      .then(function(response) {
        $scope.directories = response.data;

        var tooltips = {
          'BING_LOCAL': 'This ranking is for bing.com/maps',
          'YAHOO_LOCAL': 'This ranking is for local.yahoo.com',
          'GOOGLE_LOCAL': 'This ranking is for google.com/maps',
          'GOOGLE': 'This ranking is for the Map Pack in organic results',
          'YAHOO': 'This ranking is for the Map Pack in organic results',
          'MSN': 'This ranking is for the Map Pack in organic results',
        };

        var colors = {
          'MSN': '#008485',
          'BING_LOCAL': '#008485',
          'GOOGLE': '#2AAD54',
          'GOOGLE_LOCAL': '#2AAD54',
          'YAHOO': '#4D00A6',
          'YAHOO_LOCAL': '#4D00A6',
          'CITYSEARCH': '#59BCEE',
          'SUPERPAGES': '#06A7E6',
          'MERCHANTCIRCLE': '#F35A4B',
          'FOURSQUARE': '#2956E7',
          'YELLOWPAGES': '#FFDF00',
          'YELP': '#D11600',
        };

        $rootScope.directoriesByKey = {};
        $rootScope.directoriesByTitle = {};
        response.data.forEach(function(d){
          $rootScope.directoriesByKey[d.name] = _.merge({}, d, {
            tooltip: tooltips[d.name],
            color: colors[d.name],
          });
          $rootScope.directoriesByTitle[d.title] = _.merge({}, d, {
            tooltip: tooltips[d.name],
            color: colors[d.name],
          });
        });

        // Initial triggering of Stats, dependant on directory data.
        // $rootScope.$emit('local-aggs-overview-update');
      });

    $scope.selectDirectory = function(d, i) {
      var directories = $scope.directory.selected.map(function(d){ return d.name; });
      var params = { 'directories': directories };
      LocationService.validFilters.forEach(function(d){
        params[d] = undefined;
      });
      ParamsService.setObject(params);

      $scope.go();
      $rootScope.$emit('local-aggs-overview-update');
    }

    $scope.tabs = {
      details: { active: true },
      ranks: { active: false },
      citations: { active: false },
      reviews: { active: false },
    };

    $scope.showTab = function (tab, location, type) {
      $scope.tabs[tab].active = true;
      location.expand = true;
      location.rankType = type;
    };

    $scope.exportLocations = function() {
      Notification.success('Download started.');
      LocationService.exportLocations()
        .then(function(response){
          downloadCsv(response.data, 'locations');
        });
    };

    function downloadCsv(data, filename) {
      var data = new Blob([data], { type: 'text/csv;charset=utf-8' }),
        time  = new Date(),
        month = time.toDateString().split(' ').slice(1,2).shift().toLowerCase(),
        year = time.getUTCFullYear(),
        tenant = $rootScope.tenantName,
        config = {
          data: data,
          filename: tenant + '-' + month + '-' + year + '.csv'
        };
      FileSaver.saveAs(config);
    }

    $scope.toggleAggregations = function() {
      $rootScope.showAggregations = !$rootScope.showAggregations;
      localStorageService.set('showAggregations', $rootScope.showAggregations);
        // $scope.loadLocations();
        // $rootScope.$emit('local-aggs-overview-update');
      $scope.$emit('$routeUpdate');
    };

    $rootScope.showAggregations = localStorageService.get('showAggregations');

    $scope.$emit('$routeUpdate');
  }]);

'use strict';

angular
  .module('Local')
  .controller('LocationsCtrl', ['$scope', '$location', '$window', '$http', '$rootScope', '$uibModal', 'LocationService', 'GridWidthSrvc', 'ParamsService', function (
    $scope,
    $location,
    $window,
    $http,
    $rootScope,
    $uibModal,
    LocationService,
    GridWidthSrvc,
    ParamsService
  ) {
    $scope.loadLocations = loadLocations

    // Adjust Main Column width dynamically depending on Column Total Count
    GridWidthSrvc.mainColumnWidth(10);

    // Load locations
    function loadLocations() {
      $rootScope.params = $location.search();
      $rootScope.params.page = +$rootScope.params.page || 0;
      $rootScope.params.size = +$rootScope.params.size || 10;
      $rootScope.params.searchText = $rootScope.params.searchText || '';
      // If search text is sent sorting should be by relevance
      // @todo compare with previous values to check if it changed
      if ($rootScope.params.searchText !== '') {
        $rootScope.params['sort'] = null;
      }
      $scope.loading = true;

      LocationService.getLocations($rootScope.params)
        .success(function(response){
          $scope.totalCount = response.totalCount;
          $scope.locations  = response.rows;
          $scope.loading    = false;
        })
        .error(function(response, error){
          console.log(response, error)
        });
    };

    $scope.getColspanFixed = function (columnCount) {
      var minimalColumns = columnCount - 5;
      var colspan = minimalColumns + 2;

      return colspan;
    }

    /**
     * Executes all the events related with update of page results
     */
    $scope.go = function(params) {
      $location.search($rootScope.params);
    }

    $scope.$emit('$routeUpdate');

  }]);

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', 'LocationService', '$scope', '$rootScope', '$location', 'currentUser'];
  angular
    .module('Local')
    .controller('LocationsFiltersCtrl', Controller);

  function Controller (
    ParamsService,
    LocationService,
    $scope,
    $rootScope,
    $location,
    currentUser
  ) {
    var locationTypes = [
      { name: 'Managed', label: 'Managed', selected: false },
      { name: 'Control', label: 'Control', selected: false }
    ];
    var searchFields = [
      { name: 'BUSINESS_NAME', label: 'Business Name', selected: true },
      { name: 'CITY', label: 'City', selected: true },
      { name: 'STATE', label: 'State', selected: true },
      { name: 'COUNTRY', label: 'Country', selected: true },
      { name: 'ZIP', label: 'Zip Code', selected: true },
      { name: 'FID', label: 'Location FID', selected: true },
      { name: 'LID', label: 'Location LID', selected: true }
    ];

    if (currentUser.tenant.searchableProfileFields) {
      _.each(currentUser.tenant.searchableProfileFields, function(f) {
        searchFields.push({name: "profile:" + f, label: f, selected: true});
      });
    }

    function updateSpecialties() {
      LocationService.getSpecialties().then(function(response) {
          var specialties = _.map(response.data, function(name) {
            return {name: name, label: name, selected: false};
          });

          $scope.specialties = specialties;
      });
    }

    function updateRadii() {
      LocationService.getRadii().then(function(response) {
          $scope.radii = _.map(response.data, function(e) { return e.toString() });
          
          if ($scope.radii.length == 0) {
            $scope.filters.radius = 'All';
          } else {
            $scope.filters.radius = $scope.radii[0];
          }
      });
    }

    updateSpecialties();
    updateRadii();

    ParamsService.subscribe($scope, updateSpecialties);
    ParamsService.subscribe($scope, updateRadii);

    $scope.currentPath = $location.path();

    $scope.searchFields = searchFields;

    $scope.locationTypes = locationTypes;
    $scope.filterBySearchFields = filterBySearchFields;
    $scope.go = go;
    $scope.newdeltas = ParamsService.get('newdeltas');
    if(!$scope.newdeltas){
      $scope.newdeltas = 'default';
      ParamsService.setSilent('newdeltas', $scope.newdeltas);
    }
    $scope.filters = {
      reportType: "Local",
      nationality: "All",
      brand: "All",
      specialty: undefined,
      collapseColumns: {false:true, true:true},
      radius: undefined
    };

    // Exact Match
    $rootScope.params.exactMatch = $rootScope.params.exactMatch !== undefined ? $rootScope.params.exactMatch === true : true;
    $scope.exactMatchToggle = function(){
      $rootScope.params.exactMatch = !$rootScope.params.exactMatch;
      go();
    };

    function filterBySearchFields() {
      var fields = _.map(_.filter($scope.searchFields, { selected: true}), 'name');
      $rootScope.params.fieldFilters = _.isEmpty(fields) ? [] : fields;
    }

    function go () {
      console.log('LocationsFiltersCtrl');
      ParamsService.setObject($rootScope.params);
    }

    $scope.onNewDeltasChanges = function() {
      ParamsService.set('newdeltas',this.newdeltas);
    }

    $scope.switchTableRanking = function(newStatus) {
      $scope.tableRanking = newStatus;
      $rootScope.$broadcast('switchTableRanking', [newStatus]);
    }

    $scope.switchDataSource = function(newStatus) {
      $scope.viewMigratedData = newStatus;
      $rootScope.$broadcast('switchDataSource', [newStatus]);
    }

    $scope.switchNAPVisualization = function() {
      $rootScope.$broadcast('switchNAPVisualization', []);
    }

    $scope.onSpecialtyChange = function() {
      ParamsService.set('specialty',$scope.filters.specialty);
      $rootScope.$broadcast('specialty', [$scope.filters.specialty]);
    }

    $scope.onReportType = function() {
      $rootScope.$broadcast('reportType', [$scope.filters.reportType]);
      $scope.filters.radius = 'All';
    }

    $scope.onNationalityChange = function() {
      ParamsService.set('nationality',$scope.filters.nationality);
      $rootScope.$broadcast('nationality', [$scope.filters.nationality]);
    }

    $scope.onRadiusChange = function() {
      $rootScope.$broadcast('radiusChanged', [$scope.filters.radius]);
    }

    $scope.onBrandChanges = function() {
      ParamsService.set('brand',$scope.filters.brand);
      $rootScope.$broadcast('brand', [$scope.filters.brand]);
    }

    $scope.onCollapseColumns = function() {
      $scope.filters.collapseColumns[$scope.tableRanking] = !$scope.filters.collapseColumns[$scope.tableRanking];
      $rootScope.$broadcast('collapseColumns', [$scope.filters.collapseColumns[$scope.tableRanking]]);
    }
  }
})();

'use strict';

angular
  .module('Local')
  .controller('LocationDetailsCtrl', ['$scope', function (
    $scope
  ) {
    var buff = "";
    var counter = 0;
    var len, index, hour, buffer;

    if($scope.location.hourRange && !$scope.location.hourRangeParsed) {
      if ($scope.location.hourRange === "Sun: closed, Mon: closed, Tue: closed, Wed: closed, Thu: closed, Fri: closed, Sat: closed") {
        $scope.location.hourRange = "";
      } else {
        buffer = $scope.location.hourRange.split(",");
        len = buffer.length - 1;
        while(counter < len) {
          hour = buffer[counter++];
          index = hour.indexOf(" ",2);
          buff += "<div><div class='hourClassDay'>"+hour.slice(0,index).bold()+"</div><div class='hourClassHour'>"+hour.slice(index)+"</div></div>";
        }
        hour = buffer[counter];
        index = hour.indexOf(" ",2);
        buff += "<div><div class='hourClassDay'>"+hour.slice(0,index).bold()+"</div><div class='hourClassHour'>"+hour.slice(index)+"</div></div>";
        $scope.location.hourRange = buff;
      }
      $scope.location.hourRangeParsed = true;
    }

    var organicDirectories = [
      'Bing',
      'Google',
      'Yahoo!'
    ];

    $scope.filterDirectories = function (item) {
      if ($scope.location.ranks.type == 'Organic') {
        return organicDirectories.indexOf(item.directory) !== -1;
      }
      return true;
    }

    $scope.isSortActive = function(field, order) {
      return $scope.params.sortBy == field;
    }

    $scope.sortBy = function(field, order) {
      $scope.location.ranks.sortBy = field;
      var func = order ? d3.ascending : d3.descending,
        key = 'top' + $scope.location.ranks.type + 'Rank';
      $scope.location.ranks.directories = $scope.location.ranks.directories.sort(function(a, b) {
        var valA = a.byKeyword[field] && a.byKeyword[field][0][key] ? a.byKeyword[field][0][key] : (order ? Infinity : 0),
        valB = b.byKeyword[field] && b.byKeyword[field][0][key] ? b.byKeyword[field][0][key] : (order ? Infinity : 0),
        result = func(valA, valB);
        return result;
      })
    }
  }]);

(function() {
  'use strict';

  Controller.$inject = ['LocationService', '$uibModal', '$rootScope', 'currentUser'];
  angular
    .module('Local')
    .controller('LocationRanksCtrl', Controller);

  /* @ngInject */
  function Controller(
    LocationService,
    $uibModal,
    $rootScope,
    currentUser
  ) {
    var ctrl = this;

    ctrl.init    = init;
    ctrl.loading = true;

    ctrl.showRanksDialog = function(keyword, rankType, dir, serpUID) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'modules/keyword/keyword-ranks-dialog.html',
        controller: 'KeywordRankCtrl as ctrl',
        resolve: {
          params: function() {
            return {
              details: {
                location: ctrl.location,
                fid: ctrl.location.fid,
                lid: ctrl.location.lid,
                directory: dir,
                title: currentUser.directoryLabels[dir].forRanking + ' Ranks for Keyword: ' + keyword,
              },
              keyword: { name: keyword },
              project: null,
              rankType: rankType,
              segment: null,
              source: 'LOCAL',
              serpUID: serpUID
            };
          },
        },
        size: 'lg'
      });
    };

    function loadData (params) {
      LocationService.getNewRanks(params).then(function(response){
        ctrl.location.rankType = ctrl.location.rankType || 'Local';
        ctrl.location.ranks = response.data;
        ctrl.keywords = response.data.keywords;
        var byType = {};
        response.data.rankingAggregationSummaries.forEach(function(d) {
          if (!byType[d.type]) {
            byType[d.type] = {};
          }
          var row = byType[d.type];
          if (!row[d.directory]) {
            row[d.directory] = {
              directory: d.directory,
              type: d.type,
              serpUID: d.serpUID
            };
          }
          var dir = row[d.directory];
          dir[d.keyword] = {
            topPosition: d.topPosition,
            count: d.count,
            serpSnapshot: d.serpSnapshot
          };
        });
        for (var type in byType) {
          var rows = [];
          for (var dir in byType[type]) {
            rows.push(byType[type][dir]);
          }
          byType[type].rows = rows;
        }
        ctrl.ranks = byType;
        ctrl.loading = false;
      });
    }

    function init (location) {
      ctrl.location = location;
      var params = {
        site: $rootScope.tenantName,
        fid: ctrl.location.fid,
        lid: ctrl.location.lid,
        directory: $rootScope.params.dir || 'GOOGLE'
      }
      loadData(params);
    }
  }
})();

(function() {
  'use strict';

  Controller.$inject = ['LocationService', '$uibModal', '$rootScope', 'ParamsService', 'moment', 'currentUser', 'DataDateService'];
  angular
    .module('Local')
    .controller('LocationCitationsCtrl', Controller);

  function Controller(
    LocationService,
    $uibModal,
    $rootScope,
    ParamsService,
    moment,
    currentUser,
    DataDateService
  ) {
    var ctrl = this;

    ctrl.init    = init;
    ctrl.loading = true;
    ctrl.params = ParamsService;

    var citationFilters = {
      null: function (citation) { return true; },
      found: function(citation){ return citation.dataMatch == 1 },
      partial: function(citation){ return citation.dataMatch > 0 && citation.dataMatch < 1 },
      notFound: function(citation){ return citation.dataMatch == 0 },
    };

    ctrl.dataMatchCount = {
      found: 0,
      partial: 0,
      notFound: 0
    };

    ctrl.citationFilter = citationFilter;

    ctrl.showSerp = function(location, citation, serpUID) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'modules/keyword/keyword-ranks-dialog.html',
        controller: 'KeywordRankCtrl as ctrl',
        resolve: {
          params: function() {
            return {
              details: {
                location: location,
                fid: location.fid,
                lid: location.lid,
                directory: citation.directory,
                title: currentUser.directoryLabels[citation.directory].forRanking + ' Ranks for Location: ' + location.businessName,
              },
              keyword: { name: '' },
              project: null,
              segment: null,
              source: 'LOCAL',
              rankType: 'Local',
              selectedDate: ctrl.params.get('dataDate'),//Mock up: delete [selectedDate, datesArray]
              datesArray: ctrl.params.get('popUpDates'),
              serpUID: serpUID
            };
          },
        },
        size: 'lg'
      });
    };

    ctrl.openCitation = function(citation, location, field) {
      var modalInstance = $uibModal.open({
        size: 'lg',
        templateUrl: 'modules/local/views/citation-modal.html',
        resolve: {
          field: function() { return field },
          location: function() { return location },
          citation: function() { return citation },
          directory: function() { return citation.directory },
          url: function() { return citation.citationURL },
          source: function() { return 'CITATION' },
        },
        controller: 'CitationsCompareCtrl as ctrl'
      });
    };

    ctrl.logCitationAudit = function(citation, location) {
      LocationService.logCitationAudit(citation.dataDate, citation.directory, location.lid)
      .then(function(response) {
        init(ctrl.location);
      });
      // then loadData or init?
    }

    function citationFilter (type) {
      return citationFilters[type];
    }

    function loadData (params) {
      var selectedDataDate = DataDateService.dataDate; // the data date selected in the UI
      var currentDataDate = currentUser.dataDate; // data date configured to be the "current" one for the client

      ctrl.canAudit = selectedDataDate == currentDataDate;

      LocationService.getNewCitations(params).then(function(response){
        ctrl.dataMatchCount = {
          found: 0,
          partial: 0,
          notFound: 0
        };

        ctrl.location.citations = response.data ? response.data.map(function(c) {

          if (c.dataMatch == 1) ctrl.dataMatchCount.found++;
          if (c.dataMatch > 0 && c.dataMatch < 1) ctrl.dataMatchCount.partial++;
          if (c.dataMatch == 0) ctrl.dataMatchCount.notFound++;

          c.activeAudit = false;
          if (typeof c.auditExpiresOn !== 'undefined' && c.auditExpiresOn !== null) {
            if (moment(c.auditExpiresOn).isAfter(moment())) {
              c.activeAudit = true;
            }
          }

          if (typeof c.auditedOn !== 'undefined' && c.auditedOn !== null) {
            c.auditedOn = moment(c.auditedOn).format("MM/DD")
          }

          return c;
        }) : [];
        ctrl.loading = false;
      });
    }

    function init (location) {
      ctrl.location = location;
      var params = {
        site: $rootScope.tenantName,
        fid: ctrl.location.fid,
        lid: ctrl.location.lid,
        directory: $rootScope.params.dir || 'GOOGLE'
      }
      loadData(params);
    }
  }
})();

(function() {
  'use strict';

  Controller.$inject = ['field', 'location', 'citation', 'source', 'directory', 'url', '$uibModal', '$rootScope', 'LocationService', 'Notification', '$scope', 'currentUser'];
  angular
    .module('Local')
    .controller('CitationsCompareCtrl', Controller);

  function Controller(
    field,
    location,
    citation,
    source,
    directory,
    url,
    $uibModal,
    $rootScope,
    LocationService,
    Notification,
    $scope,
    currentUser
  ) {
    $scope.currentUser = currentUser;
    var ctrl = this;

    ctrl.collapsedDescription = true;
    ctrl.field        = field;
    ctrl.location     = location;
    ctrl.citation     = citation;
    ctrl.source       = source;


    function loadData() {
      ctrl.loading = true;

      var citPromise;

      if (source === 'ADHOC') {
        citPromise = LocationService.getAdhocCitation({
          lid: location.lid,
          directory: directory,
          citationURL: url
        });
      } else if (source === 'RANK') {
        citPromise = LocationService.getLocalCitationCompare({
          lid: location.lid,
          directory: directory,
          citationURL: url
        });
      } else if (source === 'CITATION') {
        citPromise = LocationService.getCitationsCompare({
          lid: location.lid,
          directory: directory
        });
      }

      citPromise.then(function(response) {
        var locationHourRangeRaw = response.data.locationSnapshot && response.data.locationSnapshot.hourRange ? response.data.locationSnapshot.hourRange.split(",") : [];
        var citationHourRangeRaw = response.data.chosen && response.data.chosen.hourRange ? response.data.chosen.hourRange.split(",") : [];
        var index = 0;
        var counter = 0;
        var len = citationHourRangeRaw.length > locationHourRangeRaw.length ? citationHourRangeRaw.length : locationHourRangeRaw.length;
        var chour, lhour;

        ctrl.compare = response.data;

        ctrl.locationUrls = ctrl.compare ? ctrl.compare.locationSnapshot.locationURLs || ctrl.location.locationURLs : ctrl.location.locationURLs;
        ctrl.hour = {location: "", citation: "", length: len};
        if(ctrl.compare.chosen && ctrl.compare.chosen.photosURLs) {
          ctrl.photosURLs = _.uniq(ctrl.compare.chosen.photosURLs);
        }
        ctrl.slickConfig = {
          arrows: false,
          enabled: true,
          autoplay: false,
          draggable: false,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          //centerMode: true,
          variableWidth: true,
          method: {},
          event: {
            beforeChange: function (event, slick, currentSlide, nextSlide) {
            },
            afterChange: function (event, slick, currentSlide, nextSlide) {
            }
          }
        };

        while(counter < len) {
          chour = citationHourRangeRaw[counter];
          index = chour ? chour.indexOf(" ",2)+1 : 0;
          ctrl.hour.citation += chour ? "<div><div class='hourClass'>"+chour.slice(0,index).bold()+"</div><div>"+chour.slice(index)+"</div></div>" : '';
          lhour = locationHourRangeRaw[counter];
          index = lhour ? lhour.indexOf(" ",2)+1 : 0;
          ctrl.hour.location += lhour ? "<div><div class='hourClass'>"+lhour.slice(0,index).bold()+"</div><div>"+lhour.slice(index)+"</div></div>" : '';
          counter++;
        }

        ctrl.loading = false;
      }, function(errorResponse) {
        ctrl.loading = false;
        $scope.$close();
        if (errorResponse && errorResponse.data && errorResponse.data.messages) {
          Notification.error(errorResponse.data.messages.join("<br>"));
        } else {
          Notification.error("Error loading citation");
        }
      });
    }

    loadData();

    ctrl.showSerp = function(location, citation, serpUID) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'modules/keyword/keyword-ranks-dialog.html',
        controller: 'KeywordRankCtrl as ctrl',
        resolve: {
          params: function() {
            return {
              details: {
                directory: citation.directory,
                title: currentUser.directoryLabels[citation.directory].forRanking + ' Ranks for Serp UID: ' + serpUID,
              },
              keyword: { name: '' },
              project: null,
              segment: null,
              source: 'LOCAL',
              rankType: 'Local',
              serpUID: serpUID
            };
          },
        },
        size: 'lg'
      });
    };
    ctrl.onShowMore = function() {
      ctrl.collapsedDescription = !ctrl.collapsedDescription;
    };

    ctrl.runAdhocCitation = function() {
      var modalInstance = $uibModal.open({
        size: 'lg',
        templateUrl: 'modules/local/views/citation-modal.html',
        resolve: {
          field: function() { return field },
          location: function() { return location },
          citation: function() { return citation },
          directory: function() { return directory },
          url: function() { return url },
          source: function() { return 'ADHOC' },
        },
        controller: 'CitationsCompareCtrl as ctrl'
      });
    };
  }
})();

(function() {
  'use strict';

  Controller.$inject = ['LocationService', 'ParamsService', '$rootScope', '$location', '$scope', 'DataDateService', 'currentUser'];
  angular
    .module('Local')
    .controller('LocationNapCtrl', Controller);

  function Controller(
    LocationService,
    ParamsService,
    $rootScope,
    $location,
    $scope,
    DataDateService,
    currentUser
  ) {
    $scope.currentUser = currentUser;
    
    var ctrl = this;

    var switchTableRanking = $rootScope.$on('switchTableRanking', function(event, data) {
      $scope.tableRanking = data[0];
    });
    var switchDataSource = $rootScope.$on('switchDataSource', function(event, data) {
      console.log("Clicked on a switchDataSource() trigger");
      $scope.viewMigratedData = data[0];
      loadData();
    });
    var reportType = $rootScope.$on('reportType', function(event, data) {
      $scope.reportType = data[0];
      $scope.radius = 'All';
      $scope.getRankingReport();
    });

    var radiusChanged = $rootScope.$on('radiusChanged', function(event, data) {
      $scope.radius = data[0];
      $scope.getRankingReport();
    });

    var switchNAPVisualization = $rootScope.$on('switchNAPVisualization', function(event, data) {
      $scope.alternateCitationNAP = !$scope.alternateCitationNAP;
    });

    var specialty = $rootScope.$on('specialty', function(event, data) {
      $scope.specialty = data[0];
    });

    var location = $rootScope.$on('location', function(event, data) {
      $scope.location = data[0];
    });

    var brand = $rootScope.$on('brand', function(event, data) {
      $scope.brand = data[0];
    });

    var collapseColumns = $rootScope.$on('collapseColumns', function(event, data) {
      $scope.collapseColumns[$scope.tableRanking] = data[0];
    });

    $scope.rankingReports = 'loading';
    $scope.reportType = 'Local';
    $scope.nationality = 'All';
    $scope.radius = 3;
    $scope.specialty = undefined;
    $scope.brand = 'All';
    $scope.collapseColumns = {true: true, false: true};
    $scope.tableRanking = false;
    $scope.viewMigratedData = false;
    $scope.alternateCitationNAP = $rootScope.tenantName == 'u-haul'; // todo maybe make this configurable from admin settings?

    ////Mock up: below
    //@delete everything from here
    ParamsService.setSilent('popUpDates', DataDateService.availableDates());
    //@to here

    // @todo Instead of location, it should be retrieved from ParamsService
    ctrl.selectedDirectory                 = $location.search().directories ? $location.search().directories[0] : null;
    ctrl.selectedMetric                    = $location.search().listingAccuracy || null;
    ctrl.filterLocationsByNAPMetric        = filterLocationsByNAPMetric;
    ctrl.filterLocationsByNAPRankingMetric = filterLocationsByNAPRankingMetric;
    ctrl.isNAPOptionSelected               = isNAPOptionSelected;
    ctrl.isNAPRankingOptionSelected        = isNAPRankingOptionSelected;
    ctrl.getCitationFoundTrends            = getCitationFoundTrends;
    ctrl.formatTrend                       = formatTrend;

    // Refresh data when params are updated
    ParamsService.subscribe($scope, loadData);
    loadData ();

    // Load Listing Accuracy Summary Data
    function loadData () {
      console.log($rootScope);
      LocationService.getListingAccuracySummary({viewMigratedData: $scope.viewMigratedData, did: $rootScope.tenant.rlsDomainId}).then(function(res){
        ctrl.listing = res.data.directories.map(function(i){
          i.totalItems = i.found.count + i.notFound.count;
          return i;
        });
        ctrl.total   = res.data.total;
        $scope.getRankingReport();
      });
    }

    $scope.getRankingReport = function() {
      var rankingReportParams = {
        type: $scope.reportType,
        nationality: $scope.nationality,
        brand: $scope.brand,
        collapseColumns: $scope.collapseColumns[$scope.tableRanking],
        radius: $scope.radius,
        did: $rootScope.tenant.rlsDomainId,
        viewMigratedData: $scope.viewMigratedData
      };

      $scope.rankingReports = 'loading';
      LocationService.getRankingReport(rankingReportParams).then(function(res){
        function addKeywordToProject(keyword, directoryData){
          var directory = {};
          var dirName = directoryData.directory;
          var errors = "";
          directoryData.pageCounters.forEach(function(page){
            directory[page.rankingPage] = {'value': page.count, 'delta': page};
          });
          if(directoryData.rankingErrors) {
            directoryData.rankingErrors.forEach(function(error) {
              errors += error.rankingError + ": " + error.count + "\n";
            });
            directory.tooltip = errors.slice(0,-1);
          } else {
            directory.tooltip = "";
          }
          if(!$scope.rankingReports.directoriesData[dirName]) {
            $scope.rankingReports.directoriesData[dirName] = {};
            $scope.rankingReports.directories.push(dirName);
          }
          directory.class = directoryData.backgroundColor;
          $scope.rankingReports.directoriesData[dirName][keyword] = directory;
        };
        $scope.rankingReports = {
          keywords:[],
          directoriesData: {},
          directories: [],
          rankingPages: ["TOP_1","TOP_3","TOP_10","TOP_50","UNRANKED_SUM","UNRANKED", "NOT_RUN", "EMPTY_RESULTS", "ERRORS"]
        };
        res.data.results.sort(function(a, b){
          var res = false;
          if(b.brandClassification == a.brandClassification){
            res = a.keyword > b.keyword;
          }else{
            res = a.brandClassification > b.brandClassification;
          }
          return res
        });
        res.data.results.forEach(function(keywordData){
          var keyword = keywordData.keyword;
          if($scope.rankingReports.keywords.indexOf(keyword) == -1)
            $scope.rankingReports.keywords.push(keyword);
          keywordData.directoryResults.forEach(function(directoryData){
            addKeywordToProject(keyword, directoryData);
          });
        });
      });
    }

    function filterLocationsByNAPMetric(directory, metric) {
      if(isNAPOptionSelected(directory, metric)){
        ctrl.selectedDirectory = null;
        ctrl.selectedMetric    = null;
      } else{
        ctrl.selectedDirectory = new Array(directory);
        ctrl.selectedMetric    = metric;
      }

      // Save to Params service and trigger events
      ParamsService.setObject({
        listingAccuracy: ctrl.selectedMetric,
        directories: ctrl.selectedDirectory,
      });
    }

    function filterLocationsByNAPRankingMetric(directory, keyword, metric) {
      if(isNAPRankingOptionSelected(directory, keyword, metric)){
        ctrl.rankingSelectedDirectories = null;
        ctrl.rankingKeyword = null;
        ctrl.rankingSelectedMetric    = null;
      } else{
        ctrl.rankingSelectedDirectories = new Array(directory);
        ctrl.rankingKeyword = keyword;
        ctrl.rankingSelectedMetric    = metric;
      }

      // Save to Params service and trigger events
      ParamsService.setObject({
        rankingDirectories: ctrl.rankingSelectedDirectories,
        rankingKeyword: ctrl.rankingKeyword,
        rankingPage: ctrl.rankingSelectedMetric,
        rankingType: $scope.reportType,
        directories: ctrl.rankingSelectedDirectories,
        nationality: $scope.nationality,
        radius: $scope.radius,
      });
    }

    function isNAPOptionSelected(directory, metric) {
      return (ctrl.selectedMetric === metric && ctrl.selectedDirectory.indexOf(directory) !== -1);
    }

    function isNAPRankingOptionSelected(directory, keyword, metric) {
      return (ctrl.rankingSelectedMetric === metric && ctrl.rankingKeyword === keyword && ctrl.rankingSelectedDirectories.indexOf(directory) !== -1);
    }

    // todo these trends need to be handled WAY better. right now, each sparkline calls web service and gets all trends for all directories
    // we should either only call once, or each sparkline should call only for its own trend/directory
    function getCitationFoundTrends() {
      return LocationService.getCitationFoundTrends();
    }

    function formatTrend(dirName, metric) {
      return function(response) {
        var foundData = _.find(response, function(d) { return d.type == metric; });
        if (typeof(foundData) === 'undefined') {
          return [];
        }

        var dirData = _.find(foundData.response.responseByDirs, function(d) { return d.directory == dirName; });

        if (typeof(dirData) === 'undefined') {
          return [];
        }

        return {
          series: [
            {
              name: 'Locations ' + metric,
              data: dirData.snapshots.map(function(d) {
                var date = moment(d.date, 'YYYYMMDD');
                return {
                  name: currentUser.directoryLabels[dirData.directory].forCitation + ", " + date.format('MMM YYYY'),
                  x: date.valueOf(),
                  y: d.value
                };
              })
            }
          ]
        };
      }
    }

    $scope.$on('$destroy', switchTableRanking);
  }
})();

//Reports Controller

angular
  .module('Local')
  .controller('ExportCtrl', ['$uibModal', '$rootScope', '$scope', '$state', '$stateParams', 'AdminService', 'localStorageService', 'FileSaver', 'ParamsService', 'moment', function ($uibModal,
                                                  $rootScope,
                                                  $scope,
                                                  $state,
                                                  $stateParams,
                                                  AdminService,
                                                  localStorageService,
                                                  FileSaver,
                                                  ParamsService,
                                                  moment) {
    $rootScope.params = ParamsService.params;
    $scope.reports = [];

    loadData();
    function loadData() {
      $scope.loading = true;

      AdminService.searchReports($scope.tenantName, ParamsService.params)
        .success(function (response) {
          $scope.reports = response.rows;
          $scope.totalCount = response.totalCount;
          $scope.loading = false;
        })
        .error(function (error) {
          console.log(error)
        });
    }

    ParamsService.subscribe($scope, loadData);

    $scope.loadData = loadData;

    $scope.toggleChecked = function(list) {
      if (!list) return;
      var status = !list.allchecked;
      list.forEach(function(item) {
        item.enabled = status;
      });
      list.allchecked = status;
    }

    $scope.getChecked = function(list) {
      if (!list) return [];
      return list.filter(function(item){ return item.enabled });
    };

    $scope.updateChecked = function(list) {
      if (!list) return 0;
      var checked = $scope.getChecked(list);
      list.allchecked = list.length === checked.length;
      return true;
    };

    $scope.download = function (report) {
      AdminService.downloadReport($scope.tenantName, report.uid)
        .then(function (response) {
          var data = new Blob([response.data], {type: 'text/csv;charset=utf-8'}),
            timestamp = new Date().toISOString().substring(0, 10),
            config = {
              data: data,
              filename: report.name + ".csv"
            };
          FileSaver.saveAs(config);
        })
    };

    $scope.add = function () {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/local/export/exports.edit.html',
        controller: 'EditReportModal',
        resolve: {
          report: function () {
            var d = {};
            d.status = 'Pending';
            d.startDate = moment().subtract(1, 'months').format('YYYYMMDD');
            d.endDate = moment().subtract(1, 'days').format('YYYYMMDD');
            return d;
          },
          tenant: function () {
            return $scope.tenantName;
          },
          mode: function () {
            return "add";
          }
        }
      });
    };

    $scope.edit = function (report) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/local/export/exports.edit.html',
        controller: 'EditReportModal',
        resolve: {
          report: function () {
            return report;
          },
          tenant: function () {
            return $scope.tenantName;
          },
          mode: function () {
            return "edit";
          }
        }
      });
    };

    $scope.delete = function (checkedReports) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modules/local/export/exports.delete.html',
        controller: 'DeleteReportsModal',
        resolve: {
          reports: function () {
            return checkedReports;
          },
          tenant: function () {
            return $scope.tenantName
          }
        }
      });
    };
  }]);

angular
  .module('Local')
  .controller('EditReportModal', ['$scope', '$rootScope', '$uibModalInstance', 'AdminService', 'Notification', 'DataDateService', 'tenant', 'report', 'mode', '$state', function ($scope, $rootScope, $uibModalInstance, AdminService, Notification, DataDateService, tenant, report, mode, $state) {
    var ctrl = this;

    $scope.report = report;
    $scope.mode = mode;
    $scope.addAnother = false;
    $scope.timePeriods = getTimePeriods();

    $scope.citationAuditEnabled = $rootScope.tenant.citationAuditEnabled;

    var orig = angular.copy($scope.report);
    $scope.reset = function (scope) {
      angular.copy(orig, scope);
    };

    function getTimePeriods() {
      var available = DataDateService.availableDates();
      return available;
      // debugger;
      // var monthNames = ["January", "February", "March",
      //   "April", "May", "June", "July", "August", "September",
      //   "October", "November", "December"];
      // var currentMonth = new Date();
      // var tmp = new Date(currentMonth);
      // tmp.setMonth(currentMonth.getMonth() - 5);
      // var a = [];

      // while(tmp <= currentMonth) {
      //   var display = monthNames[tmp.getMonth()]+' '+tmp.getFullYear();
      //   var value = (tmp.toISOString().substring(0, 8).replace(/:|T/g, '')+'01').replace(/-/g,'');
      //   var dateObject = new Object();
      //   dateObject.display = display;
      //   dateObject.value = value;
      //   a.push(dateObject);
      //   tmp = new Date(tmp.setMonth(tmp.getMonth() +1));
      // }
      // return a;
    };



    $scope.updateReport = function (isValid, report) {
      console.log("Time Period [%s]", report.timePeriod);
      if (isValid) {
        var report = {
          uid: report.uid,
          name: report.name,
          type: report.type,
          status: report.status,
          configurations: JSON.stringify({timePeriod: report.timePeriod, startDate: report.startDate, endDate: report.endDate})
        };

        if ($scope.mode == 'edit') {
          AdminService.updateReport(tenant, report)
            .then(function (response) {
                $uibModalInstance.close();
                $state.reload();
                Notification.success('Report successfully updated.');
              },
              function (response) {
                var msg = response.data.messages && response.data.messages.length ? response.data.messages.shift() : 'Error saving the report.';
                Notification.error(msg);
              });
        }
        else {
          AdminService.updateReport(tenant, report)
            .then(function (response) {
                if ($scope.addAnother) {
                  $scope.report = {status: 'Pending'};
                  $state.reload();
                } else {
                  $uibModalInstance.close();
                  $state.reload();
                }
                Notification.success('Report successfully created.');
              },
              function (response) {
                var msg = response.data.messages && response.data.messages.length ? response.data.messages.shift() : 'Error saving the report.';
                Notification.error(msg);
              });
        }

      }
    };
  }]);

angular
  .module('Local')
  .controller('DeleteReportsModal', ['$scope', '$state', '$uibModalInstance', 'AdminService', 'Notification', 'tenant', 'reports', function ($scope, $state, $uibModalInstance, AdminService, Notification, tenant, reports) {
    $scope.reports = reports;

    $scope.delete = function (reports) {
      var reportsToDelete = [];
      reports.forEach(function (report) {
        reportsToDelete.push({
          uid: report.uid
        });
      });

      AdminService.deleteReports(tenant, reportsToDelete).success(function (response) {
        $uibModalInstance.close();
        $state.reload();
        Notification.success('Reports successfully deleted.');
      });
    }
  }]);

'use strict';

angular
  .module('Local')
  .factory('LocationService', ['$http', '$q', '$cookies', '$rootScope', 'ParamsService', 'appConfig', 'DataDateService', 'currentUser', 'Notification', function(
    $http,
    $q,
    $cookies,
    $rootScope,
    ParamsService,
    appConfig,
    DataDateService,
    currentUser,
    Notification
  ) {

    var tenant = $rootScope.tenantName;
    var site = $rootScope.tenantName;
    var allDirectories = [];

    var getFiltersParams = function(){
      var params = getParams();
      return {
        searchTextPhraseOnly: params.searchTextPhraseOnly,
        fieldFilters: params.fieldFilters,
        searchTerm: params.searchTerm,
        dataDate: DataDateService.dataDate,
        dataDateTo: DataDateService.dataDateTo
      };
    };

    var getParams = function(moreParams){
      var directories = mapDirectories();
      var params = {
        fid: ParamsService.get('fid') || undefined,
        from: ParamsService.get('page') * ParamsService.get('size') || 0,
        size: ParamsService.get('size') || 10,
        directories: _.isEmpty(directories) ? [] : _.isArray(directories) ? directories : [directories],
        rankingKeyword: ParamsService.get('rankingKeyword') || undefined,
        listingAccuracy: ParamsService.get('listingAccuracy') || undefined,
        localPresence: ParamsService.get('localPresence') || undefined,
        localRankingPage: ParamsService.get('localRankingPage') || undefined,
        localMarketShareDomain: ParamsService.get('localMarketShare') || undefined,
        locationAvgRating: ParamsService.get('locationAvgRating') || undefined,
        locationEngagementDate: ParamsService.get('locationEngagementDate') || undefined,
        locationLikesAge: ParamsService.get('locationLikesAge') || undefined,
        locationLikesGender: ParamsService.get('locationLikesGender') || undefined,
        locationLikesDate: ParamsService.get('locationLikesDate') || undefined,
        locationTabViews: ParamsService.get('locationTabViews') || undefined,
        organicRankingPage: ParamsService.get('organicRankingPage') || undefined,
        rating: ParamsService.get('rating') || undefined,
        searchTerm: ParamsService.get('searchText') || '',
        searchTextPhraseOnly: ParamsService.get('exactMatch') === true,
        specialty: ParamsService.get('specialty') || undefined,
        fieldFilters: ParamsService.get('fieldFilters') || ['BUSINESS_NAME', 'CITY', 'STATE', 'COUNTRY', 'ZIP', 'FID','LID'],
        locationType: ParamsService.get('locationType') || '',
        sort: [{
          asc: ParamsService.isSet('sortAsc') ? ParamsService.get('sortAsc') === true : true,
          field: ParamsService.get('sortBy') || 'businessName.exact',
        }],
        dataDate: DataDateService.dataDate,
        dataDateTo: DataDateService.dataDateTo,
      };
      if (ParamsService.get('rankingPage')) {
        params.rankingRequest = {
          rankingPage: ParamsService.get('rankingPage'),
          rankingKeyword: ParamsService.get('rankingKeyword'),
          rankingDirectories: ParamsService.get('rankingDirectories'),
          type: ParamsService.get('rankingType'),
        };
      }
      if (typeof params.fieldFilters == "string") {
        params.fieldFilters = [params.fieldFilters];
      }

      if (!ParamsService.get('fieldFilters') && currentUser.tenant.searchableProfileFields) {
        _.each(currentUser.tenant.searchableProfileFields, function(f) {
          params.fieldFilters.push("profile:" + f);
        });
      }

      return params;
    };

    function mapDirectories(dirs) {
      if (!dirs) {
        dirs = ParamsService.get('directories') || [];
      }
      var result = [];
      dirs.forEach(function(d) {
        result.push(d);
        if (searchEngines[d]) {
          result.push(searchEngines[d]);
        }
        if (localDirectories[d]) {
          result.push(localDirectories[d]);
        }
      });
      return result;
    };

    var localDirectories = {
      BING_LOCAL: 'MSN',
      YAHOO_LOCAL: 'YAHOO',
      GOOGLE_LOCAL: 'GOOGLE',
    };

    var searchEngines = {
      MSN: 'BING_LOCAL',
      YAHOO: 'YAHOO_LOCAL',
      GOOGLE: 'GOOGLE_LOCAL',
    };

    return {
      validFilters: ['localPresence', 'listingAccuracy', 'localMarketShare', 'organicRankingPage', 'localRankingPage', 'rating', 'rankingKeyword', 'rankingPage', 'rankingDirectories', 'rankingType'],
      getParams: getParams,
      mapDirectories: mapDirectories,

      exportLocations: function(params) {
        var params = getParams(params);
        var url = appConfig.API_URL + '/local/' + site + '/report/locations.csv';
        return $http.post(url, params);
      },

      getLocations: function(params) {
        var params = getParams(params);
        params.newdeltas = ParamsService.get('newdeltas');
        params.location =  ParamsService.get('location');
        var url = appConfig.API_URL + '/local/' + site + '/report/locations.json';
        return $http.post(url, params);
      },

      getAggregationsRanking: function(params) {
        var url = appConfig.API_URL + '/listing/' + tenant + '/aggregations/rankings.json',
        data = {
          searchTerm: params.searchText || "",
        };
        return $http.post(url, data);
      },

      getAggregations : function(params) {
        var url = appConfig.API_URL + '/listing/' + tenant + '/aggregations/citations.json',
        data = {
          searchTerm: params.searchText || "",
        };
        return $http.post(url, data);
      },

      getSearchEngine: function(d) {
        if (localDirectories[d]) {
          return localDirectories[d];
        }
        return d;
      },

      getSearchEngines: function() {
        var _this = this;
        return _this.getDirectories()
          .then(function(response) {
            allDirectories = response.data.map(function(d){return d.name;});
            response.data = response.data.filter(function(d){
              return !localDirectories[d.name];})
            return response;
          });
      },

      getTenantSearchEngines: function() {
        var _this = this;
        return _this.getTenantDirectories()
          .then(function(response) {
            allDirectories = response.data.map(function(d){return d.name;});
            response.data = response.data.filter(function(d){
              return !localDirectories[d.name];})
            return response;
          });
      },

      getDirectories: function() {
        return $http.get(appConfig.API_URL + '/global/config/directories.json');
      },

      getDirectoriesRLS_status: function() {
        return $http.get(appConfig.API_URL + '/directories/configurations.json');
      },

      updateDirectoriesRLS_status: function(item) {
        var data = {
          'name': item.name,
          'enabledLocal': item.enabledLocal,
          'enabledRank': item.enabledRank,
          'enabledCitation': item.enabledCitation
        }
        return $http.post(appConfig.API_URL + '/directories/configurations/update.json', data);
      },

      getTenantDirectories: function() {
        return $http.get(appConfig.API_URL + '/directories.json');
      },

      getReviewsOverview: function() {
        // todo rankingsOverview.json does not exist in the backend, so this function cannot actually work
        // but it's called from somewhere... does that page not actually get used anymore? investigate...
        var params = getParams();
        var url = appConfig.API_URL + '/listing/' + tenant + '/aggregations/rankingsOverview.json';

        return $http.post(url, params);
      },

      getNewRanks: function (params) {
        var params = {
          directories: getParams().directories,
          fid: params.fid,
          lid: params.lid,
          dataDate: DataDateService.dataDate,
          dataDateTo: DataDateService.dataDateTo
        }
        var url = appConfig.API_URL + '/local/'+tenant+'/report/rankingSummary.json';
        return $http.post(url, params);
      },

      getNewCitations: function (params) {
        var params = {
          directories: getParams().directories,
          specialty: getParams().specialty,
          fid: params.fid,
          lid: params.lid,
          dataDate: DataDateService.dataDate,
          dataDateTo: DataDateService.dataDateTo
        }

        var url = appConfig.API_URL + '/local/'+tenant+'/report/citationsSummary.json';
        return $http.post(url, params);
      },

      logCitationAudit: function (dataDate, directory, lid) {
        return $http.post(appConfig.API_URL + '/local/'+tenant+'/log-citation-audit.json', {
          dataDate: dataDate,
          directory: directory,
          lid: lid
        });
      },

      getCitationsCompare: function (params) {
        var params = {
          directories: [ params.directory ],
          specialty: getParams().specialty,
          lid: params.lid,
          dataDate: DataDateService.dataDate,
          dataDateTo: DataDateService.dataDateTo
        }
        var url = appConfig.API_URL + '/local/citations/last.json';
        return $http.post(url, params);
      },

      getAdhocCitation: function (params) {
        var params = {
          shouldRescrape: true,
          directory: params.directory,
          lid: params.lid,
          citationURL: params.citationURL,
          dataDate: DataDateService.dataDate,
          dataDateTo: DataDateService.dataDateTo
        }
        var url = appConfig.API_URL + '/local/citations/adhoc.json';
        return $http.post(url, params);
      },

      getLocalCitationCompare: function (params) {
        var params = {
          directory: params.directory,
          lid: params.lid,
          citationURL: params.citationURL,
          dataDate: DataDateService.dataDate,
          dataDateTo: DataDateService.dataDateTo
        }
        var url = appConfig.API_URL + '/local/citations/adhoc.json';
        return $http.post(url, params);
      },

      getNewReviews: function (params) {
        var params = {
          directories: getParams().directories,
          fid: params.fid,
          lid: params.lid,
          dataDate: DataDateService.dataDate,
          dataDateTo: DataDateService.dataDateTo
        }
        var url = appConfig.API_URL + '/local/reviews/get.json';
        return $http.post(url, params);
      },

      getSocialInsightsRatings: function(params, fid) {
        var params = getParams(params);

        if (typeof(fid) !== 'undefined') {
          params.fid = fid;
        }

        params.locationAvgRating = undefined;

        return $http.post(appConfig.API_URL + '/local/' + tenant + '/social-insights/ratings.json', params);
      },

      getSocialInsightsLikes: function(params, fid) {
        var params = getParams(params);

        if (typeof(fid) !== 'undefined') {
          params.fid = fid;
        }

        params.locationLikesDate = undefined;

        return $http.post(appConfig.API_URL + '/local/' + tenant + '/social-insights/likes.json', params);
      },

      getSocialInsightsEngagement: function(params, fid) {
        var params = getParams(params);

        if (typeof(fid) !== 'undefined') {
          params.fid = fid;
        }

        params.locationEngagementDate = undefined;

        return $http.post(appConfig.API_URL + '/local/' + tenant + '/social-insights/engagement.json', params);
      },

      getSocialInsightsLikeDemographic: function(params, demo) {
        var params = getParams(params);

        if (demo == 'age') {
          params.locationLikesAge = undefined;
        } else {
          params.locationLikesGender = undefined;
        }

        return $http.post(appConfig.API_URL + '/local/' + tenant + '/social-insights/like-demographic/' + demo + '.json', params);
      },

      getSocialInsightsTabViews: function(params) {
        var params = getParams(params);
        params.locationTabViews = undefined;
        return $http.post(appConfig.API_URL + '/local/' + tenant + '/social-insights/tab-views.json', params);
      },

      getListingAccuracySummary: function (params) {
        var did = params.did;
        var url;
        if (params.viewMigratedData) {
          url = appConfig.API_URL + '/local/' + tenant + '/filter/listing-accuracy-summary-rollup.json';
        } else {
          url = appConfig.API_URL + '/local/' + tenant + '/filter/listing-accuracy-summary.json';
        }
        
        var params = getParams(params);
        params.listingAccuracy = undefined;
        params.directories = undefined;
        params.did = did;
        params.newdeltas = ParamsService.get('newdeltas');
        params.location =  ParamsService.get('location');

        return $http.post(url, params);
      },

      getCitationFoundTrends: function (params) {
        var url = appConfig.API_URL + '/local/' + tenant + '/filter/citation-found-trends.json';
        var params = getParams(params);
        params.listingAccuracy = undefined;
        params.directories = undefined;
        params.newdeltas = ParamsService.get('newdeltas');
        params.location =  ParamsService.get('location');

        return $http.post(url, params);
      },

      getRankingReport: function(params) {
        console.log(params);
        var did = params.did;
        var url;
        if (params.viewMigratedData) {
          url = appConfig.API_URL + '/local/' + tenant + '/filter/rankingsReportRollup.json';
        } else {
          url = appConfig.API_URL + '/local/' + tenant + '/filter/rankingsReport.json';
        }
        var reqParams = getParams(params);
        reqParams.nationality = params.nationality;
        reqParams.brand = params.brand;
        reqParams.did = did;
        reqParams.collapseColumns = params.collapseColumns;
        reqParams.rankingRequest = {
            type: params.type
        };
        reqParams.directories = [];

        if (params.radius) {
          reqParams.radius = params.radius === "All" ? -1 : params.radius;
        }

        return  $http.post(url, reqParams);
      },

      getKeywords: function() {
        return $http.get(appConfig.API_URL + '/local/keywords.json');
      },

      getSpecialties: function() {
        var params = getFiltersParams(),
          url = appConfig.API_URL + '/local/' + site + '/filter/specialties.json';

        return $http.post(url, params);
      },

      getRadii: function() {
        var params = getFiltersParams(),
          url = appConfig.API_URL + '/local/' + site + '/filter/radii.json';

        return $http.post(url, params);
      },

    }
}]);

'use strict';

angular
  .module('Local')
  .factory('Citation', ['$http', '$q', '$cookies', '$rootScope', 'appConfig', 'ParamsService', function(
    $http,
    $q,
    $cookies,
    $rootScope,
    appConfig,
    ParamsService
  ) {

    var tenant = $rootScope.tenantName;

    return {

      getCitations: function(fid) {
        var url = appConfig.API_URL + '/listing/' + tenant + '/' + fid + '/citations.json';

        return $http.get(url);
      },

      getCitationsOverview: function(params) {
        var params = {
          searchTerm: params.searchText || ''
        };
        var url = appConfig.API_URL + '/listing/' + tenant + '/aggregations/citationsOverview.json';

        return $http.post(url, params);
      },

      getCitationSerpHTML: function (params) {
        var data = {
          url: params.citationURL,
          dataDate: params.dataDate,
          directory: params.directory,
        }
        return $http.post(appConfig.API_URL + '/local/citation-candidate/response-body.json', data);
      }

    }
}]);

(function() {
  'use strict';

  Controller.$inject = ['LocationService', 'ParamsService', '$rootScope', '$location', '$scope', 'DataDateService', 'GridWidthSrvc'];
  angular
    .module('Local')
    .controller('LocationSocialCtrl', Controller);

  function Controller(
    LocationService,
    ParamsService,
    $rootScope,
    $location,
    $scope,
    DataDateService,
    GridWidthSrvc
  ) {

    // $scope.loadLocations = loadLocations

    GridWidthSrvc.mainColumnWidth(10);

    // // Load locations
    // function loadLocations() {
    //   $rootScope.params = $location.search();
    //   $rootScope.params.page = +$rootScope.params.page || 0;
    //   $rootScope.params.size = +$rootScope.params.size || 10;
    //   $rootScope.params.searchText = $rootScope.params.searchText || '';
    //   // If search text is sent sorting should be by relevance
    //   // @todo compare with previous values to check if it changed
    //   if ($rootScope.params.searchText !== '') {
    //     $rootScope.params['sort'] = null;
    //   }
    //   $scope.loading = true;

    //   LocationService.getSocialInsightsLocations($rootScope.params)
    //     .success(function(response){
    //       $scope.totalCount = response.totalCount;
    //       $scope.locations  = response.rows;
    //       $scope.loading    = false;
    //     })
    //     .error(function(response, error){
    //       console.log(response, error)
    //     });
    // };

    // loadLocations();

    $scope.getColspanFixed = function (columnCount) {
      var minimalColumns = columnCount - 5;
      var colspan = minimalColumns + 2;

      return colspan;
    }

    //$scope.$emit('$routeUpdate');

  }
})();

'use strict';

angular
  .module('Local')
  .controller('SocialStatsCtrl', ['$scope', '$location', '$window', '$http', '$rootScope', '$uibModal', '$timeout', 'highchartsNG', 'ParamsService', 'LocationService', 'localStorageService', 'AggsDataFormatterService', '$filter', function (
    $scope,
    $location,
    $window,
    $http,
    $rootScope,
    $uibModal,
    $timeout,
    highchartsNG,
    ParamsService,
    LocationService,
    localStorageService,
    AggsDataFormatterService,
    $filter
  ) {
    var ctrl = this;

    var defaultOptions = {
      plotOptions: {
        column: {
          borderWidth: 1,
          dataLabels: {
            enabled: false,
            align: 'center',
            formatter: function() {
              return $filter('bigNumber')(this.y, 0);
            },
          },
          pointPadding: 0.1,
          minPointLength: 0,
          // minPointVal: 1,
        },
      },
      yAxis: {
        title: { text: null },
        labels: { enabled: false, format: '{value}' },
        endOnTick: true,
        max: null,
        min: null
      },
      xAxis: {
        // categories: $rootScope.directoriesByKey.map(getDirTitle) || [],
        // categories: [],
        title: { text: null },
      },
      tooltip: {
        enabled: true,
        followPointer: true,
        headerFormat: "<b>{point.key}</b><br/>",
        xDateFormat: "%e %b",
        shared: true
      },
    }

    $scope.ctrl = $scope;

    $scope.init = function (totalCount) {
      $scope.totalCount = totalCount;
    }

    // @todo This is a hardcode that only works for Teleflora
    $scope.keywords = ['flowers', 'florist', 'flower delivery'];

    $scope.demographicsOptions = _.extend({}, defaultOptions);
    $scope.demographicsOptions = _.extend($scope.demographicsOptions, {
      legend: {
        enabled: true,
        align: "right",
        verticalAlign: "middle",
        layout: "vertical"
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      }
    });

    $scope.likesOptions = _.extend({}, defaultOptions);
    $scope.likesOptions = _.extend($scope.likesOptions, {
      chart: {
        marginRight: 20
      },
      xAxis: {
        type: "datetime",
        tickPositioner: function () {
            return [this.dataMin, (this.dataMin + this.dataMax) / 2, this.dataMax]
        },
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%e %b', this.value);
          }
        },
        endOnTick: true,
        startOnTick: true
      },
      yAxis: [{
        endOnTick: true,
        max: null,
        min: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          enabled: true,
          text: "Page Likes"
        },
        labels: {
          enabled: true
        }
      }, {
        opposite: true,
        endOnTick: true,
        max: null,
        min: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          enabled: false,
          text: "Avg"
        },
        labels: {
          enabled: false
        }
      }],
      legend: {
        enabled: false,
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal"
      },
      plotOptions: {
          column: {
            dataLabels: {
              enabled: false
            },
            minPointLength: 0,
            minPointVal: 0
          }
        }
    });

    $scope.engagementOptions = _.extend({}, defaultOptions);
    $scope.engagementOptions = _.extend($scope.engagementOptions, {
      chart: {
        marginRight: 20
      },
      xAxis: {
        type: "datetime",
        tickPositioner: function () {
            return [this.dataMin, (this.dataMin + this.dataMax) / 2, this.dataMax]
        },
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%e %b', this.value);
          }
        } ,
        endOnTick: true,
        startOnTick: true
      },
      yAxis: [{
        endOnTick: true,
        max: null,
        min: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          enabled: true,
          text: "Checkins"
        },
        labels: {
          enabled: true
        }
      },{
        endOnTick: true,
        max: null,
        min: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          enabled: false
        },
        labels: {
          enabled: false
        }
      },{
        endOnTick: true,
        max: null,
        min: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          enabled: false
        },
        labels: {
          enabled: false
        }
      }],
      legend: {
        enabled: false,
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal"
      },
      plotOptions: {
          column: {
            dataLabels: {
              enabled: false
            },
            minPointLength: 0,
            minPointVal: 0
          }
        }
    });

    $scope.tabViewsOptions = _.extend({}, defaultOptions);
    $scope.tabViewsOptions = _.extend($scope.tabViewsOptions, {
      legend: {
        enabled: true,
        align: "right",
        verticalAlign: "middle",
        layout: "vertical"
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
      }
    });

    // https://stackoverflow.com/questions/30533072/highcharts-pie-charts-show-outline-when-empty
    function createEmptyPieElements(chart) {
      var r = Math.min(chart.plotWidth / 2, chart.plotHeight / 2),
          y = chart.plotHeight / 2 + chart.plotTop,
          x = chart.plotWidth / 2 + chart.plotLeft;
      chart.pieOutline = chart.renderer.circle(x, y, r).attr({
          fill: '#ddd'
      }).add();

      chart.pieOutlineInner = chart.renderer.circle(x, y, r / 1.8).attr({
          fill: 'white'
      }).add();

      chart.pieLabel = chart.renderer.label('NO REVIEWS').css({
          fontSize: '10px'
      }).add();

      var textBBox = chart.pieLabel.getBBox();
      var x = chart.plotLeft + (chart.plotWidth  * 0.5) - (textBBox.width  * 0.5);
      var y = chart.plotTop  + (chart.plotHeight * 0.5) - (textBBox.height * 0.5);
      chart.pieLabel.attr({x: x, y: y});
    }

    $scope.ratingsOptions = _.extend({}, defaultOptions);
    $scope.ratingsOptions = _.extend($scope.ratingsOptions, {
      legend: {
        enabled: true,
        align: "right",
        verticalAlign: "middle",
        layout: "vertical"
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
      },
      tooltip: {
        formatter: function() {
          return '<b>Locations with ' + this.point.name + 's:</b> ' + this.y;
        }
      },
      chart: {
        events: {
          load: function() {
            var chart = this;
            if (!chart.series.length || chart.series.length == 0) {
                createEmptyPieElements(chart);
            }
          },
          redraw: function() {
            var chart = this;
            if (chart.pieOutline && chart.pieOutline.element) {
              chart.pieOutline.destroy();
              chart.pieOutlineInner.destroy();
              chart.pieLabel.destroy();
            }

            if (!chart.series.length || chart.series.length == 0) {
                createEmptyPieElements(chart);
            }
          }
        }
      }
    });

    $scope.ratingsDetailOptions = _.extend({}, $scope.ratingsOptions);
    $scope.ratingsDetailOptions = _.extend($scope.ratingsDetailOptions, {
      tooltip: {
        formatter: function() {
          return '<b>' + this.point.name + ' Reviews:</b> ' + this.y;
        }
      }
    });
    
    $scope.likesAgeOptions = _.extend({}, defaultOptions);
    $scope.likesAgeOptions = _.extend($scope.likesAgeOptions, {
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          }
        }
      },
      tooltip: {
        enabled: false
      },
      yAxis: {
        max: null,
        min: 0,
        tickInterval: 5,
        gridLineWidth: 0,
        plotLines: [
          {
            width: 1,
            color: "#cccccc",
            value: 0
          }
        ]
      }
    });


    $scope.rankingType        = $scope.rankingType || 'Local';

    $scope.reviewsRatingsOptions = _.extend(defaultOptions);

    $scope.$data                      = LocationService;
    $scope.$formatter                 = AggsDataFormatterService;

    // this is a hack to get the charts to resize properly when the window size is changed.
    // todo should this be done for whole dashboard? why wasn't it working already, is this
    // not automatic? i have neither the expertise nor the time to figure it out.
    angular.element($window).bind('resize', function() {
      $scope.$evalAsync(function() {
        Highcharts.charts.forEach(function(c) { if (c) c.setSize($('.aggregations--block').get(0).offsetWidth, c.chartHeight) });
      });
    })
  }]);

'use strict';

angular
  .module('Intro', [
    'ui.router'
  ])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider
      .state('intro', {
        url: '/intro',
        templateUrl: 'modules/intro/intro.html',
        controller: 'IntroCtrl'
      });
  }]);

'use strict';

angular
  .module('Intro')
  .controller('IntroCtrl', ['$rootScope', '$location', function(
    $rootScope,
    $location
  ){
    var path = $rootScope.mainMenu && $rootScope.mainMenu.length ? $rootScope.mainMenu[0].path : '/intro';
    $location.path(path);
  }]);

(function() {
  'use strict';

  angular
    .module('app.aggregations', []);
})();

(function() {
  'use strict';

  angular
    .module('app.aggregations')
    .directive('aggsContainer', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      transclude: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        group: '@',
        withSlider: '@',
        items: '=', // @todo this should be automatically calculated depending on children count
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/views/aggs-container.html',
      link: link,
    };

    return directive;

    function link(scope, element, attrs, ctrl) {
      if (ctrl.hasSlider) {
        scope.$watch('ctrl.currentPage', function(){
          scope.$evalAsync(function() {
            var children = $('.container--inner', element).children();
            children.hide();
            var count    = children.length;
            var from     = ctrl.currentPage * ctrl.pageSize;
            var to       = from + ctrl.pageSize;

            children.hide();
            children.each(function(i, child){
              if (i >= from && i < to) {
                $(this).show();
              }
            });
          });
        });
      }
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this;

    ctrl.pageSize    = 3;
    ctrl.pages       = Math.ceil(ctrl.items / ctrl.pageSize) || 0;
    ctrl.hasSlider   = ctrl.withSlider !== undefined && ctrl.pages > 1;

    ctrl.init = function () {
      ctrl.currentPage = 0;
    }

    ctrl.setPage = function (page) {
      ctrl.currentPage = page;
    }

    ctrl.getNumber = function(number) {
      return new Array(number);
    }
  }

})();

(function() {
  'use strict';

  factory.$inject = ['$filter', '$rootScope', 'moment', 'ParamsService', '$state', '$timeout'];
  angular
    .module('app.aggregations')
    .factory('AggsDataFormatterService', factory);

  function factory(
    $filter,
    $rootScope,
    moment,
    ParamsService,
    $state,
    $timeout
  ) {
    var srvc = {}


    srvc.facebookFanGender = facebookFanGender;
    srvc.facebookFanAge = facebookFanAge;
    srvc.facebookCheckinsEngagement = facebookCheckinsEngagement;
    srvc.facebookTabViews = facebookTabViews;
    srvc.facebookLikes = facebookLikes;
    srvc.facebookRatings = facebookRatings;
    srvc.facebookRatingsDetail = facebookRatingsDetail;

    var RED       = '#DD5143',
          RED2    = '#F38A78',
          RED3    = '#FEC9B8',
        ORANGE    = '#E68523',
          ORANGE2 = '#F3B86D',
          ORANGE3 = '#FFEBB6',
        PINK      = '#EF6C5A',
          PINK2   = '#F7A897',
          PINK3   = '#FFE2D2',
        GREEN     = '#7CB82F',
          GREEN2  = '#B0D775',
          GREEN3  = '#CEE4A3',
          GREEN4  = '#E5EFC7',
        YELLOW    = '#EDB220',
          YELLOW2 = '#F0C23B',
          YELLOW3 = '#F4D161',
          YELLOW4 = '#F7DF8C',
          YELLOW5 = '#FAF0B5',
        GRAY      = '#86888A',
          GRAY2   = '#B3B5B7',
          GRAY3   = '#E0E2E4',
        SELECTED  = '#3F86D4',
        PURPLE    = '#7B539D',
          PURPLE2 = '#A487BA',
          PURPLE3 = '#CAB5D5',
          PURPLE4 = '#F0E3EF',
        BLUE      = '#6997CA',
          BLUE2   = '#A0C7F3',
          BLUE3   = '#9BDAF3',
          BLUE4   = '#CFEDFB';

    function facebookFanGender(data) {
      var format = {
        'Male': {color: BLUE },
        'Female': {color: PINK },
        'Unknown': {color: GRAY }
      };

      var demoData = data.demographics.map(function(d) {
        return {
          name: d.key,
          color: format[d.key].color,
          y: d.counter,
          params: { locationLikesGender: d.key },
          selected: ParamsService.get('locationLikesGender') == d.key,
        }
      });

      if (demoData.length > 0) {
        return {
          series: [{
            innerSize: '50%',
            data: demoData,
            name: 'Likes'
          }]
        };
      } else {
        return {
          series: []
        }
      }
    }

    function facebookFanAge(data) {
      // in this function we will ignore all values < 0 (i.e. losing likes)
      // test data  57.7%, 28.7%, 13.8%
      // data.demographics = [ {'key':'18-24', 'counter' :8},{'key':'18-24', 'counter' :7}, {'key':'25-34', 'counter':7},{'key':'35-44', 'counter': 0},{'key':'45-54', 'counter': 0},{'key':'55-64', 'counter': 0 },{'key': '65+', 'counter': 0}];
      var counterVals = _.map(data.demographics, function(d) { return d.counter; });
      var total = _.reduce(counterVals, function(memo, val) { return val >= 0 ? memo + val : memo; }, 0);

      var demoData = data.demographics
      .filter(function(d) { return d.counter >= 0; })
      .map(function(d) {
          return {
          name: d.key,
          // this value fills the columns
          y: (d.counter/total)*100,
          params: { locationLikesAge: d.key },
          selected: ParamsService.get('locationLikesAge') == d.key,
        }
      });

      return {
        series: [{
          type: 'column',
          data: demoData,
          color: BLUE,
          name: 'Likes',
          dataLabels: {
            formatter: function () {
              // this formats and rounds y value
              return $filter('percent')(this.y/100, 1);
            },
          }
        }]
      }
    }

    function facebookCheckinsEngagement(data) {
      var engagementData = data.dailyClicks.map(function(d) {
        return {
          x: moment(d.key).valueOf(),
          y: d.counter,
          params: { locationEngagementDate: d.key },
          selected: ParamsService.get('locationEngagementDate') == d.key,
        }
      });

      var viewData = data.dailyImpressions.map(function(d) {
        return {
          x: moment(d.key).valueOf(),
          y: d.counter,
          params: { locationEngagementDate: d.key },
          selected: ParamsService.get('locationEngagementDate') == d.key,
        }
      });

      var checkinData = data.dailyCheckins.map(function(d) {
        return {
          x: moment(d.key).valueOf(),
          y: d.counter,
          params: { locationEngagementDate: d.key },
          selected: ParamsService.get('locationEngagementDate') == d.key,
        }
      });

      if (engagementData.length > 0 || viewData.length > 0 || checkinData.length > 0) {
        return {
          metrics: {
            data: [
              {label: 'CHECKINS', value: $filter('bigNumber')(data.totalCheckins)},
              {label: 'VIEWS', value: $filter('bigNumber')(data.totalImpressions)},
              {label: 'ENGAGEMENTS', value: $filter('bigNumber')(data.totalClicks)}
            ]
          },
          series: [{
            type: 'column',
            data: viewData,
            color: GRAY3,
            name: 'Page Views',
            yAxis: 0,
          }, {
            type: 'line',
            data: engagementData,
            color: BLUE,
            name: 'Engagements',
            yAxis: 1,
          }, {
            type: 'line',
            data: checkinData,
            color: GREEN,
            name: 'Checkins',
            yAxis: 2
          }]
        }
      } else {
        return {
          series: []
        }
      }
    }

    function facebookTabViews(data) {
      var colors = [GREEN, BLUE, ORANGE, PINK, PURPLE, YELLOW, GREEN, BLUE2, PURPLE2, GRAY2];

      var i = 0;
      if (typeof(data.tabViews) !== 'undefined' && data.tabViews != null) {
        var tabData = data.tabViews.slice(0, 10).map(function(d) {
          return {
            name: d.key,
            color: colors[i++],
            y: d.counter,
            params: { locationTabViews: d.key },
            selected: ParamsService.get('locationTabViews') == d.key,
          }
        });
      } else {
        var tabData = [];
      }

      if (tabData.length > 0) {
        return {
          series: [{
            innerSize: '50%',
            data: tabData,
            name: 'Tab Impressions'
          }]
        };
      } else {
        return {
          series: []
        }
      }

    }

    function facebookLikes(data) {
      var likeData = data.dailyLikes.map(function(d) {
        return {
          x: moment(d.key).valueOf(),
          y: d.counter,
          params: { locationLikesDate: d.key },
          selected: ParamsService.get('locationLikesDate') == d.key,
        }
      });

      var avgLikeData = data.averageDailyLikes.map(function(d) {
        return {
          x: moment(d.key).valueOf(),
          y: d.counter,
          params: { locationLikesDate: d.key },
          selected: ParamsService.get('locationLikesDate') == d.key,
        }
      });

      if (likeData.length > 0 || avgLikeData.length > 0) {
        var metrics = {
          data: [
            {label: 'TOTAL', value: $filter('bigNumber')(data.totalLikes)},
            {label: 'AVG PER LOC', value: $filter('number')(data.averageLikesPerLocation, 1)},
          ]
        };

        if (data.locationsWithZeroLikes > 0) {
            metrics.data.push({label: 'LOCS W/ ZERO', value: $filter('bigNumber')(data.locationsWithZeroLikes), color: RED});
        }

        return {
          metrics: metrics,
          series: [{
            type: 'column',
            data: likeData,
            color: GRAY3,
            name: 'Total Likes',
            marker: {
              enabled: false
            },
            yAxis: 0
          }, {
            type: 'line',
            data: avgLikeData,
            color: GREEN,
            name: 'Average Likes',
            yAxis: 1
          }]
        }
      } else {
        return {
          series: []
        }
      }
    }

    function facebookRatings(data) {
      return _facebookRatings(data, data.ratingAverageRanges);
    }

    function facebookRatingsDetail(data) {
      var result = _facebookRatings(data, data.ratingAllRanges);

      var total = 0;

      result.series.forEach(function(x){
        if (x.hasOwnProperty('data') && x.data.length > 0) {
          x.data.forEach(function(d) {
            total += Math.abs(d.y); // do abs in case we have negative datapoints
          });
        }
      });

      if (total == 0) {
        result.series = [];
      }

      return result;
    }

    /**
     * We have two functions that use different ranges -- one has range
     * buckets that count number of locations, the other counts number
     * of ratings
     */
    function _facebookRatings(data, ratingRanges) {
      var colors = [PURPLE, RED, ORANGE, GREEN, BLUE];

      var i = 0;
      var ratingData = [];

      for (var rating in ratingRanges) {
        if (rating > 0) {
          ratingData.push({
            name: rating + " Star",
            color: colors[i++],
            y: ratingRanges[rating],
            params: { locationAvgRating: rating },
            selected: ParamsService.get('locationAvgRating') === rating,
          });
        }
      }

      ratingData.push({
        name: "No Rating",
        color: GRAY2,
        y: ratingRanges[0],
        params: { locationAvgRating: "-1" },
        selected: ParamsService.get('locationAvgRating') === "-1",
      });

      var metrics = {
        data: [
          {label: 'TOTAL', value: $filter('bigNumber')(data.ratingCount)},
          {label: 'AVG RATING', value: $filter('number')(data.ratingAverage, 2)}
        ]
      };

      if (ratingRanges[0] > 0) {
        metrics.data.push({label: 'NO RATING', value: $filter('bigNumber')(ratingRanges[0]), color: RED})
      }

      return {
        metrics: metrics,
        series: [{
          innerSize: '50%',
          data: ratingData,
          name: 'Locations'
        }]
      };
    }

    return srvc;
  }
})();


(function (H) {
  H.seriesTypes.column.prototype.modifyValue = function (val) {
    var minPointVal = this.options.minPointVal;
    if (minPointVal) {
      if (val >= 0 && val < minPointVal) {
        return minPointVal;
      } else if (val < 0 && val > -minPointVal) {
        return -minPointVal;
      }
    }
    return val;
  };

  // Modify the values used in stacks. If implemented in the core, just do this
  // inline where y = yData[i] is set.
  H.wrap(H.Series.prototype, 'setStackedPoints', function (proceed) {
    var yData = this.processedYData,
      i = this.processedYData.length;
    while(i--) {
      if (this.modifyValue) {
        yData[i] = this.modifyValue(yData[i]);
      }
    }
    proceed.call(this);
  });
}(Highcharts));

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$filter', '$rootScope', '$scope', '$timeout'];
  angular
    .module('app.aggregations')
    .directive('aggsHorizontalBars', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        loadData: '&',
        formatData: '&',
        paramName: '@',
        interactive: '@',
        stacking: '@',
        totals: '<',
        tooltipEnabled: '@',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/horizontal-bars/horizontal-bars.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      ctrl.element = elem;
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $filter, $rootScope, $scope, $timeout) {
    var ctrl = this;
    var split = ctrl.blockTitle.split('|');
    ctrl.title = split.length ? split.shift().trim() : '';
    ctrl.description = split.length ? split.shift().trim() : '';

    ctrl.isActive = isActive;
    ctrl.reset    = reset;
    ctrl.download = download;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.loading = true;
      // @todo Check if loadData is a valid callback
      ctrl.loadData().then(function(response){
        // @todo validate callback to be a function definition
        ctrl.data = ctrl.formatData()(response.data, ctrl.paramName, ctrl.totals);
        ctrl.config = generateConfig(ctrl.data.series);
        ctrl.isEmpty = (!ctrl.data.leftTotal || !ctrl.data.leftTotal.value) && (!ctrl.data.rightTotal || !ctrl.data.rightTotal.value) && (ctrl.data.leftTotal != undefined && ctrl.data.rightTotal != undefined);
        ctrl.loading = false;
      });
    }

    function isActive () {
      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }
      return ParamsService.isSetParams(params);
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    /**
     * Reset param in ParamsService and clean selected items in graph
     */
    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      ParamsService.setObject(resetObj);
    }

    function generateConfig (series) {
      var chartWidth = angular.element(ctrl.element).width() || 420,
          chartHeight = ctrl.options && ctrl.options.height ? ctrl.options.height : 200;

      var config = {
        series: series,
        options: {
          id: ctrl.paramName,
          credits: {
            enabled: false
          },
          chart: {
            inverted: true,
            type: 'bar',
            style: {
              fontFamily: 'Montserrat',
            }
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                align: 'left',
                enabled: true,
                formatter: function() {
                  return $filter('bigNumber')(this.y, 1);
                },
              },
              minPointLength: 5,
            },
          },
          legend: {
            enabled: false,
          },
          title: '',
          tooltip: {
            enabled: ctrl.tooltipEnabled === 'true',
            headerFormat:  '<b>{point.key}</b><br/>',
            pointFormatter: function() {
              var point = this, series = this.series;
              return '<span style="color:'+point.color+'">\u25CF</span> '+series.name+': <b>'
                + $filter('number')(point.y) + '</b>';
            },
          },
          yAxis: {
            title: {
              text: null
            },
            labels: {
              enabled: false,
            },
          },
          xAxis: {
            type: 'category',
            title: {
              text: null
            },
          },
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
        loading: false,
      };

      config.options.plotOptions.series = {};

      if (typeof(ctrl.stacking) !== 'undefined') {
        config.options.plotOptions.series.stacking = ctrl.stacking;
      }

      // if the control is interactive, add cursor/pointer/selection properties
      if (ctrl.interactive !== 'false') {
        _.extend(config.options.plotOptions.series, {
          allowPointSelect: true,
          states: {
            select: {
              color: '#3B84D7',
              borderColor: '#3B84D7',
              borderWidth: 0
            }
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                var that = this;
                $timeout(function () {
                  if (that.options.selected) {
                    ParamsService.setObject(that.options.params);
                  } else {
                    ctrl.reset();
                  }
                  $scope.$apply();
                }, 0);
              }
            }
          }
        });
      }

      return config;
    }
  }

})();

(function() {
  'use strict';

  directive.$inject = ['ParamsService', '$timeout'];
  Controller.$inject = ['ParamsService', '$rootScope', '$filter', '$timeout', '$scope', '$window'];
  angular
    .module('app.aggregations')
    .directive('aggsGrid', directive);

  /* @ngInject */
  function directive(ParamsService, $timeout) {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        formatData: '&',
        loadData: '&',
        maxSegments: '@',
        paramName: '@',
        searchParam: '@',
        stacking: '@',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/grid/aggs-grid.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }
    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $rootScope, $filter, $timeout, $scope, $window) {
    var ctrl = this;

    ctrl.isActive = isActive;
    ctrl.reset    = reset;
    ctrl.filterBy = filterBy;
    ctrl.search = search;
    ctrl.gridParams = { };

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function filterBy(row) {
      // row.selected = !row.selected;
      ParamsService.setObject(row.params);
    }

    function search($event) {
      if ($event.type === 'click') {
        loadData();

      } else if ($event.charCode === 13) {
        if (!$window.getSelection().toString()) {
          // Required for mobile Safari
          $event.currentTarget.setSelectionRange(0, $event.currentTarget.value.length)
        }
        loadData();
      }
    }

    function loadData() {
      ctrl.loading  = true;
      ctrl.gridParams = angular.copy(ParamsService.getAll());
      ctrl.gridParams.size = 300;
      console.log(ctrl.gridParams)
      ctrl.loadData()(ctrl.gridParams).then(function(response){
        ctrl.data = ctrl.formatData()(response.data);
        ctrl.config  = generateConfig(ctrl.data);
        ctrl.isEmpty = !ctrl.data;
        ctrl.loading = false;
      });
    }

    function isActive () {
      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }

      if (ctrl.segment) {
        return ParamsService.isSetParams(params) && (ParamsService.get('segment') === ctrl.segment);
      }

      return ParamsService.isSetParams(params);
    }

    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      ParamsService.setObject(resetObj);
      return;
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    function generateConfig (rows) {
      var chartWidth = ctrl.graphWidth, chartHeight = 200;

      var config = {

      };

      return config;
    }
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$filter', '$rootScope', '$scope', '$timeout', 'AggsDataFormatterService'];
  angular
    .module('app.aggregations')
    .directive('aggsVerticalBars', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        formatData: '&',
        interactive: '@',
        loadData: '&',
        options: '<',
        paramName: '@',
        paramFilter: '<',
        stacking: '@',
        hideXAxis: '@',
        tooltipEnabled: '@',
        totals: '<',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/vertical-bars/vertical-bars.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      ctrl.element = elem;
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }

    return directive;
  }


  /* @ngInject */
  function Controller(ParamsService, $filter, $rootScope, $scope, $timeout, AggsDataFormatterService) {
    var ctrl = this;

    ctrl.isActive    = isActive;
    ctrl.reset       = reset;
    ctrl.options     = ctrl.options || {};
    ctrl.download    = download;
    ctrl.$formatter  = AggsDataFormatterService;
    ctrl.customLabel = null;
    ctrl.metrics = null;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.loading = true;
      // @todo Check if loadData is a valid callback
      ctrl.loadData().then(function(response){
        var total = 0;
        // @todo validate callback to be a function definition
        ctrl.data = ctrl.formatData()(response.data, ctrl.paramName, ctrl.totals);
        ctrl.data.series.forEach(function(x){
          if (x.hasOwnProperty('data') && x.data.length > 0) {
            total += Math.abs(x.data[0].y); // do abs in case we have negative datapoints
          }
        });

        ctrl.config = generateConfig(ctrl.data);
        if(total == 0) {
          ctrl.isEmpty = !ctrl.data.series.length;
        }else {
          ctrl.isEmpty = false;
        }
        if (ctrl.isEmpty) {
          debugger;
        }
        ctrl.loading = false;
      });
    }

    function isActive () {
      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }

      if (ctrl.paramFilter) {
        var paramValues = ParamsService.get(ctrl.paramName) || [];
        return _.some(paramValues, ctrl.paramFilter)
      }

      var active = params.reduce(function(a, b){
        return ParamsService.isSet(b) && a;
      }, true);

      return active;
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    /**
     * Reset param in ParamsService and clean selected items in graph
     */
    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      if (ctrl.paramFilter) {
        var paramValues = ParamsService.get(ctrl.paramName) || [];
        resetObj = _.reject(paramValues, ctrl.paramFilter)
        ParamsService.set(ctrl.paramName, resetObj);
      }
      else {
        ParamsService.setObject(resetObj);
      }

      return;
    }

    function generateConfig (data) {
      var series = data.series;
      var categories = data.categories;
      ctrl.metrics = data.metrics;

      var chartWidth = angular.element(ctrl.element).width(),
          chartHeight = ctrl.options && ctrl.options.height ? ctrl.options.height : 200;

      var config = {
        series: series,
        options: {
          id: ctrl.paramName,
          credits: {
            enabled: false
          },
          chart: {
            // inverted: true,
            stacking: 'percent',
            type: 'column',
            style: {
              fontFamily: 'Montserrat',
            },
            zoomType: 'y',
            marginTop: 0,
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          plotOptions: {
            column: {
              dataLabels: {
                align: 'center',
                enabled: true,
                formatter: function() {
                  return $filter('bigNumber')(this.y, 1);
                },
              },
              minPointLength: 0,
              minPointVal: 0,
            },
          },
          legend: {
            enabled: false,
          },
          title: '',
          tooltip: {
            enabled: ctrl.tooltipEnabled === 'true',
            headerFormat:  '<b>{point.key}</b><br/>',
            pointFormatter: function() {
              var point = this, series = this.series;
              return '<span style="color:'+point.color+'">\u25CF</span> '+series.name+': <b>'
                + $filter('number')(point.y) + '</b><br/>';
            },
          },
          yAxis: {
            labels: {
              enabled: false,
            },
            max: 100,
            min: 0,
            // tickInterval: 0.25,
           maxPadding: 0,
            title: {
              text: null
            },
          },
          xAxis: {
            tickPlacement: 'between',
            type: 'category',
            categories: categories,
            title: {
              text: null
            },
          },
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
        loading: false,
      };

      config.options.plotOptions.series = {};

      if (typeof(ctrl.stacking) !== 'undefined') {
        config.options.plotOptions.series.stacking = ctrl.stacking;
      }

      if (ctrl.hideXAxis === "true") {
        config.options.xAxis = {
          labels: {
            enabled: false,
          },
          lineWidth: 0,
          minorGridLineWidth: 0,
          lineColor: 'transparent',
          minorTickLength: 0,
          tickLength: 0
        }
      }

      // if the control is interactive, add cursor/pointer/selection properties
      if (ctrl.interactive !== 'false') {
        _.extend(config.options.plotOptions.series, {
          allowPointSelect: true,
          states: {
            select: {
              color: '#3B84D7',
              borderColor: '#3B84D7',
              borderWidth: 0
            }
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                var that = this;
                $timeout(function () {
                  if (that.options.selected) {
                    if (that.options.paramName) {
                      if (that.options.paramName === 'customFilters') {
                        var customFilters = ParamsService.get('customFilters') || [];

                        var filterIndex = _.findIndex(customFilters, {
                          field: that.options.params.field,
                          fieldValue: that.options.params.fieldValue,
                          rankType: that.options.params.rankType,
                          type: that.options.params.type,
                        });

                        if (filterIndex !== -1) {
                          customFilters[filterIndex].filterValue = that.options.params.filterValue;
                        } else {
                          customFilters.push(that.options.params);
                        }

                        ParamsService.set('customFilters', customFilters);
                      }
                      else {
                        ParamsService.set(that.options.paramName, that.options.params);
                      }
                    }
                    else {
                      ParamsService.setObject(that.options.params);
                    }
                  }
                  else {
                    ctrl.reset();
                  }
                  $scope.$apply();
                }, 0);
              }
            }
          }
        });
      }

      // metrics
      // todo encapsulate this somewhere? service?
      if (ctrl.metrics) {
        config.options.chart.events = {
          redraw: function() {
            if (ctrl.customLabel != null) {
              ctrl.customLabel.destroy();
            }

            var labelString = '<div class="custom-labels custom-labels-vertical-bars" style="width:' + this.chartWidth + 'px">';
            labelString += '<div class="custom-label-inner" style="width:' + (ctrl.metrics.data.length * 100) + 'px">';

            for (var i in ctrl.metrics.data) {
              labelString += '<div class="custom-label">';
              labelString += '<div class="custom-label-label">';
              labelString += ctrl.metrics.data[i].label;
              labelString += '</div>';
              labelString += '<div class="custom-label-value" style="color:';
              labelString += ctrl.metrics.data[i].color ? ctrl.metrics.data[i].color : '#000000'
              labelString += '"">';
              labelString += ctrl.metrics.data[i].value;
              labelString += '</div>';
              labelString += '</div>';

            }

            labelString += '</div></div>';

            ctrl.customLabel = this.renderer.label(labelString, 0, 0, 'rect', null, null, true).add();

          }
        }
        config.options.chart.marginTop = 30;
        config.options.chart.spacingTop = 0;
        //config.chartHeight += 100;
      }
      // end metrics

      if (ctrl.options) {
        config.options = _.merge({}, config.options, ctrl.options);
      }

      // manually set yAxis max if all data is 0
      var total = 0;
      series.forEach(function(x){
          if (x.hasOwnProperty('data') && x.data.length > 0) {
            total += Math.abs(x.data[0].y); // do abs in case we have negative datapoints
          }
        });

      if (total == 0) {
        config.options.yAxis.max = 100;
      }
      // console.log(JSON.stringify(config));

      return config;
    }
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$filter', '$rootScope', '$q', '$scope', '$timeout', 'moment', 'AggsDataFormatterService'];
  angular
    .module('app.aggregations')
    .directive('aggsTrends', directive);

  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        formatData: '&',
        interactive: '@',
        loadData: '&',
        options: '=',
        paramName: '@',
        stacking: '@',
        graphWidth: '@'
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/trends/trends.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      setTimeout(function(){
        ctrl.graphWidth = angular.element(elem).width();
      }, 0);
      /*scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });*/
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $filter, $rootScope, $q, $scope, $timeout, moment, AggsDataFormatterService) {
    var ctrl = this;

    ctrl.isActive   = isActive;
    ctrl.reset      = reset;
    ctrl.params      = {};
    ctrl.download   = download;
    ctrl.$formatter = AggsDataFormatterService;

    // Initialize directive data
    //loadData();
    setTimeout(loadData, 10);

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData () {
      ctrl.loading = true;
      ctrl.loadData().then(function(response){
        ctrl.data = ctrl.formatData()(response.data);
        if (response.data.total) ctrl.data[0].total = response.data.total;
        ctrl.isEmpty = _.isEmpty(ctrl.data) || (ctrl.data.length == 1 && ctrl.data[0].data.length == 0);
        ctrl.config = generateConfig(ctrl.data);
        ctrl.loading = false;
      });
    }

    function isActive () {
      return ParamsService.isSet(ctrl.paramName);
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    /**
     * Reset param in ParamsService and clean selected items in graph
     */
    function reset () {
      ParamsService.reset(ctrl.paramName);

      Highcharts.charts.forEach(function(c) {
        if (c && c.options && c.options.id == ctrl.paramName) {
          c.series.forEach(function(s) {
            s.points.forEach(function(p) {
              p.select(false);
            });
          });
        }
      });
    }

    function generateConfig (series) {
      Highcharts.setOptions({
        global: {
          useUTC: false
        }
      });

      var chartWidth = ctrl.graphWidth, chartHeight = 200;
      var config = {
        series: series,
        options: {
          id: ctrl.paramName,
          credits: {
            enabled: false
          },
          lineColor: 'red',
          chart: {
            zoomType: 'xy',
            style: {
              fontFamily: 'Montserrat',
            },
            spacingBottom: 30,
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          plotOptions: {
            series: {
              groupPadding: 0,
            },
          },
          legend: {
            enabled: false,
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'middle',
            borderWidth: 0
          },
          title: '',
          yAxis: {
            title: {
              text: null
            },
            gridLineColor: '#e0e0e0',
            labels: {
              showFirstLabel: true,
              align: 'center',
              x: 0,
              y: 13,
            }
          },
          tooltip: {
            shared: true,
          },
          xAxis: {
            crosshair: {
              color: '#f5f5f5',
              dashStyle: 'solid',
              snap: true,
              width: 80,
            },
            lineColor: '#e0e0e0',
            type: 'datetime',
            title: {
              text: null
            },
            labels: {
              align: 'center',
            },
          },
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
        loading: false,
      };

      if (ctrl.options) {
        config.options = _.merge({}, config.options, ctrl.options);
      }

      if (typeof(ctrl.stacking) !== 'undefined') {
        config.options.plotOptions.series.stacking = ctrl.stacking;
      }

      // if the control is interactive, add cursor/pointer/selection properties
      if (ctrl.interactive !== 'false') {
        _.extend(config.options.plotOptions.series, {
          allowPointSelect: true,
          states: {
            select: {
              color: '#3B84D7',
              borderColor: '#3B84D7',
              borderWidth: 0
            }
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                var that = this;
                $timeout(function () {
                  if (that.options.selected) {
                    ParamsService.set(ctrl.paramName, that.paramValue);
                  } else {
                    ctrl.reset();
                  }
                  $scope.$apply();
                }, 0);
              }
            }
          }
        });
      }

      return config;
    }
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$timeout', '$scope', '$rootScope', '$filter'];
  angular
    .module('app.aggregations')
    .directive('aggsDonut', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        loadData: '&',
        formatData: '&',
        paramName: '@',
        interactive: '@'
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/donut/donut.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $timeout, $scope, $rootScope, $filter) {
    var ctrl = this;

    ctrl.isActive = isActive;
    ctrl.reset    = reset;
    ctrl.download = download;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.loading = true;
      ctrl.loadData().then(function(response){
        // @todo validate callback to be a function definition
        var paramKeys = ctrl.paramName.split(',');
        ctrl.data = ctrl.formatData()(response.data, paramKeys);
        ctrl.config = generateConfig(ctrl.data.series);
        ctrl.loading = false;
      });
    }

    function isActive () {
      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }

      var active = params.reduce(function(a, b){
        return ParamsService.isSet(b) || a;
      }, false) || ParamsService.isSetParams(params);

      return active;
    }

    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      ParamsService.setObject(resetObj);
      return;
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    function generateConfig (series) {

      var chartWidth = ctrl.graphWidth, chartHeight = 200;

      var config = {
        series: series,
        options: {
          id: ctrl.paramName,
          title: null,
          credits: {
            enabled: false
          },
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            style: {
              fontFamily: 'Montserrat'
            }
          },
          // title: {
          //   text: 'Share'
          // },
          tooltip: {
            formatter: function(d) {
              return (this.point.key ? this.point.key : this.key) + ': <br><b>' + $filter('number')(this.y) + '</b>';
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              startAngle: -90,
              endAngle: 90,
              center: ['50%', '75%'],
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                distance: -20,
                style: {
                  color: '#FFF',
                  fontWeight: '300',
                  fontSize: '10px',
                  // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                }
              },
              shadow: false,
              // showInLegend: true,
            },
            series: ctrl.interactive === 'false' ? undefined : {
              allowPointSelect: true,
              colorByPoint: true,
              cursor: 'pointer',
              point: {
                events: {
                  click: function (e) {
                    var that = this;
                    $timeout(function() {
                      if (that.options.selected) {
                        ctrl.params = that.options.params;
                        ParamsService.setObject(ctrl.params);
                      } else {
                        ctrl.reset();
                      }
                      $scope.$apply();
                    }, 0);
                  },
                },
              },
            },
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
      };

      if (ctrl.options) {
        config.options = _.merge({}, config.options, ctrl.options);
      }
      return config;
    }

    function generateOptions(paramName) {
      return {
        chart: {
          type: 'pieChart',
          width: 200,
          height: 125,
          margin: {
            top:0,
            right:0,
            bottom:0,
            left:0
          },
          growOnHover: true,
          tooltip: {
            keyFormatter: function(d) {
              return 'Lead by ' + d;
            }
          },
          showLegend: false,
          color: function(d){
            if (!ParamsService.isSet(paramName) || ParamsService.get(paramName) === d.key) {
              return d.color;
            } else {
              return '#ccc';
            }
          },
          x: function(d){ return d.label; },
          y: function(d){ return d.value; },
          valueFormat: function(d) {
            return d3.format(',.0f')(d);
          },
          pie: {
            startAngle: function (d) {
              return d.startAngle - Math.PI/2;
            },
            endAngle: function (d) {
              return d.endAngle - Math.PI/2;
            },
            dispatch: {
              elementClick: function (e) {
                $scope.$evalAsync(function(){
                  if (ParamsService.get(paramName) === e.data.key) {
                    ParamsService.reset(paramName);
                    e.data.active = !e.data.active;
                  } else {
                    ParamsService.set(paramName, e.data.key);
                    e.data.active = !e.data.active;
                  }
                  return e.active;
                });
              }
            }
          }
        }
      };
    }

  }

})();

(function() {
  'use strict';

  directive.$inject = ['ParamsService', '$timeout'];
  Controller.$inject = ['ParamsService', '$rootScope', '$filter', '$timeout', '$scope'];
  angular
    .module('app.aggregations')
    .directive('aggsBubble', directive);

  /* @ngInject */
  function directive(ParamsService, $timeout) {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        formatData: '&',
        loadData: '&',
        paramName: '@',
        options: '<',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/bubble/bubble.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $rootScope, $filter, $timeout, $scope) {
    var ctrl = this;

    ctrl.isActive = isActive;
    ctrl.reset    = reset;
    ctrl.download = download;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.params = ctrl.paramName.split(',');
      ctrl.loading  = true;
      ctrl.loadData().then(function(response){
        ctrl.data = ctrl.formatData()(response.data, $rootScope.tenantName, ctrl.params);
        ctrl.config  = generateConfig(ctrl.data.series);
        ctrl.isEmpty = !ctrl.data.series.length;
        ctrl.loading = false;
      });
    }

    function isActive () {
      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }

      if (ctrl.segment) {
        return ParamsService.isSetParams(params) && (ParamsService.get('segment') === ctrl.segment);
      }

      return ParamsService.isSetParams(params);
    }

    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      ParamsService.setObject(resetObj);
      return;
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    function generateConfig (series) {
      var chartWidth = ctrl.graphWidth, chartHeight = 200;
      var config = {
        series: series,
        options: {
          id: ctrl.paramName,
          title: {
            text: ctrl.blockTitle,
            style: {
             display: 'none'
            }
          },
          chart: {
            marginTop: 0,
            style: {
              fontFamily: 'Montserrat',
            },
            type: 'bubble',
            zoomType: 'xy',
          },
          xAxis: {
            title: {
              text: null
            },
            labels: {
              enabled: true,
              formatter: function() {
                return this.value*100+"%";
              }
            },
          },
          yAxis: {
            title: {
              text: null
            },
            labels: {
              enabled: false,
            },
          },
          legend: {
            enabled: false,
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          tooltip: {
            headerFormat:  '<b>{point.key}</b><br/>',
            pointFormatter: function() {
              var point = this, series = this.series;
              return '<span style="color:'+point.color+'">\u25CF</span> '+series.name+': <b>'
                + $filter('number')(point.y) + '</b><br/>';
            },
            // useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                align: 'center',
                formatter: function() {
                  return $filter('bigNumber')(this.y, 1);
                },
              },
            },
            series: {
              allowPointSelect: true,
              states: {
                select: {
                  color: '#3B84D7',
                  borderColor: '#3B84D7',
                  borderWidth: 0
                }
              },
              cursor: 'pointer',
              point: {
                events: {
                  click: function (e) {
                    var that = this;
                    $timeout(function() {
                      if (that.options.selected) {
                        ParamsService.setObject(that.options.params);
                      } else {
                        ctrl.reset();
                      }
                      $scope.$apply();
                    }, 0);
                  },
                },
              },
            },
          }
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
        credits: {
          enabled: false
        },
      };

      if (ctrl.options) {
        config.options = _.merge({}, config.options, ctrl.options);
      }
      return config;

    }
  }

})();

(function() {
  'use strict';

  directive.$inject = ['ParamsService', '$timeout'];
  Controller.$inject = ['ParamsService', '$rootScope', '$filter', '$timeout', '$scope'];
  angular
    .module('app.aggregations')
    .directive('aggsHeatmap', directive);

  /* @ngInject */
  function directive(ParamsService, $timeout) {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        formatData: '&',
        loadData: '&',
        paramName: '@',
        options: '=',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/heatmap/heatmap.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $rootScope, $filter, $timeout, $scope) {
    var ctrl = this;

    ctrl.isActive = isActive;
    ctrl.reset    = reset;
    ctrl.download = download;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    ctrl.toggle = function(brandingSegment) {
      var params = {
        brandingSegment: brandingSegment,
        qvBuckets: null,
        thermometer: null,
      }
      ParamsService.setObject(params);
    };

    function loadData() {
      ctrl.params = ctrl.paramName.split(',');
      ctrl.loading  = true;
      var brandingSegment = ParamsService.get('brandingSegment') || 'OVERALL';
      ctrl.loadData().then(function(response){
        ctrl.data = ctrl.formatData()(response.data, brandingSegment);
        ctrl.config  = generateConfig(ctrl.data);
        ctrl.isEmpty = !ctrl.data.series.length;
        ctrl.loading = false;
      });
    }

    function isActive () {
      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }

      // if (ctrl.segment) {
      //   return ParamsService.isSetParams(params) && (ParamsService.get('segment') === ctrl.segment);
      // }
      return ParamsService.isSetParams(params);
    }

    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      ParamsService.setObject(resetObj);
      return;
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    function generateConfig (data) {
      var chartWidth = ctrl.graphWidth, chartHeight = 200;
      var config = {
        series: data.series,
        options: {
          chart: {
            marginTop: 0,
            marginBottom: 30,
            style: {
              fontFamily: 'Montserrat',
            },
            type: 'heatmap',
          },
          colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: '#000000',
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          id: ctrl.paramName,
          legend: {
            enabled: false,
            align: 'right',
            layout: 'vertical',
            margin: 10,
            verticalAlign: 'bottom',
            y: 10,
            symbolHeight: chartHeight * 1.6
          },
          plotOptions: {
            series: {

            },
          },
          title: {
            text: ctrl.blockTitle,
            style: {
             display: 'none'
            }
          },
          xAxis: data.xAxis || {
            categories: [],
            title: ''
          },
          yAxis: data.yAxis || {
            categories: [],
            title: ''
          },
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
        credits: {
          enabled: false
        },
      };

      if (ctrl.options) {
        config.options = _.merge({}, config.options, ctrl.options);
      }

      if (ctrl.interactive !== 'false') {
        _.extend(config.options.plotOptions.series, {
          allowPointSelect: true,
          states: {
            select: {
              color: '#3B84D7',
              borderColor: '#3B84D7',
              borderWidth: 0
            }
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                var that = this;
                $timeout(function () {
                  if (that.options.selected) {
                    ParamsService.setObject(that.options.params);
                  } else {
                    ctrl.reset();
                  }
                  $scope.$apply();
                }, 0);
              }
            }
          }
        });
      }

      return config;

    }
  }

})();

(function() {
  'use strict';

  directive.$inject = ['ParamsService', '$timeout'];
  Controller.$inject = ['ParamsService', '$rootScope', '$filter', '$timeout', '$scope'];
  angular
    .module('app.aggregations')
    .directive('aggsMarketShare', directive);

  /* @ngInject */
  function directive(ParamsService, $timeout) {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        formatData: '&',
        loadData: '&',
        maxSegments: '@',
        paramName: '@',
        segment: '@',
        stacking: '@',
        options: '<'
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/market-share/market-share.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $rootScope, $filter, $timeout, $scope) {
    var ctrl = this;

    ctrl.isActive = isActive;
    ctrl.reset    = reset;
    ctrl.download = download;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.params = ctrl.paramName.split(',');
      ctrl.loading  = true;
      ctrl.loadData().then(function(response){
        ctrl.data = ctrl.formatData()(response.data, $rootScope.tenantName, ctrl.params);
        ctrl.config  = generateConfig(ctrl.data.series);
        ctrl.isEmpty = !ctrl.data.series.length;
        ctrl.loading = false;
      });
    }

    function isActive () {
      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }

      if (ctrl.segment) {
        return ParamsService.isSetParams(params) && (ParamsService.get('segment') === ctrl.segment);
      }

      return ParamsService.isSetParams(params);
    }

    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      ParamsService.setObject(resetObj);
      return;
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    function generateConfig (series) {
      var chartWidth = ctrl.graphWidth, chartHeight = 200;
      var config = {
        series: series,
        options: {
          id: ctrl.paramName,
          title: {
            text: ctrl.blockTitle,
            style: {
             display: 'none'
            }
          },
          chart: {
            marginTop: 5,
            style: {
              fontFamily: 'Montserrat',
            },
            type: 'column',
            zoomType: 'y',
          },
          xAxis: {
            type: 'category',
            crosshair: true,
            labels: {
              useHTML: true,
              formatter: function(d) {
                var favIconUrl = '//www.google.com/s2/favicons?domain=' + this.value;
                return '<img src="'+favIconUrl+'" style="width: 16px; vertical-align: middle" />';
              }
            }
          },
          yAxis: {
            title: {
              text: null
            },
            labels: {
              enabled: false,
            },
          },
          legend: {
            enabled: false,
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          tooltip: {
            followPointer: true,
            headerFormat:  '<b>{point.key}</b><br/>',
            pointFormatter: function() {
              var point = this, series = this.series;
              return '<span style="color:'+point.color+'">\u25CF</span> '+series.name+': <b>'
                + $filter('number')(point.y) + '</b><br/>';
            },
            // positioner: function(labelWidth, labelHeight, point) {
            //   return { x: point.plotX, y: point.plotY || point.h }
            // },
            // useHTML: true
          },
          plotOptions: {
            column: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                align: 'center',
                formatter: function() {
                  return $filter('bigNumber')(this.y, 1);
                },
              },
              pointPadding: 0.2,
            },
            series: {
              allowPointSelect: true,
              states: {
                select: {
                  color: '#3B84D7',
                  borderColor: '#3B84D7',
                  borderWidth: 0
                }
              },
              cursor: 'pointer',
              point: {
                events: {
                  click: function (e) {
                    var that = this;
                    $timeout(function() {
                      /* Draft implemenatation of Multiple Params */
                      // var paramValues = ParamsService.get(ctrl.paramName) || [];
                      // var value = that.options.params[ctrl.paramName].shift();
                      // if (that.options.selected) {
                      //   paramValues.push(value);
                      // } else {
                      //   paramValues = _.reject(paramValues, value);
                      // }
                      // console.log(ctrl.paramName, paramValues, value)
                      // ParamsService.set(ctrl.paramName, paramValues);
                      /* End Draft */
                      if (that.options.selected) {
                        ParamsService.setObject(that.options.params);
                      } else {
                        ctrl.reset();
                      }
                      $scope.$apply();
                    }, 0);
                  },
                },
              },
            },
          }
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
        credits: {
          enabled: false
        },
      };

      if (typeof(ctrl.stacking) !== 'undefined') {
        config.options.plotOptions.series.stacking = ctrl.stacking;
      }

      if (ctrl.options) {
        config.options = _.merge({}, config.options, ctrl.options);
      }

      return config;
    }
  }

})();

(function() {
  'use strict';

  directive.$inject = ['ParamsService', '$timeout'];
  Controller.$inject = ['ParamsService', '$rootScope', '$filter', '$timeout', '$scope'];
  angular
    .module('app.aggregations')
    .directive('aggsFunnel', directive);

  /* @ngInject */
  function directive(ParamsService, $timeout) {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        loadData: '&',
        formatData: '&',
        paramName: '@',
        options: '=',
        interactive: '@',
        stacking: '@',
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/funnel/funnel.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      ctrl.element = elem;
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $rootScope, $filter, $timeout, $scope) {
    var ctrl = this;

    ctrl.isActive = isActive;
    ctrl.reset    = reset;
    ctrl.download = download;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.params = ctrl.paramName.split(',');
      ctrl.loading  = true;
      ctrl.loadData().then(function(response){
        var total = 0;
        ctrl.data = ctrl.formatData()(response.data, $rootScope.tenantName, ctrl.params);
        ctrl.data.series[0].data.forEach(function(item) {
          var x = item.label;
          var value = parseInt(x.slice(x.lastIndexOf("> ")+2,-1));
          total += value ? value : 0;
        });
        ctrl.config  = generateConfig(ctrl.data.series);
        // ctrl.isEmpty = !ctrl.data.series.length;
        if(total == 0) {
          ctrl.isEmpty = true;
        }else {
          ctrl.isEmpty = false;
        }
        ctrl.loading = false;
      });
    }

    function isActive () {
      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }

      return ParamsService.isSetParams(params);
    }

    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      ParamsService.setObject(resetObj);
      return;
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    function generateConfig (series) {
      var chartWidth = angular.element(ctrl.element).width() || 190,
          chartHeight = ctrl.options && ctrl.options.height ? ctrl.options.height : 170;

      var config = {
        series: series,
        options: {
          id: ctrl.paramName,
          title: {
            text: ctrl.blockTitle,
            style: {
             display: 'none'
            }
          },
          chart: {
            type: 'funnel',
            style: {
              fontFamily: 'Montserrat',
            },
            className: "centerTunnel"
          },
          xAxis: {
            type: 'category',
            crosshair: true,
            labels: {
              useHTML: true,
              formatter: function(d) {
                var favIconUrl = '//www.google.com/s2/favicons?domain=' + this.value;
                return '<img src="'+favIconUrl+'" style="width: 16px; vertical-align: middle" />';
              }
            }
          },
          yAxis: {
            title: {
              text: null
            },
            labels: {
              enabled: false,
            },
          },
          legend: {
            enabled: false,
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          tooltip: {
            enabled: true,
            formatter: function() {return this.point.label; },
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            series: {
              states: {
                select: {
                  color: '#3B84D7',
                  borderColor: '#3B84D7',
                  borderWidth: 0
                },
              },
              dataLabels: {
                enabled: true,
                useHTML: true,
                inside: true,
                width: 195,
                distance: 0,
                color: 'black',
                formatter:function(){
                  return '<div class="funnel-datalabel">' + this.point.options.name +'</div>';
                },
              },
              neckWidth: '40%',
              neckHeight: '40%',
            },
          }
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
        credits: {
          enabled: false
        },
      };

      if (typeof(ctrl.stacking) !== 'undefined') {
        config.options.plotOptions.series.stacking = ctrl.stacking;
      }

      // if the control is interactive, add cursor/pointer/selection properties
      if (ctrl.interactive !== 'false') {
        _.extend(config.options.plotOptions.series, {
          allowPointSelect: true,
          states: {
            select: {
              color: '#3B84D7',
              borderColor: '#3B84D7',
              borderWidth: 0
            }
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function (e) {
                var that = this;
                $timeout(function () {
                  if (that.options.selected) {
                    ctrl.params = that.options.params;
                    ParamsService.setObject(ctrl.params);
                  } else {
                    ctrl.reset();
                  }
                  $scope.$apply();
                }, 0);
              }
            }
          }
        });
      }

      return config;
    }
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$timeout', '$scope', '$rootScope', '$filter'];
  angular
    .module('app.aggregations')
    .directive('aggsPieChart', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        formatData: '&',
        interactive: '@',
        loadData: '&',
        options: '<',
        paramName: '@',
        viewSelector: '='
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/pie-chart/pie-chart.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      ctrl.element = elem;
      scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $timeout, $scope, $rootScope, $filter) {
    var ctrl = this;

    ctrl.isActive     = isActive;
    ctrl.reset        = reset;
    ctrl.download     = download;
    ctrl.changeView   = changeView;
    ctrl.selectedView = ctrl.viewSelector ? ctrl.viewSelector[0] : undefined;
    ctrl.customLabel = null;
    ctrl.metrics = null;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.loading = true;
      if (ctrl.selectedView) {
        ParamsService.setSilent('webAnalyticMetric', ctrl.selectedView.key);
      }
      ctrl.loadData().then(function(response){
        // @todo validate callback to be a function definition
        var paramKeys = ctrl.paramName ? ctrl.paramName.split(',') : [];
        ctrl.data = ctrl.formatData()(response.data, paramKeys);

        var total = 0;
        ctrl.data.series.forEach(function(x){
          if (x.hasOwnProperty('data') && x.data.length > 0) {
            total += Math.abs(x.data[0].y); // do abs in case we have negative datapoints
          }
        });

        if(total == 0) {
          ctrl.isEmpty = true;
        }else {
          ctrl.isEmpty = false;
        }

        ctrl.config = generateConfig(ctrl.data);
        ctrl.loading = false;
      });
    }

    function changeView (option) {
      _.map(ctrl.viewSelector, { selected: false});
      option.selected   = true;
      ctrl.selectedView = option;
      ParamsService.setSilent('webAnalyticMetric', option.key);
      loadData();
    }

    function isActive () {
      if (ctrl.interactive === 'false') return false;

      var params = [];
      if (typeof ctrl.paramName === 'string') {
        params = ctrl.paramName.split(',');
      }

      if (typeof ctrl.paramName === 'object') {
        params = Object.keys(ctrl.paramName);
      }

      var active = params.reduce(function(a, b){
        return ParamsService.isSet(b) || a;
      }, false) || ParamsService.isSetParams(params);
      return active;
    }

    function reset () {
      var params = ctrl.paramName.split(','),
        resetObj = _.zipObject(params, new Array(params.length));

      ParamsService.setObject(resetObj);
      return;
    }

    function download () {
      var chart = $('#' + ctrl.paramName).highcharts();
      chart.exportChart();
    }

    // https://stackoverflow.com/questions/30533072/highcharts-pie-charts-show-outline-when-empty
    function createEmptyPieElements(chart) {
      var r = Math.min(chart.plotWidth / 2, chart.plotHeight / 2),
          y = chart.plotHeight / 2 + chart.plotTop,
          x = chart.plotWidth / 2 + chart.plotLeft;
      chart.pieOutline = chart.renderer.circle(x, y, r).attr({
          fill: '#ddd'
      }).add();

      chart.pieOutlineInner = chart.renderer.circle(x, y, r / 1.8).attr({
          fill: 'white'
      }).add();

      chart.pieLabel = chart.renderer.label('NO DATA').css({
          fontSize: '10px'
      }).add();

      var textBBox = chart.pieLabel.getBBox();
      var x = chart.plotLeft + (chart.plotWidth  * 0.5) - (textBBox.width  * 0.5);
      var y = chart.plotTop  + (chart.plotHeight * 0.5) - (textBBox.height * 0.5);
      chart.pieLabel.attr({x: x, y: y});
    }

    function generateConfig (data) {
      var chartWidth  = angular.element(ctrl.element).width() || 420;
      var chartHeight = ctrl.options && ctrl.options.height ? ctrl.options.height : 200;
      var series      = data.series;
      ctrl.metrics = data.metrics;

      var config = {
        series: series,
        options: {
          id: ctrl.paramName,
          title: null,
          credits: false,
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            style: {
              fontFamily: 'Montserrat'
            },
            events: {
              load: function() {
                var chart = this;
                
                if (!chart.series.length || chart.series.length == 0) {
                    createEmptyPieElements(chart);
                }
              },
              redraw: function() {
                var chart = this;
                if (chart.pieOutline && chart.pieOutline.element) {
                  chart.pieOutline.destroy();
                  chart.pieOutlineInner.destroy();
                  chart.pieLabel.destroy();
                }

                if (!chart.series.length || chart.series.length == 0) {
                    createEmptyPieElements(chart);
                } else {
                  // we have series, but let's see if they're all 0
                  var total = 0;
                  chart.series.forEach(function(x){
                    if (x.hasOwnProperty('data') && x.data.length > 0) {
                      total += Math.abs(x.data[0].y); // do abs in case we have negative datapoints
                    }
                  });

                  if (total == 0) {
                    createEmptyPieElements(chart);
                  }
                }
              }
            }
          },
          tooltip: {
            headerFormat:  '<b>{point.key}</b><br/>',
            pointFormatter: function() {
              var point = this, series = this.series;
              return '<span style="color:'+point.color+'">\u25CF</span> '+series.name+': <b>'
                + $filter('number')(point.y) + '</b><br/>';
            },
            useHTML: true,
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              center: ['50%', '50%'],
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                // format: '<b>{point.name}</b>: {point.y:.0f}',
                formatter: function(d) {
                  var point = this;
                  return '<span style="color:' + point.color + '">●</span> '
                    + point.key + ': ' + $filter('bigNumber')(point.y, 1) + '';
                },
                style: {
                  color: '#333',
                  fontWeight: '400',
                  fontSize: '10px',
                  // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                }
              },
              minPointLength: 8,
              minPointVal: 1,
              shadow: false,
              // showInLegend: true,
            },
            series: ctrl.interactive === 'false' ? undefined : {
              allowPointSelect: true,
              states: {
                select: {
                  color: '#3B84D7',
                  borderColor: '#3B84D7',
                  borderWidth: 0
                }
              },
              colorByPoint: true,
              cursor: 'pointer',
              point: {
                events: {
                  click: function (e) {
                    var that = this;
                    $timeout(function() {
                      if (that.options.selected) {
                        ctrl.params = that.options.params;
                        ParamsService.setObject(ctrl.params);
                      } else {
                        ctrl.reset();
                      }
                      $scope.$apply();
                    }, 0);
                  },
                },
              },
            },
          },
          exporting: { enabled: false }
        },
        size: {
          width: chartWidth,
          height: chartHeight
        },
      };

      if (data.drilldown) {
        config.options.drilldown = data.drilldown;
      }

      if (ctrl.options) {
        config.options = _.merge({}, config.options, ctrl.options);
      }

      // metrics
      // todo encapsulate this somewhere? service?
      if (data.metrics) {
        if (!config.options.chart) {
          config.options.chart = {};
        }
        if (!config.options.chart.events) {
          config.options.chart.events = {};
        }

        // check if we have an existing redraw event handler
        var existingRedraw = config.options.chart.events.redraw ? config.options.chart.events.redraw : false;

        config.options.chart.events.redraw = function() {
          if (existingRedraw) {
            existingRedraw.call(this);
          }

          if (ctrl.customLabel != null) {
            ctrl.customLabel.destroy();
          }

          var labelString = '<div class="custom-labels custom-labels-pie-chart" style="height:' + this.chartHeight + 'px">';
          labelString += '<div class="custom-label-inner" style="height:' + (ctrl.metrics.data.length * 53) + 'px">';

          for (var i in ctrl.metrics.data) {
            labelString += '<div class="custom-label">';
            labelString += '<div class="custom-label-label">';
            labelString += ctrl.metrics.data[i].label;
            labelString += '</div>';
            labelString += '<div class="custom-label-value" style="color:';
            labelString += ctrl.metrics.data[i].color ? ctrl.metrics.data[i].color : '#000000'
            labelString += '"">';
            labelString += ctrl.metrics.data[i].value;
            labelString += '</div>';
            labelString += '</div>';
            
          }

          labelString += '</div></div>';

          ctrl.customLabel = this.renderer.label(labelString, 0, 0, 'rect', null, null, true).add();
        }

        config.options.chart.marginLeft = 85;
      }
      // end metrics

      return config;
    }
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$filter', '$rootScope'];
  angular
    .module('app.aggregations')
    .directive('aggsEstimator', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: {
        blockTitle: '@',
        loadData: '&',
        formatData: '&'
      },
      bindToController: true,
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/estimator/estimator.html'
    };

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $filter, $rootScope) {
    var ctrl = this;

    // @todo move to extends
    ctrl.loading = true; // @todo: LoadingService.start()
    ctrl.data    = {};
    ctrl.active  = false;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.relevance = ctrl.relevance || 0.25;
      ctrl.investment = ctrl.investment || 0.25;
      ctrl.loadData()(ParamsService.getAll(), ctrl.relevance, ctrl.investment).then(function(response){
        ctrl.data = response.data;
        ctrl.loading = false; // @todo: LoadingService.finish()
      });
    }

    ctrl.updateRelevance = function (percent) {
      ctrl.relevance = percent;
      ParamsService.set('relevanceForPaid', ctrl.relevance);
    }

    ctrl.updateInvestment = function (percent) {
      ctrl.investment = percent;
      ParamsService.set('investmentForPaid', ctrl.investment);
    }
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$filter', '$rootScope', '$scope'];
  angular
    .module('app.aggregations')
    .directive('aggsSingleCounter', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        loadData: '&',
        formatData: '&',
        paramName: '@'
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/single-counter/single-counter.html'
    };

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $filter, $rootScope, $scope) {
    var ctrl = this;

    // @todo move to extends
    ctrl.data    = {};
    ctrl.active  = isActive;
    ctrl.reset   = reset;
    ctrl.onClick = onClick;

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.loading = true; // @todo: LoadingService.start()
      ctrl.loadData().then(function(response){
        // @todo validate callback to be a function definition
        ctrl.data = ctrl.formatData()(response.data);
        ctrl.loading = false; // @todo: LoadingService.finish()
      });
    }

    function isActive () {
      return ParamsService.isSet(ctrl.paramName);
    }

    function reset () {
      ParamsService.reset(ctrl.paramName);
    }

    function onClick (counter) {
      if (ctrl.paramName !== undefined) {
        if (ParamsService.get(ctrl.paramName) === counter.paramValue) {
          ParamsService.reset(ctrl.paramName);
        } else {
          ParamsService.set(ctrl.paramName, counter.paramValue);
        }
      }
    }
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$filter', '$rootScope', '$scope'];
  angular
    .module('app.aggregations')
    .directive('aggsMultipleCounter', directive);

  /* @ngInject */
  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: {
        blockTitle: '@',
        loadData: '&',
        formatData: '&'
      },
      bindToController: true,
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/multiple-counter/multiple-counter.html'
    };

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $filter, $rootScope, $scope) {
    var ctrl = this;

    ctrl.data = [];

    // Initialize directive data
    loadData();

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData() {
      ctrl.loading = true;
      ctrl.loadData().then(function(response){
        // @todo validate callback to be a function definition
        ctrl.data = ctrl.formatData()(response.data);
        ctrl.loading = false; // @todo: LoadingService.finish()
      });
    }
  }

})();

(function() {
  'use strict';

  Controller.$inject = ['ParamsService', '$filter', '$rootScope', '$q', '$scope', '$timeout', 'moment', 'LocationService'];
  angular
    .module('app.aggregations')
    .directive('sparkline', directive);

  function directive() {
    var directive = {
      replace: true,
      restrict: 'E',
      scope: true,
      bindToController: {
        blockTitle: '@',
        formatData: '&',
        interactive: '@',
        loadData: '&',
        options: '=',
        paramName: '@',
        graphWidth: '@'
      },
      controller: Controller,
      controllerAs: 'ctrl',
      templateUrl: 'modules/aggs/sparkline/sparkline.html',
      link: link
    };

    function link (scope, elem, attr, ctrl) {
      setTimeout(function(){
        ctrl.graphWidth = angular.element(elem).width();
      }, 0);
      /*scope.$evalAsync(function(){
        ctrl.graphWidth = angular.element(elem).width();
      });*/
    }

    return directive;
  }

  /* @ngInject */
  function Controller(ParamsService, $filter, $rootScope, $q, $scope, $timeout, moment, LocationService) {
    var ctrl = this;

    ctrl.isActive   = isActive;
    ctrl.reset      = reset;
    ctrl.params      = {};
    ctrl.$data      = LocationService;

    // Initialize directive data
    //loadData();
    setTimeout(loadData, 10);

    ParamsService.subscribe($scope, function(scope, params) {
      if (ParamsService.paramsChanged(params)) {
        loadData();
      }
    });

    function loadData () {
      ctrl.loading = true;

      ctrl.loadData().then(function(response) {
        ctrl.data = ctrl.formatData()(response.data);
        if (response.data.total) ctrl.data[0].total = response.data.total;
        ctrl.isEmpty = _.isEmpty(ctrl.data) || (ctrl.data.length == 1 && ctrl.data[0].data.length == 0);
        ctrl.config = generateConfig(ctrl.data);
        ctrl.loading = false;
      });
    }

    function isActive () {
      return ParamsService.isSet(ctrl.paramName);
    }

    /**
     * Reset param in ParamsService and clean selected items in graph
     */
    function reset () {
      ParamsService.reset(ctrl.paramName);

      Highcharts.charts.forEach(function(c) {
        if (c && c.options && c.options.id == ctrl.paramName) {
          c.series.forEach(function(s) {
            s.points.forEach(function(p) {
              p.select(false);
            });
          });
        }
      });
    }

    function generateConfig (data) {
      var series = data.series;
      Highcharts.setOptions({
        global: {
          useUTC: false
        }
      });

      var chartWidth = ctrl.graphWidth, chartHeight = 200;
      var config = {
        series: series,
        options: {
          chart: {
            backgroundColor: null,
            borderWidth: 0,
            type: 'area',
            margin: [2, 0, 2, 0],
            width: chartWidth,
            height: 20,
            style: {
              overflow: 'visible'
            },

            // small optimization, saves 1-2 ms each sparkline
            skipClone: true
          },
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          xAxis: {
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            startOnTick: false,
            endOnTick: false,
            tickPositions: []
          },
          yAxis: {
            endOnTick: false,
            startOnTick: false,
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            tickPositions: [0]
          },
          legend: {
            enabled: false
          },
          tooltip: {
            shadow: false,
            useHTML: true,
            hideDelay: 0,
            shared: true,
            positioner: function(w, h, point) {
              return {
                x: point.plotX - w / 2,
                y: point.plotY - h
              };
            },
            headerFormat:  '<b>{point.key}</b><br/>',
            pointFormatter: function() {
              var point = this, series = this.series;
              return '<span style="color:'+point.color+'">\u25CF</span> '+series.name+': <b>'
                + $filter('number')(point.y) + '</b><br/>';
            },
          },
          plotOptions: {
            series: {
              color: 'rgb(124, 181, 236)',
              animation: false,
              lineWidth: 1,
              shadow: false,
              states: {
                hover: {
                  lineWidth: 1
                }
              },
              marker: {
                radius: 1,
                states: {
                  hover: {
                    radius: 2
                  }
                }
              },
              fillOpacity: 0.25
            },
            column: {
              negativeColor: '#910000',
              borderColor: 'silver'
            }
          }
        }
      };

      if (ctrl.options) {
        config.options = _.merge({}, config.options, ctrl.options);
      }

      return config;
    }
  }


})();

(function() {
  'use strict';

  config.$inject = ['$stateProvider'];
  angular
    .module('app.static', [
      'ui.router'
    ])
    .config(config);

  /* @ngInject */
  function config ($stateProvider) {

    $stateProvider
      .state('help', {
        url: '/help',
        templateUrl: 'modules/static/help.html'
      })

  }
})();
